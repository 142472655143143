export default class UtilSort {
    static _typesSort = {
        inc: true,
        desc: false
    };

    static sortByAlphabet(arr = [], typeSort = '', getValueForSort = () => {}) {
        const arrSorted = arr.sort((arrItemPrev, arrItemNext) => {
            const arrItemPrevValue = String(getValueForSort(arrItemPrev)) ?? '';
            const arrItemNextValue = String(getValueForSort(arrItemNext)) ?? '';
            return arrItemPrevValue.localeCompare(arrItemNextValue);
        });

        return this._typesSort[typeSort] ? arrSorted : arrSorted.reverse();
    }
}