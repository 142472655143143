import UtilLocalStorage from "../../utils/UtilLocalStorage";

/** Сервис для работы с выбранными конструкциями */
export default class ServiceSelector {

    /**
     * Ключ для получения выбранного из локального хранилища
     * @private
     * @static
     * @type {String}
     */
    static _localStorageDataKey = 'selectData';


    /**
     * Есть ли выбранные конструкции в локальном хранилище
     * @private
     * @static
     * @returns {Boolean}
     */
    static get _isSidesSelected() {
        return UtilLocalStorage.isData(this._localStorageDataKey);
    }

    /**
     * Установить выбранные маркеры в локальное хранилище
     * @public
     * @static
     * @param {Array} selectedMarker - выбранный маркер
     * @param {Object} kitItemsMarkers - маркеры наборов
     * @returns {Boolean} по сути это stage для маркера
     */
    static toggleMarker(selectedMarker = [], kitItemsMarkers = {}, actionFilterSides = () => true, actionFilterSidesSelected = () => true) {
        const {id: markerId = '', extraData = {}} = selectedMarker;
        const {kitItemId = ''} = extraData;
        let beforeSelectedSides = this.getSidesSelected();
        // let afterUnselectSides = this.putAwayMarker(markerId, beforeSelectedSides);
        let afterUnselectSides = beforeSelectedSides.filter(side => actionFilterSidesSelected(kitItemId, markerId, side));
        if(afterUnselectSides.length !== beforeSelectedSides.length){
            this.saveSelected(afterUnselectSides);

            const afterUnselectSidesByIds = afterUnselectSides.filter(side => String(side?.markerId) === String(markerId) && String(side?.kitItemId) === String(kitItemId));

            return afterUnselectSidesByIds.length === 0 ? 0 : 1;
        }
        const kitMarker = this.getKitMarker(selectedMarker, kitItemsMarkers);
        const {sides = []} = kitMarker;
        const sidesFiltered = sides.filter((side) => actionFilterSides(side));
        this.saveSelected(this.putSides(sidesFiltered, markerId, kitItemId, beforeSelectedSides));
        return 1;
    }

    /**
     * Получение готового массива для маркера
     * @param selectedMarker
     * @param kitItemsMarkers
     * @return {Array}
     */
    static selectMarker(selectedMarker = [], kitItemsMarkers = {}){
        const {extraData = {}} = selectedMarker;
        const {kitItemId = ''} = extraData;
        const kitMarker = this.getKitMarker(selectedMarker, kitItemsMarkers);
        const {sides = [], id = ""} = kitMarker;
        return this.putSides(sides, String(id), kitItemId);
    }

    /**
     *
     * @param {Array} sides
     * @param {String} sides[].sideId  - id стороны
     * @param {String} sides[].markerId  - id маркера
     * @param {Array} sides[].priceIds - массив выбранных id цен
     */
    static saveSelected(sides){
        UtilLocalStorage.setData(this._localStorageDataKey, sides);
    }

    /**
     * Очистка выбранного
     */
    static clearSelected(){
        UtilLocalStorage.setData(this._localStorageDataKey, []);
    }

    /**
     * Добавление сторон в выбранное
     * @param sides стороны маркера
     * @param markerId
     * @param selectedSides - массив выбранного
     * @return {Array}
     */
    static putSides(sides, markerId, kitItemId = '', selectedSides = []){
        sides.forEach((side) => {
            const {id: sideId = '', prices = []} = side;
            const priceIds = prices.map((price) => {
                const {id: priceId = ''} = price;
                return String(priceId);
            })
            // const existedSideIndex = selectedSides.findIndex(selectedSide => selectedSide.sideId === String(sideId));
            // if(existedSideIndex === -1){
                selectedSides.push({
                    sideId: String(sideId),
                    markerId: String(markerId),
                    kitItemId: String(kitItemId),
                    priceIds
                });
            // }
        })
        return selectedSides;
    }

    /**
     * Отфильтровываем маркеры не равные текущему
     * @param markerId
     * @param selectedSides
     * @return {Array} Выбранные стороны без сторон относящихся к текущему маркеру
     */
    static putAwayMarker(markerId, selectedSides){
        return selectedSides.filter((selectedSide) => {
            const {markerId: currentMarkerId = ''} = selectedSide;
            return String(markerId) !== String(currentMarkerId);
        });
    }

    /**
     *
     * @param marker
     * @param kitItemsMarkers
     * @return {Object} Выбранный маркер из набора
     */
    static getKitMarker(marker, kitItemsMarkers){
        const {extraData = {}, id = ''} = marker;
        const {kitItemId = ""} = extraData;
        const kitMarkers = kitItemsMarkers[kitItemId] || [];
        const markerIndex = kitMarkers.findIndex((kitMarker) => {
            const {id: kitMarkerId = ''} = kitMarker;
            return Number(id) === Number(kitMarkerId);
        });
        return kitMarkers[markerIndex];
    }


    /**
     * Получить выбранные данные из локального хранилища
     * @public
     * @static
     * @returns {Array} selectedSides - выбранные данные
     */
    static getSidesSelected() {
        return UtilLocalStorage.getData(this._localStorageDataKey) || [];
    }

    /**
     * Получить выбранные цены фильтра из локального хранилища
     * @public
     * @static
     * @returns {Array} selectedSides - выбранные данные
     */
    static getPriceSelected() {
        const data = UtilLocalStorage.getData(this._localStorageDataKey) || [];
        return data.reduce((totalPriceIds, side) => {
            let {priceIds = []} = side;
            return [...totalPriceIds, ...priceIds];
        }, []);
    }
}
