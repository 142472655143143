import ServiceApi from "../ServiceApi";
import ServiceSelector from "../ServicesSelector/ServiceSelector";
import ServiceFilter from "../ServicesFilter/ServiceFilter";
/** Сервис для работы с эксель */
export default class ServiceExcelExport {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} GET templateList - получение списка шаблонов
     * @property {String} POST templateCreate - создание набора
     * @property {String} PUT templateUpdate - редактирование набора
     * @property {String} DELETE templateRemove - удаление набора
     * @property {String} POST templateRemove - создание набора
     * @property {String} POST exportSelected - экспорт выбранного
     * @property {String} POST exportKit - экспорт набора
     */
    static _requestRouts = {
        templateList: '/export/template-list',
        templateCreate: '/export/template-create',
        templateUpdate: '/export/template-update',
        templateRemove: '/export/template-remove',
        exportSelected: '/export/selected',
        exportKit: '/export/group',
    };

    /**
     * получение списка шаблонов
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async getTemplates(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.templateList
            );
            const {data: templates = []} = res;
            actionAfter(this._getTemplateListAfter(templates));
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для сводных данных
     * @private
     * @static
     * @param {Array<Object>} templates - массив шаблонов
     * @returns {Object} respBody - тело запроса
     * @returns {Object<Array>} respBody.templates - шаблоны
     * @returns {Object<Number>} reqBody.activeIndex - индекс активного шаблона
     */
    static _getTemplateListAfter(templates){
        let activeIndex = templates.findIndex(item => item.is_last_used === 1);
        if(activeIndex === -1){
            activeIndex = templates.length - 1;
        }
        return {templates, activeIndex};
    }

    /**
     * Создание/обновление шаблона
     * @private
     * @static
     * @param {Object} template - массив шаблонов
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async saveTemplate(template, actionAfter = () => {}){
        const {id = 0} = template;
        if(id === 0){
            this.createTemplate(template, actionAfter)
        }else{
            this.updateTemplate(template, actionAfter)
        }
    }

    static async createTemplate(template, actionAfter = () => {}) {
        const {name, data} = template;
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.templateCreate,
                {template: {name, data}}
            );
            const {data: template = {}} = res;
            actionAfter(this._modifyTemplateAfter(template));
        } catch(error) {
            console.log(error);
        }
    }

    static async updateTemplate(template, actionAfter = () => {}) {
        const {id, name, data} = template;
        try {
            const res = await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.templateUpdate,
                {template: {template_id: id, name, data}}
            );
            const {data: template = {}} = res;
            actionAfter(this._modifyTemplateAfter(template));
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Создание/обновление шаблона
     * @private
     * @static
     * @param {Object} excelInput - данные для экспорта
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async exportTemplate(excelInput, actionAfter = () => {}){
        const {type = "selected"} = excelInput;
        switch (type) {
            case "selected":
                this.exportSelected(excelInput, actionAfter);
                break;
            case "group":
                this.exportGroup(excelInput, actionAfter);
                break;
        }
    }

    /**
     * Создание/обновление шаблона
     * @private
     * @static
     * @param {Object} excelInput - данные для экспорта
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async exportGroup(excelInput, actionAfter = () => {}){
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.exportKit,
                this._getExportKitBefore(excelInput)
            );
            const {data = {}} = res;
            const {link = ""} = data;
            actionAfter(link);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для сводных данных
     * @private
     * @static
     * @param {Object} excelInput - параметры импорта
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getExportKitBefore(excelInput = {}) {
        const {currentTemplate = {}, kitItemId} = excelInput;
        const {id = 0} = currentTemplate;
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            export: {
                filter_main: {
                    main_params: {...filterSelected},
                    price_group_id: String(kitItemId)
                },
                template_id: id
            }
        };

        return reqBody;
    }

    /**
     * Создание/обновление шаблона
     * @private
     * @static
     * @param {Object} excelInput - данные для экспорта
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async exportSelected(excelInput, actionAfter = () => {}){
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.exportSelected,
                this._exportSelectedBefore(excelInput)
            );
            const {data = {}} = res;
            const {link = ""} = data;
            actionAfter(link);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для получения маркеров
     * @private
     * @static
     * @param {Object} excelInput - данные для экспорта
     * @returns {Object} reqBody - тело запроса
     */
    static _exportSelectedBefore(excelInput) {
        const {currentTemplate = {}} = excelInput;
        const {id = 0} = currentTemplate;
        const priceIds = ServiceSelector.getPriceSelected();
        const reqBody = {
            export: {
                price_ids: priceIds,
                template_id: id
            }
        };
        return reqBody;
    }

    /**
     * Ответ от создания обновления шаблона
     * @private
     * @static
     * @param {Object} template - модифицированный шаблон
     * @returns {Object} respBody - тело запроса
     * @returns {Object<Number>} respBody.id - id шаблона
     * @returns {Object<String>} reqBody.name - название
     * @returns {Object<Number>} respBody.is_last_used - флаг последнего экспорта
     * @returns {Object<Object>} reqBody.data - столбцы
     */
    static _modifyTemplateAfter(template){
        const {id = 0, name = "", is_last_used = 0, data = []} = template;
        return {id, name, is_last_used, data};
    }

    /**
     * Удаление шаблона
     * @private
     * @static
     * @param {Object} template - массив шаблонов
     * @param {Function} actionAfter - событие, сработающее после открытия окна экспорта
     */
    static async removeTemplate(template, actionAfter = () => {}) {
        const {id} = template;
        try {
            await ServiceApi.delete(
                this._microserviceName,
                this._requestRouts.templateRemove + "/" + id
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }
}
