<template>
    <transition :name="nameTransition" appear>
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'BaseTransition',
    props: {
        nameTransition: {
            type: String,
            default: 'appear-custom',
            validator: (value) => {
                return ['appear-custom'].includes(value);
            }
        }
    }
}
</script>

<style lang="scss">
    .appear-custom-enter-active, .appear-custom-leave-active {
        transition: all .5s;
        opacity: 1;
        transform: translate(0, 0);
    }
    .appear-custom-enter, .appear-custom-leave-to {
        opacity: 0;
        transform: translate(0, 10px);
    }
    .appear-custom-move {
        transition: transform .5s;
    }
</style>