<template>
	<div
        ref="table"
        class="table"
        @scroll="onScroll"
    >
        <div
            ref="header"
            class="header"
        >
            <!-- Заголовки таблицы -->
            <div  v-if="columns.length !== 0" class="row titles">
                <div v-if="canCheck" class="cell cell-checkbox">
                    <label v-show="canCheckAll && columns.length > 0">
                        <input
                            type="checkbox"
                            v-model="checkAll"
                            @change="onCheckAll"
                        >
                        <span class="checkmark" :style="getStyleCheckboxAll()" />
                    </label>
                </div>
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    ref="columns"
                    @click="onSort(column)"
                >
                    <div
                        class="column__resizer-right"
                        @mousedown="onResizeColumn($event, posX, column.prop)"
                        @click.stop
                    />
                    <div class="cell__content">
                        <span>{{column.label}}</span>
                        <svg
                            v-show="column.prop === sortedKey"
                            class="cell__arrow"
                            :class="{'cell__arrow_rotate': isReverse}"
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.918"
                            height="7.918"
                            viewBox="0 0 7.918 7.918"
                            fill="currentColor"
                        ><g id="Next" transform="translate(7.918) rotate(90)"><path id="path" d="M3.959,0,3.266.693,6.038,3.464H0v.99H6.038L3.266,7.226l.693.693L7.918,3.959Z" transform="translate(0 0)" fill-rule="evenodd"/></g></svg>
                    </div>
                </div>
            </div>
            <!-- Заголовки таблицы -->
            <!-- Строка поиска -->
            <div
                v-if="hasSearchRow && columns.length !== 0"
                class="row search"
            >
                <div v-if="canCheck" class="cell cell-checkbox"></div>
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column)"
                    class="cell cell-search"
                    :class="getCellClasses(column.prop, column.classes)"
                    ref="columnsSearch"
                >
                    <!--TODO: сделал фильтрацию таблицы по событию ленивого кода, убрал событие обработки клика по энтеру-->
                    <BaseInput
                        v-if="column.canSearch"
                        v-model="searchValues[column.prop]"
                        class="cell__search-input"
                        view="table"
                        placeholder="Найти"
                        :canClear="true"
                        @onInputLazy="updateSearchValues"
                    />
                </div>
            </div>
            <!-- Строка поиска -->
        </div>
        <ProgressBar
            :isLoading="isProgressBar"
            :style="styleProgressBar"
            class="table__progressbar"
            ref="progressbar"
        />

        <div class="body" :style="getTableBodyStyle">
            <div
                v-if="rows.length === 0 && !isProgressBar"
                class="body__empty"
            >
                Ничего не найдено
            </div>
            <div
                v-for="(row, posY) of rows"
                :key="posY"
                :data-rowid="posY"
                class="row"
                :class="{
                    'row__no-margin': !isRowMargin,
                    'row_selected': getIsRowSelected(row)
                }"
                :style="getRowsStyle"
            >
                <div v-if="canCheck" class="cell cell-checkbox" :style="cellStyleRow(row)">
                    <label>
                        <!--Добавил событие выделения для конкретной строки-->
                        <input
                            type="checkbox"
                            :value="row[primaryKey]"
                            v-model="selectedCheckboxes"
                            @change="onCheckRow({row})"
                        >
                        <span class="checkmark" :style="getStyleCheckbox(row)"/>
                    </label>
                    <!-- Вынес чекбокс в компонент. Пример использования -->
                    <!-- <base-checkbox-v2
                        :value="row[primaryKey]"
                        :isPartChecked="isRowSelectedPart(row[primaryKey])"
                        v-model="selectedCheckboxes"
                        @change.native="onCheckRow({row})"
                    /> -->
                </div>

                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column, row)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    @click="onClickCell(column, row)"
                    @dblclick="onDblclickCell(column, row)"
                    :ref="`rowCells__${posY}`"
                >
                    <BaseInput
                        v-if="getIsCellEditableText(column, row)"
                        v-model="cellEditableData.value"
                        @onBlur="onEditCell"
                    />
                    <BaseSelect
                        v-else-if="getIsCellEditableSelect(column, row)"
                        ref="BaseSelect"
                        :options="cellEditableData.items"
                        :value="cellEditableData.value"
                        :open="true"
                        @closeDropdown="onCloseDropdown"
                        @check="onCheckSelect"
                    />
                    <slot
                        v-else-if="column.isSlot"
                        :name="column.prop"
                        :cell="row[column.prop]"
                        :row="row"
                        :column="column"
                    />
                    <div
                        v-else
                        class="cell__content"
                    >{{row[column.prop]}}</div>
                </div>
            </div>

            <!-- <div
                v-if="isLoading && isProgressBar"
                class="table__preloader"
                ref="preloader"
            >
                <BasePreloader :size="30" />
            </div> -->
        </div>
	</div>
</template>

<script>
// import BaseCheckboxV2 from "../Base/BaseCheckboxV2";
// import BasePreloader from "../Base/BasePreloader"
import BaseInput from '../Base/BaseTextInput'
import BaseSelect from '../Base/BaseSelect.vue';
import ProgressBar from '../Base/ProgressBar'

import lazyload from './lazyload'
import search from './search'
import sort from './sort'
import checkRow from './checkRow'

export default {
    name: "Table",
    inject: {
        getEditTableValues: {
            default: async (entityName = '', attribute = '') => {
                console.warn('needs to provide getEditTableValues method');
                console.log(entityName, attribute);
            }
        }
    },
    props: {
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            // required: true
            default: () => []
        },
        rowsStyles: {
            type: Object,
            default: () => ({})
        },
        isProgressBar: {
            type: Boolean,
            default: false
        },
        primaryKey: {
            type: String,
            required: true
        },
        canCheck: {
            type: Boolean,
            default: false
        },
        canCheckAll: {
            type: Boolean,
            default: true
        },
        isRowMargin: {
            type: Boolean,
            default: true
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({}),
        },
        isStickyProgressBar: {
            type: Boolean,
            default: false
        }
    },
    mixins: [lazyload, search, sort, checkRow],
    components: {
        // BaseCheckboxV2,
        // BasePreloader,
        BaseInput,
        BaseSelect,
        ProgressBar
    },
    mounted() {
        /* задаю paddingTop тк header - position: absolute */
        // const headerHeight = this.$refs.header.offsetHeight + 'px'
        // this.$refs.table.style.paddingTop = headerHeight
    },
    data: () => ({
        cellEditableData: {
            rowId: '',
            priceId: '',
            prop: '',
            type: '',
            entityName: '',
            value: '',
            valueLabel: '',
            items: [],
        },
        rowIdClicked: '',
        startPoint: 0,
        finishPoint: 0,
        isOnResizeColumn: false
    }),
    computed: {
        getRowsStyle() {
            const { rowHeight = 0 } = this.virtualScrollConfig;
            if (rowHeight) {
                return { 'height': rowHeight + 'px' }
            }

            return {};
        },
        getTableBodyStyle() {
            const { spacerStyle = {} } = this.virtualScrollConfig;

            if (Object.entries(spacerStyle).length !== 0) {
                return spacerStyle;
            }

            return {};
        },
        styleProgressBar() {
            const {header = {}} = this.$refs;
            const {offsetHeight = 0} = header;
            const styleProgressBarObj = {
                position: 'sticky',
                top: offsetHeight === 0 ? 0 : `${offsetHeight}px`,
                left: '0px'
            }
            const styleProgressBar = this.isStickyProgressBar && this.isProgressBar ? styleProgressBarObj : {};
            return styleProgressBar;
        }
    },
    methods: {
        getIsRowSelected(row = {}) {
            const rowId = String(row?.id ?? '');
            return this.rowsSelected.includes(rowId) || this.rowIdClicked === rowId;
        },
        getStyleCheckboxAll() {
            const lengthSelected = this.selectedCheckboxes.length;
            const lengthRows = this.rows.length;
            if (lengthSelected !== 0 && lengthRows !== 0 && lengthSelected < lengthRows && lengthSelected > 0)
                return {'background-image': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5.995' cy='8.995' r='5.995' fill='%23fff'/%3e%3c/svg%3e"`};
            else
                return {};

        },
        onScroll(event) {
            const leftIndent = this.$refs.table.scrollLeft
            const topIndent = this.$refs.table.scrollTop
            /* Двигаю прогрессбар относительно горизонтального скролла */
            if (this.$refs.progressbar.$el.tagName && !this.isStickyProgressBar) {
                this.$refs.progressbar.$el.style.left = leftIndent + 'px'
                this.$refs.progressbar.$el.style.top = topIndent + this.$refs.header.offsetHeight + 'px'
            }

            /* Двигаю прелоадер */
            if (this.$refs.preloader)
                this.$refs.preloader.style.left = leftIndent + 'px'

            /* Плавующий header */
            // this.$refs.header.style.top = topIndent + 'px'
            this.$emit('onScroll', event);
            this.onLazyLoad(event)
        },
        onClickCell(column, row){
            if(column.isClickEmit){
                this.rowIdClicked = String(row?.id ?? '');
                this.$emit('onClickCell', {attribute: column.prop, row});
            }
        },
        async onDblclickCell(column = {}, row = {}) {
            const isCellEditable = Boolean(column?.isCanEditText ?? false) || Boolean(column?.isCanEditSelect ?? false);

            if (isCellEditable) {
                const type = column?.isCanEditText ?? false ? 'text' : 'select';
                const rowId = String(row?.id ?? '');
                const prop = String(column?.prop ?? '');
                const entityName = String(column?.entityName ?? '');

                const prices = row?.extraData?.prices ?? [];
                const priceDate = prop.split('__')[1] ?? '';
                const price = prices.find(priceItem => String(priceItem?.date ?? '') === priceDate);
                const priceId = entityName === 'nmp_price' ? String(price?.id ?? '') : '';

                if (type === 'text') {
                    const value = entityName === 'nmp_price' ? String(row[prop] ?? '').replace(/[^\d]/g, "") : String(row[prop] ?? '');
                    this.cellEditableData.value = value;
                    this.cellEditableData.valueLabel = value;
                } else {
                    this.cellEditableData.value = String(row?.extraData?.propertyLinks[prop]?.property_id ?? '');
                    this.cellEditableData.valueLabel = String(row[prop] ?? '');
                    this.cellEditableData.items = await this.getEditTableValues(entityName, prop);
                }

                this.cellEditableData = {
                    ...this.cellEditableData,
                    rowId,
                    priceId,
                    prop,
                    type,
                    entityName,
                };

                if (type === 'select')
                    this.$nextTick(() => this.$refs?.BaseSelect[0]?.openDropdown());
            }
        },
        getIsCellEditable(column = {}, row = {}) {
            return this.cellEditableData.prop === String(column?.prop ?? '') && this.cellEditableData.rowId === String(row?.id ?? '');
        },
        getIsCellEditableText(column = {}, row = {}) {
            return this.getIsCellEditable(column, row) && this.cellEditableData.type === 'text';
        },
        getIsCellEditableSelect(column = {}, row = {}) {
            return this.getIsCellEditable(column, row) && this.cellEditableData.type === 'select';
        },
        onCloseDropdown() {
            this.clearCellEditableData();
        },
        onCheckSelect(optionSelect) {
            this.cellEditableData.value = String(optionSelect?.id ?? '');
            this.cellEditableData.valueLabel = String(optionSelect?.value ?? '');
            this.onEditCell();
        },
        onEditCell() {
            this.$emit('editCell', this.cellEditableData);

            this.clearCellEditableData();
        },
        clearCellEditableData() {
            this.cellEditableData = {
                rowId: '',
                priceId: '',
                prop: '',
                type: '',
                entityName: '',
                value: '',
                valueLabel: '',
                items: []
            };
        },
        onCloseSelect() {
            this.$emit('$closeSelect');
        },
        cellStyles(culumnSettings, row = {}) {
            const rowStyle = this.cellStyleRow(row);
            if(culumnSettings.isClickEmit){
                rowStyle['cursor'] = 'pointer';
            }
            return { ...culumnSettings.columnStyles, ...rowStyle};
        },
        cellStyleRow(row = {}) {
            const rowId = String(row[this.primaryKey]);
            const rowStyle = this.rowsStyles[rowId] || {};
            return rowStyle;
        },
        getCellClasses(propName, classes) {
            const result = [
                {'cell-selected': propName === this.sortedKey},
            ]

            if (classes) {
                if (Array.isArray(classes)) {
                    result.push(...classes)
                } else {
                    result.push(classes)
                }
            }

            return result
        },
        onResizeColumn(event = null, indexColumn = -1, columnProp = '') {
            this.isOnResizeColumn = true;
            document.body.style.userSelect = 'none';

            let target = this.$refs.columns[indexColumn];
            let sumX = '0';
			let startPoint = event.clientX;
            let startWidth = target.offsetWidth;

            const onResizeCeilEnd = (event) => {
                let finishPoint = event.clientX;
                const difference = finishPoint - startPoint;
                sumX = (difference + startWidth) + 'px';

                target.style.width = sumX;
                this.$refs.columnsSearch[indexColumn].style.width = target.style.width;
                this.rows.forEach((_, indexRow) => this.$refs[`rowCells__${indexRow}`][indexColumn].style.width = target.style.width);

                this.$emit('resizeColumn', columnProp, sumX);
            }

            document.addEventListener('mousemove', onResizeCeilEnd);
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeCeilEnd);
                document.body.style.userSelect = 'initial';
                setTimeout(() => this.isOnResizeColumn = false, 300);
            }, {once: true});
        }
    }
}
</script>

<style lang="scss" scoped>
$rowBorderRadius: 6px;
$active-color: #4A92F6 !default;
$border-color: #F1F3F9;
$green-color: #71C376;
$text-gray-color: #ADB0BB;
$background-color: #F2F2F2;
$placeholder-color: #EAECED;

::-webkit-scrollbar {
    width: 12px;
}

// ::-webkit-scrollbar-thumb {
//     background-color: #f2f2f2;

//     &:hover {
//         background-color: $active-color;
//     }
//     &:active {
//         background-color: #2c70ce;
//     }
// }

::-webkit-scrollbar:horizontal {
    height: 16px;
}

.column__resizer {
    &-left {
        position: absolute;
        left: 0;
        width: 10px;
        height: 100%;
        cursor: ew-resize;
    }

    &-right {
        position: absolute;
        right: 0;
        width: 10px;
        height: 100%;
        cursor: ew-resize;
    }
}

.table {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    background: #fff;
    font-size: 12px;
    overflow: auto;
    border-top: 1px solid $border-color;
    // border-left: 1px solid $border-color;

    &__progressbar {
        position: absolute;
        z-index: 1;
    }

    &__preloader {
        position: absolute;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        position: sticky;
        position:-webkit-sticky;
        top: 0px;
        z-index: 3;
        min-width: 100%;
        border-bottom-left-radius: $rowBorderRadius;
        border-bottom-right-radius: $rowBorderRadius;

        .cell {
            background: #fff;
            cursor: pointer;
            color: $text-gray-color;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            transition: .2s;
            height: 40px;
            padding: 13px 15px;
            border-bottom: 1px solid $border-color;
            border-right: 1px solid $border-color;

            &.text-center .cell__content span {
                text-align: center;
            }

            &:first-child {
                border-left: 1px solid $border-color;
            }

            &-selected {
                background: #FFF !important;
                color: #000;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                span {
                    display: block;
                    text-align: left;
                    width: 100%;
                }
            }

            &__arrow {
                transition: .2s;
                &_rotate {
                    transform: rotate(180deg);
                }
            }
        }

        // .date-cell {
        //     padding: 13px 15px !important;
        // }

        .cell-search {
            padding: 0;
            padding-top: 1px;
            height: 24px;
            box-shadow: 4px 4px 5px 0 rgba(0,0,0, .05);

            input {
                max-width: 100%;
            }

            .cell__search-input {
                ::v-deep input.input::placeholder {
                    color: $placeholder-color;
                }
            }
        }

        .row.search {
            height: 24px;

            .cell-checkbox {
                padding: 0;
                height: 24px;
            }
        }
    }

	.body {
        background: #fff;
        &__empty {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }
        .row {
            height: 40px;
            border-radius: $rowBorderRadius;
            transition: .25s;

            .cell {
                color: #000;
                font-size: 12px;
                line-height: 14px;
                background: #fff;
                padding: 13px 15px;
                border-bottom: 1px solid $border-color;
                border-right: 1px solid $border-color;
                transition: .25s;

                &:first-child {
                    border-left: 1px solid $border-color;
                }

                &.text-center .cell__content span {
                    text-align: center;
                }
            }

            &:hover {
                .cell {
                    background: #FFF !important;
                }
            }
            &__no-margin {
                margin-bottom: 0px;
            }

            &_selected {
                &:hover {
                    .cell {
                        background-color: #b9e4fc !important;
                    }
                }
                .cell {
                    background-color: #b9e4fc !important;
                    border-bottom: 1px solid #b9e4fc !important;
                    border-right: 1px solid #b9e4fc !important;
                }
            }
        }
    }

    .row {
        display: flex;
        width: max-content;
    }

	.cell {
        position: relative;
        // background: #fff;
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        min-width: 50px;
        padding: 2px 15px 2px 0;
        display: flex;
        align-items: center;

        &-selected {
            background: #FFF !important;
        }

        &-checkbox {
            width: 54px;
            position: sticky;
            left: 0;
            z-index: 1;
            box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.05);

            label {
                cursor: pointer;

                input {
                    display: none;

                    &:checked + span {
                        border-color: $active-color;
                        background-color: $active-color;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        background-size: 60%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                span {
                    width: 24px;
                    height: 24px;
                    border: 2px solid;
                    border-color: #ccc;
                    display: block;
                    border-radius: 4px;
                    transition: border-color 250ms;

                    &:hover {
                        border-color: $active-color;
                    }
                }
            }
        }
    }
}
</style>
