export default class UtilRouteParse {
    static _propsScheme = {
        map: [
            {
                propName: 'isShare',
                getPropValue: (queryParams) => {
                    const {shareToken = ''} = queryParams;
                    return typeof(shareToken) !== 'undefined' && shareToken !== null && shareToken !== '';
                }
            },
            {
                propName: 'kitShowed',
                getPropValue: (queryParams) => {
                    let kitShowed = {};
                    
                    const {order = '', price_group: priceGroup = '', type = ''} = queryParams;
                    const isOrder = order !== null && String(order) !== '';
                    const isType = type !== null && String(type) !== '';

                    if (isOrder || isType) {
                        kitShowed = {
                            externalId: isOrder ? String(order) : String(priceGroup),
                            type: isOrder ? 'offer': String(type)
                        };
                    }

                    return kitShowed;
                }
            },
        ]
    }

    static getPropsQuery(routeKey = '', queryParams = {}) {
        const propsList = this._propsScheme[routeKey] ?? [];
        
        const propsObj = propsList.reduce((props, prop) => {
            const {propName = '', getPropValue = () => {}} = prop;
            props[propName] = getPropValue(queryParams);
            return props;
        }, {});

        return propsObj;
    }
}