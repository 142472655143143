const SchemeCompetitor = {
    competitorTypes: [
        {
            key: "industry",
            name: "Отрасли",
        },
        {
            key: "advertiser",
            name: "Рекламодатели",
        },
        {
            key: "brand",
            name: "Бренды",
        },
        {
            key: "project",
            name: "Проекты",
        },
    ],
    headMenuCompetitor: [
        {
            name: 'Добавить конкурента',
            emit: 'addCompetitor',
        }
    ],
    competitorDropdownOptions: [
        {
            name: 'Удалить',
            emit: 'removeCompetitor',
        },
    ]
};

export default SchemeCompetitor;
