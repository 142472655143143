import UtilDate from "../../utils/UtilDate";
export default {
    namespaced: true,
    state: {
        competitorDatesSelected: [],
        competitorSelected: []
    },
    getters: {
        competitorDatesSelectedFormatted(state) {
            return state.competitorDatesSelected.map(date => {
                return UtilDate.fromDatepickerDate(date);
            });
        },
        competitorSelectedFormatted(state) {
            return state.competitorSelected.map(competitor => {
                const {id = '', description = '', count = '', color = '', key = ''} = competitor;
                const idFormatted = String(id).split('-')[1];
                return {
                    id: idFormatted,
                    name: String(description),
                    count: String(count),
                    color: String(color),
                    key: String(key)
                };
            })
        }
    },
    mutations: {
        setCompetitorDatesSelected(state, competitorDatesSelected) {
            state.competitorDatesSelected = competitorDatesSelected;
        },
        setCompetitorSelected(state, competitorSelected) {
            state.competitorSelected = competitorSelected;
        }
    }
};