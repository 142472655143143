export default {
    data() {
        return {
            searchValues: {}
        }
    },
	computed: {
		hasSearchRow() {
			let hasSearchRow = false

			for (let column of this.columns) {
				if (column.canSearch) {
					hasSearchRow = true
					break
				}
			}

			return hasSearchRow
		}
	},
    created() {
        const {columns} = this
        /**
         * Записываю в объект searchValues ключи для поиска 
         */
        for (let index in columns) {
            const objItem = columns[index]
            const propName = objItem.prop
            
            this.searchValues[propName] = ''
        }
        this.updateSearchValues('created')
    },
	methods: {
        updateSearchValues(lifecycleHook) {
			if (lifecycleHook == 'created') {
				this.searchValues = {...this.searchValues}
				return
			}

			/* Отсекаю поля с пустыми значениями */
			const withValue = {}
			for (let item in this.searchValues) {
				if (this.searchValues[item] != "") {
					withValue[item] = this.searchValues[item]
				} 
			}

            this.$el.scrollTop = 0
			this.$emit('search', withValue)
        },
	}
}