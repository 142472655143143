<template>
    <div class="base-search">
        <BaseInput
            :value="searchValue"
            :placeholder="placeholder"
            class="base-search__input"
            @onInput="onInputSearch"
            @onEnter="onInputSearch" 
        >   
            <div class="base-search__actions">
                <IconClose
                    v-if="searchValue !== ''" 
                    class="base-search__icon-close"
                    @click.native.stop="onClear"
                />
                <IconSearch class="base-search__icon-search" />
            </div>
        </BaseInput>
    </div>
</template>

<script>
/** Компонент поиска тулбара */
import BaseInput from './BaseInput.vue';
import IconSearch from '../Icons/IconSearch.vue';
import IconClose from '../Icons/IconClose.vue';
export default {
    name: 'ToolbarSearch',
    components: {
        BaseInput,
        IconSearch,
        IconClose
    },
    /**
     * Входные данные компонента
     * @property {String} searchValue - значение поля поиска 
     */
    props: {
        searchValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    methods: {
        /**
         * Событие поиска на карте
         * @param {String} value - значение поля поиска
         */
        onInputSearch(value = '') {
            this.$emit('onInputSearch', String(value));
        },
        onClear() {
            this.onInputSearch('');
        },
    }
}
</script>

<style lang="scss" scoped>
    .base-search {
        height: 28px;
        max-height: 36px;
        width: 100%;

        &__input {
            width: 100%;
        }

        &__icon-search,
        &__icon-close {
            width: 14px;
            height: 14px;
            cursor: pointer;
            &:not(:last-child) {
                @media screen and (min-width: 1024px) {
                    margin-right: 10px;
                }
            }

            ::v-deep {
                path {
                    fill: #AAB0B8
                }
            }
        }

        &__icon-search {
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
</style>