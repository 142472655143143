<template>
	<div class="panel">
<!--		<div class="panel__left">-->
<!--			<p>Найдено {{ numberFormat(sidesNumber, 0, '.', ' ') }} сторон</p>-->
<!--		</div>-->
		<div class="panel__right">
            <!-- <BaseButton
                form="oval"
                class="panel-btn"
                @click="onClose"
            >
                <BaseIcon icon="icon-close" size="lg" class="panel-btn__icon" />
                <span class="panel-btn__title">Закрыть фильтр</span>
            </BaseButton> -->
			<BaseButton
				form="oval"
				class="panel-btn"
				@click="onClear"
			>
				<!-- <BaseIcon icon="icon-broom" size="lg" class="panel-btn__icon" /> -->
				<span class="panel-btn__title">Сбросить всё</span>
			</BaseButton>
		</div>
	</div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'
// import BaseIcon from '@/components/Base/BaseIcon'
import MixinNumberModify from "@/mixins/MixinNumberModify";

export default {
	name: "Panel",
	components: {
		BaseButton,
		// BaseIcon
	},
    mixins: [
        MixinNumberModify
    ],
	props: {
		sidesNumber: {
			type: Number,
			default: 0
		}
	},
	methods: {
		onClose() {
			this.$emit('close')
		},
		onClear() {
			this.$emit('clear')
		}
	}
}
</script>

<style lang="scss" scoped>
.panel {
	display: grid;
	grid-template-columns: 160px 1fr;
	grid-column-gap: 30px;
	align-items: center;

	@media screen and (max-width: 620px) {
		display: block;
		grid-template-columns: initial;
		grid-column-gap: initial;
	}

	&__left p {
		font-weight: 500;
		font-size: 16px;
		white-space: nowrap;
	}

	&__right {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-grow: 1;

		@media screen and (max-width: 620px) {
			margin-top: 10px;
		}
		@media screen and (max-width: 420px) {
			flex-wrap: wrap;
		}

		.panel-btn {
			display: flex;
			align-items: center;
			border-radius: 10px;

			@media screen and (max-width: 620px) {
				margin: 10px 0;
				flex-wrap: wrap;
				width: 100%;
				margin-right: 6px !important;
				margin-left: 6px !important;
			}

			&:not(:last-child) {
				margin-right: 6px;
			}

			&__icon {
				font-weight: 700;
			}

			&__title {
				display: block;
				// margin-left: 6px;
			}
		}
	}
}
</style>
