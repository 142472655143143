import cookie from "js-cookie";

/** Сервис для работы с токенами */
export default class ServiceToken {
    static keyProductToken = 'product_token';
    static keyAuthToken = 'auth_token';

    /**
     * Токен продукта
     * @static
     * @returns {String|null}
     */
    static get productToken() {
        return cookie.get(this.keyProductToken) || null;
    }

    /**
     * Токен сервиса авторизации
     * @static
     * @returns {String|null}
     */
    static get authToken() {
        return cookie.get(this.keyAuthToken) || null;
    }

    /**
     * Установить токен продукта
     * @static
     * @param {String} token - токен
     */
    static setProductToken(token = '') {
        cookie.set(this.keyProductToken, token, {domain: process.env.VUE_APP_PRODUCT_HOST});
    }

    /**
     * Установить токен сервиса авторизации
     * @static
     * @param {String} token - токен
     */
    static setAuthToken(token = '') {
        cookie.set(this.keyAuthToken, token, {domain: process.env.VUE_APP_GENERAL_HOST});
    }

    /**
     * Удалить токен продукта
     * @static
     */
    static removeProductToken() {
        if (this.productToken !== null)
            cookie.remove(this.keyProductToken, {domain: process.env.VUE_APP_PRODUCT_HOST});
    }

    /**
     * Удалить токен сервиса авторизации
     * @static
     */
    static removeAuthToken() {
        if (this.authToken !== null)
            cookie.remove(this.keyAuthToken, {domain: process.env.VUE_APP_GENERAL_HOST});
    }
}
