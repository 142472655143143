<template>
    <base-modal
        :title="title"
        @close="onClose"
    >
        <template v-slot:modalBody>
            <ExportTemplateBase
              v-if="formType === 'base'"
              :selectValues="selectValues"
              :selectedTemplate="selectedTemplate"
              @onClosePopup="onClose"
              @onEditTemplate="onEditTemplate"
              @onCreateTemplate="onCreateTemplate"
              @onCheckTemplate="onCheckTemplate"
              @onExportTemplate="onExportTemplate"
              @onRemoveTemplate="onRemoveTemplate"
            />
            <ExportTemplateEdit
                v-if="formType === 'edit' || formType === 'create'"
                :type="formType"
                :items="bufferCurrentTemplate.data"
                :name="bufferCurrentTemplate.name"
                :id="bufferCurrentTemplate.id"
                :countTemplates="userTemplates.length"
                @onClosePopup="onClose"
                @changeType="onChangeType"
                @onCancel="onChangeType('base')"
                @onSaveTemplate="onSaveTemplate"
            />
        </template>
    </base-modal>
</template>

<script>

import BaseModal from "@/components/Base/BaseModal";
import ExportTemplateBase from "@/components/Export/ExportTemplateBase";
import ExportTemplateEdit from "@/components/Export/ExportTemplateEdit";
import ServiceExcelExport from "../../services/ServicesExcel/ServiceExcelExport";
export default {
    name: 'ExportTemplate',
    components: {
        ExportTemplateBase,
        ExportTemplateEdit,
        BaseModal,
    },
    /**
     * Входные данные
     * @property {Object} excelInput - входные данные экспорта
     */
    props: {
        excelInput: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * Локальные данные
     * @property {String} formType - режим работы с шаблоном
     * @property {Object} bufferCurrentTemplate - текущий шаблон
     */
    data() {
        return {
            formType: 'base', // base, edit, create
            bufferCurrentTemplate: {},
            userTemplates: [],
            currentTemplate: {}
        }
    },
    mounted() {
        if(!this.userTemplates.length){
            ServiceExcelExport.getTemplates((result) => {
                const {templates = [], activeIndex = 0} = result;
                this.currentTemplate = templates[activeIndex]
                this.userTemplates = templates;
            })
        }
    },
    watch:{
        currentTemplate(){
            this.bufferCurrentTemplate = {...this.currentTemplate};
        }
    },
    computed: {
        title() {
            if (this.formType === 'edit') {
                return 'Изменить шаблон';
            }

            if (this.formType === 'create') {
                return 'Создать шаблон';
            }

            return 'Скачать файл Excel';
        },
        selectedTemplate(){
            let selected = Object.keys(this.currentTemplate).length ? [this.currentTemplate.id] : [];
            return selected;
        },
        selectValues(){
            let selectValues = [];
            this.userTemplates.forEach(item => {
                selectValues.push({id:item.id, value: item.name});
            })
            return selectValues;
        }
    },
    methods:{
        onClose(){
            this.$emit('onCloseModal');
        },
        onChangeType(type){
            this.formType = type;
        },
        onCreateTemplate(){
            this.bufferCurrentTemplate = {...this.userTemplates[0]};
            this.formType = 'edit';
        },
        onEditTemplate() {
            this.bufferCurrentTemplate = {...this.currentTemplate};
            this.onChangeType('edit');
        },
        onSaveTemplate(data){
            ServiceExcelExport.saveTemplate(data, (template) => {
                this.currentTemplate = template;
                const updatedIndex = this.userTemplates.findIndex(userTemplate => userTemplate.id == template.id)
                if(updatedIndex === -1){
                    this.userTemplates.push(template);
                }else {
                    this.userTemplates.splice(updatedIndex, 1, template);
                }
            })
            this.onChangeType('base');
        },
        onCheckTemplate(id){
            this.currentTemplate = Object.assign({}, this.userTemplates.find(item => item.id == id));
        },
        onExportTemplate(){
            let excelInput = {...this.excelInput, currentTemplate: this.currentTemplate};
            ServiceExcelExport.exportTemplate(excelInput, (link) => {
                window.open(link, "_blank")
            })
        },
        onRemoveTemplate(){
            ServiceExcelExport.removeTemplate(this.currentTemplate, () => {
                const indexRemove = this.userTemplates.findIndex(item => item.id === this.currentTemplate.id);
                this.userTemplates.splice(indexRemove, 1);
                this.currentTemplate = this.userTemplates[this.userTemplates.length - 1]
            })
        }
    }
}
</script>
