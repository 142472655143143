const clientStatuses = [
    {
        id: 'agree',
        value: 'Согласовано'
    },
    {
        id: 'remove',
        value: 'Удаляем'
    },
    {
        id: 'stock',
        value: 'Запас. Выборка'
    },
    {
        id: 'reset',
        value: 'Вернуть Агентский'
    }
];

/**
 * Схема для панели наборов
 * @property {Array<Object>} SchemeActionPanel.clientStatuses - действия над статусами пользователя
 * @property {String} SchemeKitPanel.clientStatuses[].id - идентификатор статуса
 * @property {String} SchemeKitPanel.clientStatuses[].value - название статуса
 */
const SchemeActionPanel = {
    clientStatuses: clientStatuses,
    actionsMobile: [
        {
            id: 'clear',
            value: 'Очистить',
            isMulti: false,
            isCanShare: true,
            emitName: 'onClearSelected',
            items: []
        },
        {
            id: 'summary',
            value: 'Сводные данные',
            isMulti: false,
            isCanShare: true,
            emitName: 'onSummarySelected',
            items: []
        },
        {
            id: 'adMetrix',
            value: 'AdMetrix',
            isMulti: false,
            isCanShare: true,
            emitName: 'onEsparSelected',
            items: []
        },
        {
            id: 'download',
            value: 'Скачать Excel',
            isMulti: false,
            isCanShare: true,
            emitName: 'onExportSelected',
            items: []
        },
        {
            id: 'delete',
            value: 'Удалить',
            isMulti: false,
            isCanShare: false,
            emitName: 'onDelete',
            items: []
        },
        {
            id: 'create',
            value: 'Создать',
            isMulti: true,
            isCanShare: false,
            emitName: '',
            items: [
                {
                    id: 'createMaket', 
                    value: 'Создать макет',
                    isMulti: false,
                    isCanShare: false,
                    emitName: 'onSelectAction',
                    items: []
                },
                {
                    id: 'createProject', 
                    value: 'Создать проект',
                    isMulti: false,
                    isCanShare: false,
                    emitName: 'onSelectAction',
                    items: []
                },
                {
                    id: 'createProposal', 
                    value: 'Создать предложение',
                    isMulti: false,
                    isCanShare: false,
                    emitName: 'onSelectAction',
                    items: []
                },
            ]
        },
        {
            id: 'status',
            value: 'Статус',
            isMulti: true,
            isCanShare: true,
            emitName: '',
            items: [
                {
                    id: 'agree',
                    value: 'Согласовано',
                    isMulti: false,
                    isCanShare: true,
                    emitName: 'onChangeUserStatus',
                    items: []
                },
                {
                    id: 'remove',
                    value: 'Удаляем',
                    isMulti: false,
                    isCanShare: true,
                    emitName: 'onChangeUserStatus',
                    items: []
                },
                {
                    id: 'stock',
                    value: 'Запас. Выборка',
                    isMulti: false,
                    isCanShare: true,
                    emitName: 'onChangeUserStatus',
                    items: []
                },
                {
                    id: 'reset',
                    value: 'Вернуть Агентский',
                    isMulti: false,
                    isCanShare: true,
                    emitName: 'onChangeUserStatus',
                    items: []
                }
            ]
        }
    ]
};

export default SchemeActionPanel;
