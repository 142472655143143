<template>
	<div class="draggble-window">
		<div class="draggble-window__top-resizer" @mousedown="onResize($event, 'top')" />
		<div class="draggble-window__right-resizer" @mousedown="onResize($event, 'right')" />
        <div class="draggble-window__right-resizer_se" @mousedown="onResizeSe($event)"/>
		<div class="draggble-window__bottom-resizer" @mousedown="onResize($event, 'bottom')" />
        <div class="draggble-window__bottom-resizer_se" @mousedown="onResizeSe($event)"/>
		<div class="draggble-window__left-resizer" @mousedown="onResize($event, 'left')" />

		<div class="draggble-window-panel" @mousedown.self="onMove($event, $el)">
			<!-- <div class="draggble-window-panel__drag" /> -->
            <div class="draggble-window-panel__head">
                <div :style="{'background-color': color}" class="draggble-window-panel__item_color"/>
			<div class="draggble-window-panel__title-area">
				<base-text-input
					v-if="title && renameMode"
					ref="BaseTextInputWindow"
					:value="title"
					@input="onUpdateName"
					@onBlur="onBlurInputWindow"
					class="draggble-window-panel__input"
				/>
				<p
					v-if="title && !renameMode"
					v-text="title"
					class="draggble-window-panel__title"
					@click="onClickNameWindow"
				/>
			</div>
            </div>


			<div class="custom-panel">
				<slot name="panel" />
			</div>

			<div class="draggble-window-panel-controls" v-if="hasPanel">
				<div v-if="isMinimize" class="draggble-window-panel-controls__el" @click="$emit('onMinimized')">
					<BaseIcon icon="icon-tabler-icon-minus" color="#000" />
				</div>
				<div
					class="draggble-window-panel-controls__el close"
					@click="$emit('onClose')"
				>
					<BaseIcon icon="icon-tabler-icon-x" size="md" class="close__icon" />
				</div>
			</div>
		</div>

		<div class="draggble-window-summary">
			<slot name="summary" />
		</div>

        <!-- <slot name="panel"></slot> -->

		<div class="draggble-window__content">
			<slot name="content" class="dd"></slot>
		</div>
	</div>
</template>

<script>
import BaseIcon from "./BaseIcon.vue"
import BaseTextInput from '@/components/Base/BaseTextInput';
import MixinRename from '@/mixins/MixinRename';
export default {
	name: "BaseDraggbleWindow",
    mixins: [MixinRename],
	props: {
		hasPanel: {
			type: Boolean,
			default: false
		},
		title: {
			type: [Number, String],
			default: ''
		},
        isMinimize: {
            type: Boolean,
            default: true
        },
        isRename: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        }
	},
	components: {
		BaseIcon,
        BaseTextInput
	},
	data() {
		return {
			startPoint: 0,
            finishPoint: 0,

			startPointX: 0,
			startPointY: 0,
			finishPointX: 0,
			finishPointY: 0,

            resizeSeStartX: 0,
            resizeSeStartY: 0,
            resizeSeFinishX: 0,
            resizeSeFinishY: 0
		}
	},
	methods: {
        /**
         * Событие клика по названию окна
         */
        onClickNameWindow() {
            if (this.isRename) {
                this.refInput = 'BaseTextInputWindow';
                this.onClickName();
            }
        },
        /**
         * Событие снятия фокуса с окна
         */
        onBlurInputWindow() {
            this.onBlurInput();
            this.onRenameWindow();
        },
        /**
         * Событие переименования окна
         */
        onRenameWindow() {
            if (this.newName !== '' && this.newName !== this.title)
                this.$emit('onRenameWindow', this.newName);
        },
		onMove(event, target) {
            document.body.style.userSelect = 'none';
			this.startPointX = event.clientX;
			this.startPointY = event.clientY;

            let leftPosition = '0';
            let topPosition = '0';

            const move = (event) => {
				this.finishPointX = event.clientX;
				const differenceX = this.finishPointX - this.startPointX;
				const left  = parseInt(getComputedStyle(target).left);
                leftPosition = (left + differenceX) + 'px';
				target.style.left = leftPosition;
				this.startPointX = this.finishPointX;

				this.finishPointY = event.clientY;
				const differenceY = this.finishPointY - this.startPointY;
				const top  = parseInt(getComputedStyle(target).top);
                topPosition = (top + differenceY) + 'px';
                target.style.top = topPosition;
				this.startPointY = this.finishPointY;
            }

            // Удаляю слушатели
            document.addEventListener('mousemove', move);
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', move);
                document.body.style.userSelect = 'initial';

                this.$emit('onMoved', leftPosition, topPosition);
            }, {once: true});
        },
        onResizeSe(event) {
            this.resizeSeStartX = event.clientX;
            this.resizeSeStartY = event.clientY;
            const target = this.$el;
            document.body.style.userSelect = 'none';
            let sumX = '0';
            let sumY = '0';
            const onResizeSeEnd = (event) => {
                this.resizeSeFinishX = event.clientX;
                this.resizeSeFinishY = event.clientY;
                const differenceX = this.resizeSeFinishX - this.resizeSeStartX;
                const differenceY = this.resizeSeFinishY - this.resizeSeStartY;
                sumX = (differenceX + target.offsetWidth) + 'px';
                sumY = (differenceY + target.offsetHeight) + 'px';
                this.resizeSeStartX = this.resizeSeFinishX;
                this.resizeSeStartY = this.resizeSeFinishY;
                target.style.width = sumX;
                target.style.height = sumY;
            }
            document.addEventListener('mousemove', onResizeSeEnd);
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeSeEnd)
                document.body.style.userSelect = 'initial';
				this.resizeSeStartX = 0;
				this.resizeSeStartY = 0;
                this.resizeSeFinishX = 0;
                this.resizeSeFinishY = 0;

                const width = sumX === '0' ? target.offsetWidth : sumX;
                const height = sumY === '0' ? target.offsetHeight : sumY;

                this.$emit('onResized', width, height);
            }, {once: true});

            this.$emit('onResize', event);
        },
		onResize(event, direction) {
			const target = this.$el;
            document.body.style.userSelect = 'none';
            let sumX = '0';
            let sumY = '0';
            if (direction === 'right' || direction === 'left') {
				this.startPoint = event.clientX;
			} else if (direction === 'top' || direction === 'bottom') {
				this.startPoint = event.clientY;
			}

            const onResizeCeilEnd = (event) => {
				if (direction === 'right') {
					this.finishPoint = event.clientX;
					const difference = this.finishPoint - this.startPoint;
					sumX = (difference + target.offsetWidth) + 'px';
					this.startPoint = this.finishPoint;
					target.style.width = sumX;
				}
				if (direction === 'bottom') {
					this.finishPoint = event.clientY;
					const difference = this.finishPoint - this.startPoint;
					sumY = (difference + target.offsetHeight) + 'px';
					this.startPoint = this.finishPoint;
					target.style.height = sumY;
				}
				if (direction === 'top') {
					this.finishPoint = event.clientY;
					const difference = this.finishPoint - this.startPoint;
					sumY = (target.offsetHeight - difference) + 'px';
					const top  = parseInt(getComputedStyle(target).top);
					target.style.top = top + difference + 'px';
					this.startPoint = this.finishPoint;
					target.style.height = sumY;
				}
				if (direction === 'left') {
					this.finishPoint = event.clientX;
					const difference = this.finishPoint - this.startPoint;
					sumX = (target.offsetWidth - difference) + 'px';
					const left  = parseInt(getComputedStyle(target).left);
					target.style.left = left + difference + 'px';
					this.startPoint = this.finishPoint;
					target.style.width = sumX;
				}
            }

            // Удаляю слушатели
            document.addEventListener('mousemove', onResizeCeilEnd);
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', onResizeCeilEnd);
                document.body.style.userSelect = 'initial';
				this.finishPoint = 0;
				this.startPoint = 0;

                const width = sumX === '0' ? target.offsetWidth : sumX;
                const height = sumY === '0' ? target.offsetHeight : sumY;

                this.$emit('onResized', width, height);
            }, {once: true});

            this.$emit('onResize', event);
        }
	}
}
</script>

<style lang="scss" scoped>
$resizerSize: 5px;
%resizer {
	background: transparent !important;
	position: absolute;
	z-index: 2;
}

.draggble-window {
	overflow: hidden;
	position: absolute;
	background: #fff;
	min-width: 140px;
    min-height: 140px;
	display: flex;
	flex-direction: column;

	&__content {
		flex-grow: 1;
		overflow: hidden;
        background: #fff;
        height: 100%;
	}

	&-panel {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		flex-shrink: 0;
		padding: 10px 10px 10px 20px;
		cursor: grab;
        z-index: 3;

        &__head {
            display: flex;
            align-items: center;
            height: 36px;
            min-width: 100px;
        }
        &__item_color {
            min-width: 16px;
            min-height: 16px;
            width: 16px;
            height: 16px;

            border-radius: 50%;
            // margin-left: 10px;
        }

        &__input {
            width: 30%;
            margin-left: 5px;
        }

		&__title-area {
            width: 100%;
			height: 100%;

			display: flex;
			align-items: center;

            margin-left: 5px;
            padding-right: 30px;

			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}

		&__title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

			font-size: 12px;
			font-weight: 700;
		}

		// .custom-panel {

		// }

		&-controls {
			display: flex;
			z-index: 1;

			&__el {
				width: 24px;
				height: 24px;
				text-align: center;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.close {
				margin-right: 12px;
				color: #B9BBC2;
				font-size: 12px;
			}
		}
	}

	&-summary {
		background: #fff;
		// padding: 0px 15px;
		padding: 5px 20px;
        z-index: 3;
	}

	&__right-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
        height: 100%;
		right: 0px;
		top: 0px;
	}
    &__right-resizer_se {
		@extend %resizer;
		cursor: se-resize;
        width: 5px;
        height: 40px;
        right: 0px;
        bottom: 0px;
	}
	&__top-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		right: 0px;
		top: 0px;
	}
	&__bottom-resizer {
		@extend %resizer;
		cursor: ns-resize;
		width: 100%;
		height: $resizerSize;
		// right: 0px;
        left: 0px;
		bottom: 0px;
	}
    &__bottom-resizer_se {
		@extend %resizer;
		cursor: se-resize;
		width: 40px;
		height: $resizerSize;
        right: 0px;
		bottom: 0px;
	}
	&__left-resizer {
		@extend %resizer;
		cursor: ew-resize;
		width: $resizerSize;
		height: 100%;
		left: 0px;
		top: 0px;
	}
}
</style>