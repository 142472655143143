import ServiceApi from '../ServiceApi';
import UtilValidation from '../../utils/UtilValidation';
import UtilSort from '../../utils/UtilSort';

export default class ServiceConstructionData {
    static _microserviceName = 'apiMap';

    static _requestRouts = {
        getMassEditData: '/main/get-mass-edit-data',
        setMassEditData: '/main/mass-edit'
    };

    static _massEditDataValidationRules = {
        string: (value) => UtilValidation.validationString(value),
        number: (value = '') => UtilValidation.validationPositiveNumber(value),
        dropdown: (value = '', values = []) => {
            return values.includes(value);
        }
    };

    static _massEditDataComponents = {
        string: 'BaseTextInput',
        number: 'BaseTextInput',
        dropdown: 'BaseSelect'
    };

    static async getMassEditData(actionAfter = () => {}) {
        await this._getMassEditData(actionAfter);
    }

    static async _getMassEditData(actionAfter = () => {}) {
        try {
            const resBody = await ServiceApi.get(this._microserviceName, this._requestRouts.getMassEditData);
            const {data = {}} = resBody; 
            const massEditDataAfter = this._getMassEditDataAfter(data);
            
            actionAfter(massEditDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    static _getMassEditDataAfter(massEditData = {}) {
        const massEditDataAfter = Object.keys(massEditData).map(massEditDataKey => {
            const massEditDataItem = massEditData[massEditDataKey];
            const {label = '', type = '', values = {}} = massEditDataItem;
            
            const valuesAfter = Object.keys(values).map(valueKey => {
                const valueAfter = {
                    id: String(valueKey),
                    value: String(values[valueKey])
                };

                return valueAfter;
            });

            const valuesAfterSorted = UtilSort.sortByAlphabet(valuesAfter, 'inc', (valueAfter) => String(valueAfter?.value));
            
            const massEditDataItemAfter = {
                id: String(massEditDataKey),
                value: String(label),
                type: String(type),
                validationRule: this._massEditDataValidationRules[type] ?? (() => true),
                componentName: this._massEditDataComponents[type] ?? '',
                values: valuesAfterSorted
            };

            return massEditDataItemAfter
        });

        const massEditDataAfterSorted = UtilSort.sortByAlphabet(massEditDataAfter, 'inc', (massEditDataItem) => String(massEditDataItem?.value));

        return massEditDataAfterSorted;
    }

    static async setMassEditData(priceIds = [], propId = '', propValue = '', actionAfter = () => {}) {
        await this._setMassEditData(priceIds, propId, propValue, actionAfter);
    }

    static async _setMassEditData(priceIds = [], propId = '', propValue = '', actionAfter = () => {}) {
        const reqBody = this._setMassEditDataBefore(priceIds, propId, propValue);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.setMassEditData, reqBody);
            const {data = {}} = res;
            const {price_groups_ids: kitItemsIds = []} = data;
            const kitItemsIdsAfter = kitItemsIds.map(kitItemId => String(kitItemId));
            actionAfter(kitItemsIdsAfter);
        } catch(error) {
            console.log(error);
            const {response: {data: {data: {ids = ''} = {}} = {}} = {}} = error;
            alert(ids);
        }
    }

    static _setMassEditDataBefore(priceIds = [], propId = '', propValue = '') {
        const reqBody = {
            ids: priceIds.map(id => String(id)),
            [propId]: String(propValue)
        };

        return reqBody;
    }
}