<template>
    <div class="additional-photo">
        <h3 class="additional-photo__title">Дополнительные фото</h3>

        <div class="additional-photo__content">
            <base-carousel
                :carousel-list="carouselList"
                :pagination-size="10"
                pagination-color="#bbb"
                pagination-active-color="#777"/>
        </div>

    </div>
</template>

<script>
import BaseCarousel from "@/components/Base/BaseCarousel";

export default {
    name: "AdditionalPhoto",
    components: {
        BaseCarousel,
    },

    computed: {
        carouselList() {
            return [
                {
                    linkText: 'Дата загрузки фото: 10.10.2010',
                    linkUrl: '#',
                    src: 'https://via.placeholder.com/200/ff0000',
                },
                {
                    linkText: 'Дата загрузки фото: 11.11.2011',
                    linkUrl: '#',
                    src: 'https://via.placeholder.com/220/00ff00',
                },
                {
                    linkText: 'Дата загрузки фото: 12.12.2012',
                    linkUrl: '#',
                    src: 'https://via.placeholder.com/250/0000FF',
                }
            ];
        },
    },
}
</script>

<style lang="scss">
.additional-photo__content .slider {
    margin-bottom: 0;
}

.additional-photo__content .slider .slider__image {
    margin-bottom: 10px;
}

.additional-photo__content .slider .slider__wrapper {
    padding: 10px;
}
</style>

<style lang="scss" scoped>
$color-gray: #b9bbc2;
$color-green: #6ec87a;

.additional-photo {
    padding: 10px;
    background: #fff;

    &__title {
        display: block;
        width: max-content;
        font-weight: 400;
        padding: 5px;
        font-size: 11px;
        background: #00aa00;
        color: #fff;

        margin-bottom: 15px;
    }

    //&__content {
    //
    //}

    &__link {
        color: $color-green;

        &:hover, &:focus {
            color: #000;
        }
    }

}
</style>
