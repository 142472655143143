<template>
    <div class="kit-panel-placement-add" :class="{'row': direction === 'row', 'column': direction === 'column'}">
        <div class="kit-panel-placement-add__title"> Размещения нет :( </div>
        <BaseButton view="simple">
            <a :href="placementAddRoute" target="_blank">
                <div class="kit-panel-placement-add__btn-title"> Загрузить </div>
            </a>
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue';
export default {
    name: 'KitPanelPlacementAdd',
    components: {BaseButton},
    props: {
       direction: {
            type: String,
            default: 'row'
        }
    },
    data: () => ({
        placementAddRoute: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/import`
    })
}
</script>

<style lang="scss" scoped>
    .kit-panel-placement-add {
        display: flex;
        height: 80px;
        min-width: 180px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #F4F5F5;
        border-radius: 10px;

        &.row {
            margin-right: 10px;
        }
        &.column {
            margin-bottom: 10px;
        }

        &__title {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #AAB0B8;
            margin-bottom: 5px;
        }

        &__btn-title {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #4A91F1;
        }
    }
</style>
