export default {
    data: () => ({
        pricesColor: {
            free: '#C5E9CA',
            reserved: '#FFB300',
            booked:'#F84967'
        }
    }),
    props: {
		columns: {
			type: Array,
			default: () => ([])
		},
		tableData: {
			type: Array,
			default: () => ([])
		},
		isProgressBarLoading: {
			type: Boolean,
			default: false
		},
		modifieredCols: {
			type: Array,
			default: () => ([])
		}
	},
    methods: {
		/**
		 * @param {Object} $event - название колонки по которой был произведен клик в шапке таблицы
		 */
		onSort($event) {
			this.$emit('sort', $event)
		},
		/**
		 * @param {Object} $event - объект содержащий значения для фильрации
		 */
		onFilter($event) {
			this.$emit('filter', $event)
		},
		onLazyload() {
			this.$emit('lazyload')
		},
        getPriceColor(tableData = {}) {
            const {column = {}, row = {}} = tableData;
            const {allPrices = null, prices = []} = row;
            const {prop = ''} = column;
            const currentPrices = allPrices === null ? Object.assign([], prices) : Object.assign([], allPrices);
            const price = currentPrices.find(price => String(price.date) === String(prop)) || {};
            // eslint-disable-next-line no-unused-vars
            const {status = ''} = price;
            const color = this.pricesColor[status] || '#4A92F6';
            return color;
        }
	},
};