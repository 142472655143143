<template>
    <BaseButton
        :view="'border'"
        @click="onToggleVisible"
    >
        <IconArrowV2 :class="{
            'base-button-toggle-visible__icon_visible': isVisible,
            'base-button-toggle-visible__icon_unvisible': !isVisible 
        }" />
    </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';
import IconArrowV2 from '../Icons/IconArrowV2.vue';
export default {
    name: 'BaseButtonToggleVisible',
    components: { 
        BaseButton,
        IconArrowV2
    },
    props: {
        isVisible: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onToggleVisible() {
            this.$emit('update:isVisible', !this.isVisible);
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-button-toggle-visible {
        &__icon {
            &_visible {
                transform: rotate(180deg);
                transition: all .4s ease-out;
            }

            &_unvisible {
                transition: all .4s ease-out;
            }
        }
    }
</style>