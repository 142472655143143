<template>
    <div class="control-panel-block">
        <div
            class="control-panel-block__header"
            @click="onToggleShow"
        >
            <div class="control-panel-block__title">
                {{panelBlockTitle}}
            </div>
        </div>
        <div
            v-show="isShow"
            class="control-panel-block__items"
        >
            <control-panel-item
                v-for="(panelItem, index) in panelBlockItems"
                :key="index"
                :panelItem="panelItem"
                :isActive="getPanelItemActive(panelItem.id)"
                @onClickPanelItem="onClickPanelItem"
            />
        </div>
    </div>
</template>

<script>
import ControlPanelItem from "./ControlPanelItem";
export default {
    name: "ControlPanelBlock",
    components: {ControlPanelItem},
    /**
     * Входные данные
     * @property {Object} panelBlock - блок элементов панели
     * @property {Array} panelIdsActive - активные элементы панели
     * @property {boolean} isShow - условие отображения
     */
    props: {
        panelBlock: {
            type: Object,
            default: () => ({})
        },
        panelIdsActive: {
            type: Array,
            default: () => ([])
        },
        isShow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Заголовок блока
         * @returns {string}
         */
        panelBlockTitle() {
            const {title = ''} = this.panelBlock;
            return String(title);
        },
        /**
         * Элементы блока
         * @returns {Array}
         */
        panelBlockItems() {
            const {items = []} = this.panelBlock;
            return items;
        },
        /**
         * Ключ блока
         * @returns {string}
         */
        panelBlockKey() {
            const {key = ''} = this.panelBlock;
            return String(key);
        },
    },
    methods: {
        /**
         * Получить активность элемента панели
         * @param {String} panelItemId - идентификатор элемента панели
         * @returns {Boolean}
         */
        getPanelItemActive(panelItemId = '') {
            return this.panelIdsActive.includes(String(panelItemId));
        },
        /**
         * Событие клика по элементу панели
         * @param {string} panelItemId - идентификатор элемента панели
         */
        onClickPanelItem(panelItemId = '') {
            this.$emit('onClickPanelItem', panelItemId);
        },
        /**
         * Переключить видимость блока
         */
        onToggleShow() {
            this.$emit('onToggleShowPanelBlock', this.panelBlockKey);
        }
    }
}
</script>

<style lang="scss" scoped>
$color-background: #F2F2F2;
$color-gray: #AFB0B4;
    .control-panel-block {
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            background: $color-background;
            padding: 4px 5px;
            border-radius: 4px;
            cursor: pointer;
            color: $color-gray;
            transition: all .4s ease-out;

            &:hover {
                color: #000;
            }
        }
        &__title {
            font-size: 8px;
            letter-spacing: 0;
        }
        &__items {
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
