<template>
    <div class="description">
        <BasePreloaderSkeleton 
            v-if="isLoading"
            class="description__preloader" 
        />
        <div class="description__header">
            <div class="description__company">
                <div v-if="params.supplier" class="description__company-name">{{params.supplier}}</div>
                <a v-if="params.imageSrc" target="_blank" :href="params.imageSrc">
                    <span class="description__icon-link icon-tabler-icon-external-link"></span>
                </a>
            </div>
            <div class="description__last-update">Обновлено {{params.updatedAt}}</div>
        </div>
        <div class="description__address-block">
            <!-- <span v-if="params.opnNumber" class="description__title-num">{{params.opnNumber}}</span> -->
            <span class="description__title">
                {{params.city}}, {{params.address}}
            </span>
        </div>
        <div class="description__table">
            <div v-if="params.opnNumber" class="description__table-column">
                <div class="description__table-head">№</div>
                <div class="description__table-value">{{params.opnNumber}}</div>
            </div>
            <div v-if="params.gid" class="description__table-column">
                <div class="description__table-head">GID</div>
                <div class="description__table-value">{{params.gid}}</div>
            </div>
            <div v-if="params.kind" class="description__table-column">
                <div class="description__table-head">Вид</div>
                <div class="description__table-value">{{params.kind}}</div>
            </div>
            <div v-if="params.grp" class="description__table-column">
                <div class="description__table-head">GRP</div>
                <div class="description__table-value">{{params.grp}}</div>
            </div>
            <div v-if="params.ots" class="description__table-column">
                <div class="description__table-head">OTS</div>
                <div class="description__table-value">{{params.ots}}</div>
            </div>
            <div v-if="params.moveDirection" class="description__table-column">
                <div class="description__table-head">Направление</div>
                <div class="description__table-value">{{params.moveDirection}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
export default {
    name: "Description",
    components: {BasePreloaderSkeleton},
    props: {
        params: {
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-gray-300: #AAB0B8;

.description {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid $color-border;

    &__preloader {
        top: 0;
        left: 0;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 7px;
    }

    &__company {
        display: flex;
    }

    &__company-name {
        font-size: 12px;
        letter-spacing: 0;
        margin-right: 7px;
    }

    &__icon-link {
        color: #000;
        font-size: 14px;
    }

    &__last-update {
        font-size: 10px;
        letter-spacing: 0;
        color: $color-gray-300;
    }

    &__address-block {
        // display: flex;

        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;

        margin-bottom: 10px;
    }

    &__title-num {
        // padding-right: 5px;
        color: #AFB0B4;
    }

    &__table {
        display: flex;
        flex-wrap: wrap;
    }

    &__table-column {
        display: flex;
        padding: 6px 10px;
        background: #F5F5F5;
        border-radius: 50px;
        margin-bottom: 4px;
        &:not(:last-child) {
            margin-right: 4px;
        }
        // padding: 0 20px;
        // border-right: 1px solid $color-border;

        // &:first-child {
        //     padding-left: 0;
        // }

        // &:last-child {
        //     padding-left: 0;
        //     border-right: none;
        // }
    }

    &__table-head {
        // font-size: 10px;
        font-size: 12px;
        letter-spacing: 0;
        color: $color-gray-300;
        margin-right: 5px;
        // margin-bottom: 2px;
    }

    &__table-value {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        color: #000;
    }
}
</style>
