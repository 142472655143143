import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import LayoutAuth from "../layouts/LayoutAuth";
import PageMapV2 from "../pages/PageMapV2";
import ServiceTokenObserver from "../services/ServicesAuth/ServiceTokenObserver";
import ServiceShare from '../services/ServicesAuth/ServiceShare';
import ServiceToken from '../services/ServicesAuth/ServiceToken';
import UtilRouteParse from '../utils/UtilRouteParse';

const router = new VueRouter({
	routes: [
        {
            path: '/auth',
            name: 'auth',
            meta: { title: 'Аутентификация', layout: ''},
            component: LayoutAuth
        },
		{
			path: '/',
            redirect: '/map',
		},
        {
            path: '/login',
            name: 'login',
            meta: { title: 'Вход', layout: 'LayoutLoginV2' },
            component: () => import('../pages/PageLoginV2'),
        },
        {
            path: '/map',
            meta: {title: 'Размещение', layout: 'WithFilterLayout', auth: true},
            component: PageMapV2,
            name: 'map',
            // props: (route) => ({ isShare: typeof(route.query.shareToken) !== 'undefined' && route.query.shareToken !== null})
            props: (route) => ({...UtilRouteParse.getPropsQuery('map', route.query)})
        },
        {
            path: '/map/share',
            meta: {title: 'Размещение', layout: 'WithFilterLayout', auth: true},
            component: PageMapV2,
            name: 'map',
            // props: (route) => ({ isShare: typeof(route.query.shareToken) !== 'undefined' && route.query.shareToken !== null})
            props: (route) => ({...UtilRouteParse.getPropsQuery('map', route.query)})
        },
        {
            path: '/profile',
            name: 'map',
            meta: { title: 'Профиль', layout: 'LayoutUserProfile', auth: true },
            component: () => import('../pages/PageUserProfile'),
            props: (route) => ({...UtilRouteParse.getPropsQuery('map', route.query)})
        },
        {
            path: '/profile/access',
            name: 'map',
            meta: { title: 'Контроль прав доступа', layout: 'LayoutUserProfile', auth: true },
            component: () => import('../pages/PageUserAccess'),
            props: (route) => ({...UtilRouteParse.getPropsQuery('map', route.query)})
        },
	],
	mode: 'history',
	scrollBehavior: () => ({ x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
    Vue.nextTick( () => {
        document.title = to.meta.title ? to.meta.title : 'oohdesk';
    });
    const {meta = {}, name = '', query = {}} = to;
    const {auth = false} = meta;
    const {shareToken = null} = query;
    console.log(shareToken);
    if (window.serviceTokenObserver !== null && typeof(window.serviceTokenObserver) !== 'undefined')
        window.serviceTokenObserver.clearTokenListener();

    window.serviceTokenObserver = null;
    window.serviceShare = null;

    if (name === 'login') {
        window.serviceTokenObserver = new ServiceTokenObserver(false);
        if (!window.serviceTokenObserver.isLogin)
            next({name: 'map'});
        else
            next();
    }
    if (auth) {
        if (shareToken === null) {
            window.serviceTokenObserver = new ServiceTokenObserver(true);
            if(window.serviceTokenObserver.isLogin)
                next({name: 'login'});
            else
                next();
        } else {
            window.serviceShare = new ServiceShare(shareToken);
            if (ServiceToken.productToken !== null) {
                window.serviceShare.setShareProductToken(ServiceToken.productToken);
                next();
            } else {
                window.serviceShare.loginGuest(() => next());
            }

        }
    }
    else
        next();
});

export default router;
