<template>
    <div class="summary-item-group">
<!--        <div class="summary-item-group__head">-->
<!--            <div class="summary-item-group__head-title">{{name}}</div>-->
<!--            <div-->
<!--                v-if="value !== null"-->
<!--                class="summary-item-group__head-value"-->
<!--            >-->
<!--                {{preparePrice(value)}}-->
<!--            </div>-->
<!--        </div>-->
        <div
            v-if="params.length"
            class="summary-item-group__body"
        >
            <div
                v-for="(param, index) in params"
                :key="index"
                class="summary-item-group__sum">
                <div class="summary-item__sum-name">{{param.name}}</div>
                <div class="summary-item__sum-value">{{param.value}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdMetrixItemParams",
        /**
         *
         *
         * @return {Object} group
         */
        props: {
            params: {
                type: Array,
                default: () => [],
            }
        },
        computed:{

        },
        methods:{

        }
    }
</script>

<style lang="scss" scoped>
    .summary-item-group{
        box-sizing: border-box;
        margin-top: 8px;
        background: #FFFFFF;
        /* gray light */
        border: 1px solid #F4F5F5;
        border-radius: 4px;

        &__head{
            display: flex;
            padding: 8px;
        }

        &__body{
            background: #F4F5F5;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 0 0 4px 4px;
            padding: 8px 8px 4px 8px;
        }

        &__head-title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
        }

        &__head-value{
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height, or 120% */
            text-align: right;
            /* black */
            color: #000000;

            margin-left: auto;
        }

        &__sum{
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height, or 120% */
            /* gray */
            color: #AAB0B8;
        }

        // &__sum-count{

        // }

        // &__sum-value{
        //     /*margin-left: auto;*/
        // }
    }
</style>
