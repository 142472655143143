<template>
    <div
        class="wrapper"
        :class="getRootClasses"
    >
        <input
            v-show="!isShowPass"
            class="input"
            type="password"
            ref="input"
            v-model="password"
            @input="onInput"
            @blur="onBlur"
        >
        <input
            v-show="isShowPass"
            class="input"
            type="text"
            ref="input"
            v-model="password"
            @input="onInput"
            @blur="onBlur"
        >

        <div
            v-if="isShowPassIcon"
            class="show-pass"
            @click="onShowPass">
            <span class="icon-view show-pass-icon" :class="{'show-pass-icon--hidden' : isShowPass}"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BasePasswordInput",
    props: {
        modificator: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
            validator: value => ['text', 'password'].includes(value)
        },
        value: {
            type: String,
        },
        canClear: {
            type: Boolean,
            default: false
        },
        isShowPassIcon: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
            default: 'line',
            validator: value => ['base', 'line', 'primary'].includes(value)
        }
    },
    data: () => ({
        inputLazyTimerId: null,
        isShowPass: false,
        password: '',
    }),
    computed: {
        /**
         * Условие рендера крестика очистки инпута
         * @returns {Boolean}
         */
        isClear() {
            return this.canClear && this.value !== '' && this.value !== undefined;
        },
        /**
         * Классы для корневого элемента
         */
        getRootClasses() {
            const classes = ['wrapper_' + this.view];

            if (this.modificator) {
                classes.push(`wrapper--${this.modificator}`);
            }

            return classes;
        },
    },
    methods: {
        onInput(event) {
            const value = event === null ? '' : event.target.value
            this.$emit('input', value)
            //TODO: при событии ввода вызываю событие лениового ввода, которое либо дропнет предудыщее, либо выполнится
            this.onInputLazy(value);
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputLazy', value);
            }, 300);
        },
        onClear() {
            this.$refs.input.value = ''
            this.onInput(null)
        },
        /**
         * Событие для фокусировки инпута
         */
        onFocus() {
            this.$refs.input.focus();
        },
        /**
         * Событие снятия фокусировки с инпута
         */
        onBlur(event) {
            this.$emit('onBlur', event);
        },
        /**
         * Событие для показа символов пароля
         */
        onShowPass() {
            this.isShowPass = !this.isShowPass;
            this.$emit('onShowPass', this.onShowPass);
        },
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;
$bg-input-color:            #F5F5F5 !default;
$input-color:               #000000 !default;
$show-pass-color:           #848484 !default;
$hidden-pass-color:         #E10032 !default;

.wrapper {
    position: relative;
    display: block;
    width: 100%;

    &_base {
        .input {
            display: block;
            width: 100%;
            padding: 0 .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
            }
        }
    }

    &_line {
        .input {
            display: block;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            border: none;
            border-bottom: 1px solid;
            border-color: #E7E7EB;
            background: none;

            &:focus {
                outline: none;
                border-color: #000;
                color: #000;
            }
        }
    }

    &_primary {
        .input {
            background: $primary-color;
            border: none;
            outline: none;
            height: 36px;
            width: 100%;
            display: block;
            padding-left: 15px;
            font-weight: 700;
            font-size: 12px;
            color: #000;
            border-radius: 4px;

            &::placeholder {
                color: inherit;
                font-weight: inherit;
                font-size: inherit;

            }
        }
    }

    .clear {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
        line-height: 1.5;
        font-weight: 700;
        font-size: 10px;
        color: #000;

        &:hover {
            color: red;
        }
    }

    .show-pass {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        color: black;
    }

    .show-pass-icon {
        font-size: 18px;
        color: $show-pass-color;

        &--hidden {
            color: $hidden-pass-color;
        }
    }

    &--login {
        .input {
            height: 44px;
            font-size: 14px;
            font-weight: 400;
            color: $input-color;
            background: $bg-input-color;
            box-sizing: border-box;
        }
    }
}
</style>
