export default {
    namespaced: true,
    state: {
        selectedSides: [],
    },
    mutations: {
        setSelectedSidesData(state, selectedSidesData) {
            state.selectedSides = selectedSidesData;
        }
    }
}
