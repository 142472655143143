<template>
	<BaseDraggbleWindow
		v-show="isOpenFolder"
		class="folder"
		:style="getRootStyles"
		:hasPanel="false"
		:title="title"
        :isMinimize="isMinimize"
        :isRename="isRename"
        :color="color"
		@mousedown.native="bringToFront(id)"
		@onMinimized="collapseFolder(id)"
		@onClose="closeFolder(id)"
        @onRenameWindow="onRenameFolder"
        @onResize="$emit('onResize')"
        @onResized="onChangeSizeFolder"
        @onMoved="onChangePositionFolder"
    >
        <template v-slot:panel>
            <slot name="panel" />
        </template>
		<template v-slot:summary>
            <slot name="summary" />
        </template>
        <template v-slot:content>
            <div class="folder__content">
                <slot name="content" />
            </div>
        </template>
	</BaseDraggbleWindow>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import BaseDraggbleWindow from '../Base/BaseDraggbleWindow.vue';

export default {
	name: "Folder",
	components: {
		BaseDraggbleWindow
	},
	props: {
		id: {
			type: [String, Number],
			required: true
		},
		title: {
			type: String,
			default: "Folder"
		},
		width: {
			type: String,
			default: '50%'
		},
		height: {
			type: String,
			default: '300px'
		},
		top: {
			type: String,
			default: '145px'
		},
		left: {
			type: String,
			default: '10px'
		},
        isMinimize: {
            type: Boolean,
            default: true
        },
        isRename: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        },
        isMobile: {
            type: Boolean,
            default: false
        }
	},
    /**
     * Данные компонента
     * @property {Array} folderParams - параметры всех папок
     * @property {Object} folderParam - параметры папки
     */
    data: () => ({
        folderParams: [],
        folderParam: {},
    }),

    created() {
        // const params = localStorage.getItem('folderParams') ?? [];

        // if (params.length > 0) {
        //     this.folderParams = JSON.parse(params);
        //     this.folderParam = this.folderParams.find((folder) => String(folder.id) === String(this.id)) ?? {};
        // }
    },

	computed: {
		...mapGetters('folders', [
            'folders',
            'expandedFolders',
            'folderQueue',
        ]),

        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },

		getRootStyles() {
            const currentFolder = this.folders.find((folder) => String(folder.id) === String(this.id)) ?? {};
            const { resize = '' } = currentFolder;
            const { position, size = [] } = this.folderParam;
            const [ widthSize = '400px', heightSize = '300px' ] = size;
            let width = widthSize === '400px' ? this.width : widthSize;
            let height = heightSize === '300px' ? this.height : heightSize;
            let left = !Array.isArray(position) ? this.left : position[0];
            let top = !Array.isArray(position) ? this.top : position[1];
            
            if (resize === 'full') {
                width = '95.1vw';
                height = this.isShare ? 'calc(100vh - 107px)' : 'calc(100vh - 195px)';
                top = this.isShare ? '46px' : '134px';
                left = '0';

                this.changeAllFolderParams(width, height, left, top);
            }

            if (resize === 'fifty') {
                width = '50vw';
                height = this.isShare ? 'calc(100vh - 107px)' : 'calc(100vh - 195px)';
                top = this.isShare ? '46px' : '134px';
                left = this.isMobile ? '0' : '47.4%';

                this.changeAllFolderParams(width, height, left, top);
            }

			return {
				width,
				height,
				top,
				left,
				zIndex: 3 + this.folderQueue.indexOf(this.id),
			}
		},

		/**
		 * @returns {Boolean} является ли папка открытой
		 */
		isOpenFolder() {
			return this.expandedFolders.findIndex(item => item.id === this.id) != -1
		},
	},
	methods: {
		...mapMutations('folders', [
			'closeFolder',
			'bringToFront',
			'collapseFolder',
			// 'setFolderData'
		]),

        /**
         * Событие переименования папки
         * @param {String} newName - новое имя
         */
        onRenameFolder(newName = '') {
            this.$emit('onRenameFolder', this.id, newName);
        },

        /**
         * Событие перемещения папки
         * @param {String} left
         * @param {String} top
         */
        onChangePositionFolder(left, top) {
            this.folderParam = {...this.folderParam, id: this.id, position: [left, top] };
            this.saveFolderParams(this.folderParam);
        },

        /**
         * Событие ресайза папки
         * @param {String} width
         * @param {String} height
         */
        onChangeSizeFolder(width, height) {
            const { size = [] } = this.folderParam ?? {};
            const [w , t] = size;

            if (t === '0' || w === '0') { return }

            this.folderParam = {...this.folderParam, id: this.id, size: [width, height] };
            this.saveFolderParams(this.folderParam);
        },

        /**
         * Событие ресайза папки половина экрана/полный экран
         * @param {String} width
         * @param {String} height
         * @param {String} left
         * @param {String} top
         */
        changeAllFolderParams(width, height, left, top) {
            this.folderParam = { id: this.id, position: [left, top], size: [width, height] };
            this.saveFolderParams(this.folderParam);
        },

        /**
         * Событие сохранения координат в LocalStorage
         * @param {Object} currentFolder - параметры папки
         */
        // eslint-disable-next-line no-unused-vars
        saveFolderParams(currentFolder = {}) {
            // const params = localStorage.getItem('folderParams') ?? [];

            // if (params.length > 0) {
            //     this.folderParams = JSON.parse(params);
            // }

            // let dublicateFolderIndex = this.folderParams.findIndex((folder) => String(folder.id) === String(this.id));
            // if (dublicateFolderIndex !== -1) {
            //     this.folderParams.splice(dublicateFolderIndex, 1, currentFolder);
            // } else {
            //     this.folderParams.push(currentFolder);
            // }

            // localStorage.setItem('folderParams', JSON.stringify(this.folderParams));
        },
	}
}
</script>

<style lang="scss" scoped>
.folder {
	box-shadow: 9px 19px 40px #0000001C;
	border-radius: 10px;
	z-index: 1000;

	&__content {
		height: 100%;
		width: 100%;
		overflow: hidden;
        background: #fff;
	}
}
</style>