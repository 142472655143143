<template>
    <div class="side-panel__tabs">
        <div
            v-for="item in tabs"
            :key="item.id"
            class="side-panel__tabs--item"
            @click="onSwitchActiveTab(item.id)"
        >
            <BaseButtonIcon
                :isActive="item.id === activeTabId"
                :tooltip-text="item.title"
                :icon-component="item.icon"
                tooltipPlacement="top"
                class="side-panel__tabs--btn"
            />
        </div>
    </div>
</template>

<script>
import BaseButtonIcon from "../../components/Base/BaseButtonIcon.vue";

export default {
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        activeTabId: {
            type: String,
            default: "",
        },
        isTransition: {
            type: Boolean,
            default: false,
        },
        isHoverReady: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BaseButtonIcon,
    },
    data() {
        return {};
    },
    methods: {
        onSwitchActiveTab(tabId) {
            this.$emit("onSwitchActiveTab", tabId);
        },
    },
};
</script>

<style lang="scss">
.side-panel__tabs {
    position: absolute;
    top: 0;
    left: 10px;
    display: flex;
    flex-direction: column;
    list-style: none;
    border: 1px solid #dddfe4;
    border-radius: 10px;
    padding: 10px 4px;

    &--item {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 0;
        margin-bottom: 10px;
        border: 1px solid transparent;
        border-radius: 4px;

        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// .side-panel.isActive .side-panel__tabs {
// }
</style>
