<template>
    <div
        class="summary-item-group"
        :class="isSelected ? 'summary-item-group__selected' : ''"
    >
        <div class="summary-item-group__head">
            <div class="summary-item-group__head-title">{{name}}</div>
            <div
                v-if="value !== null"
                class="summary-item-group__head-value"
            >
                {{preparePrice(value)}}
            </div>
        </div>
        <div
            v-if="sums.length"
            class="summary-item-group__body"
        >
            <div
                v-for="(sum, index) in sums"
                :key="index"
                class="summary-item-group__sum">
                <div class="summary-item__sum-name">{{sum.name}}</div>
                <div class="summary-item__sum-value">{{preparePrice(sum.value)}}</div>

                <div
                    v-if="isCount(sum)"
                    class="summary-item__sum-count"
                >
                    {{prepareCount(sum.count)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UtilNumbers from "../../utils/UtilNumbers";
    export default {
        name: "SummaryItemGroup",
        /**
         *
         *
         * @return {Object} group
         */
        props: {
            group: {
                type: Object,
                default: () => {},
            }
        },
        computed:{
            /**
             * @return {Number} value значение группы
             */
            value(){
                const {value = null} = this.group;
                return value;
            },
            /**
             * @returns {String} name название группы
             */
            name(){
                const {name = ''} = this.group;
                return name;
            },
            /**
             * @return {Array<Object>} sums массив с детализацией сумм
             */
            sums(){
                const {sums = []} = this.group;
                return sums;
            },

            isSelected() {
                const {isSelected = false} = this.group;
                return isSelected
            }
        },
        methods:{
            /**
             * Определение наличия суммы
             * @param {Object} sum
             * @return {boolean}
             */
            isCount(sum){
                return Object.keys(sum).includes("count");
            },
            preparePrice(value){
                return UtilNumbers.toPrice(value);
            },
            prepareCount(value){
                return UtilNumbers.toCount(value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-item-group{
        box-sizing: border-box;
        margin-top: 8px;
        background: #FFFFFF;
        /* gray light */
        border: 1px solid #F4F5F5;
        border-radius: 4px;

        &__head{
            display: flex;
            padding: 8px;
        }

        &__body{
            background: #F4F5F5;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 0 0 4px 4px;
            padding: 8px 8px 4px 8px;
        }

        &__head-title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
        }

        &__head-value{
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            /* identical to box height, or 120% */
            text-align: right;
            /* black */
            color: #000000;

            margin-left: auto;
        }

        &__sum{
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            /* identical to box height, or 120% */
            /* gray */
            color: #AAB0B8;
        }

        &__selected {
            border: 1px solid #6ec87a;
        }

        // &__sum-count{

        // }

        // &__sum-value{
        //     /*margin-left: auto;*/
        // }
    }
</style>
