<template>
    <base-modal
        :title="title"
        @close="onClose"
    >
        <template v-slot:modalBody>
            <div class="new-competitor">
                <div class="new-competitor__content">
                    <div class="new-competitor__select-wrap">
                        <BasePreloaderSkeleton
                            v-if="isLoadingNewCompetitors"
                        />
<!--                        <div class="new-competitor__label">-->
<!--                            Выберите конкурента-->
<!--                        </div>-->
                        <BaseSelect
                            class="new-competitor__select"
                            view="primary"
                            position="left"
                            style="width: 100%"
                            :defaultTitle="'Выберите тип конкурента'"
                            :value="selectedType"
                            :canSearch="false"
                            :is-virtual-scroll="true"
                            :isCloseAfterCheck="true"
                            :options="competitorTypes"
                            @check="onCheckCompetitorType"
                        />

                        <BaseSelect
                            v-if="selectedType"
                            class="new-competitor__select"
                            view="primary"
                            position="left"
                            style="width: 100%"
                            :value="selectedCompetitor"
                            :canSearch="true"
                            :is-virtual-scroll="true"
                            :isCloseAfterCheck="true"
                            :options="newCompetitorsList"
                            @check="onCheckCompetitor"
                        />
                    </div>
                </div>
                <div class="new-competitor__actions-wrap">
                    <BaseButton
                        class="new-competitor__action-btn"
                        view="border"
                        @click="$emit('onClosePopup')"
                    >Отменить</BaseButton>
                    <BaseButton
                        class="new-competitor__action-btn"
                        view="secondary"
                        @click="onAddCompetitor"
                    >Добавить</BaseButton>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "../../../Base/BaseModal";
    import BaseButton from "../../../Base/BaseButton";
    import BaseSelect from "../../../Base/BaseSelect";
    import BasePreloaderSkeleton from "../../../Base/BasePreloaderSkeleton";
    export default {
        name: "CompetitorSelectForm",
        components:{
            BaseModal,
            BaseButton,
            BaseSelect,
            BasePreloaderSkeleton
        },
        data: () => ({
            selectedCompetitor: [],
            selectedType: ''
        }),
        /**
         * Входные данные
         */
        props: {
            newCompetitorsList: {
                type: Array,
                default: () => ([])
            },
            competitorTypes: {
                type: Array,
                default: () => ([])
            },
            title: {
                type: String,
                default: ''
            },
            isLoadingNewCompetitors: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onCheckCompetitor(value){
                this.selectedCompetitor = value.id;
            },
            onClose(){
                this.$emit('onCloseModal');
            },
            onAddCompetitor(){
                this.$emit('onAddCompetitor', this.selectedCompetitor, this.selectedType);
            },
            onCheckCompetitorType(value){
                this.selectedType = value.id;
                this.$emit('onCheckCompetitorType', this.selectedType);
            }
        }
    }
</script>

<style lang="scss" scoped>
    $color-gray-light: #f2f2f2;
    $color-gray: #AFB0B4;

    .new-competitor{
        position: relative;

        &__content{
            margin-top: 20px;
        }

        &__select-wrap {
            margin-bottom: 10px;
        }

        &__label {
            font-size: 12px;

            margin-bottom: 5px;
        }

        &__buttons-wrap{
            width: 100%;
            display: flex;
            padding-bottom: 30px;
            justify-content: flex-start;
        }

        &__button-item {
            color: $color-gray;
            font-size: 12px;
            cursor: pointer;
            margin-right: 20px;
            transition: all .3s ease-out;

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: #000;
            }
        }

        &__actions-wrap {
            width: 100%;
            display: flex;
            justify-content: right;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

</style>
