<template>
        <div class="template">
            <div class="template__content">
                <div class="template__select-wrap">
                    <div class="template__label">
                        Выберите шаблон для скачивания
                    </div>
                    <BaseSelect
                        class="template__select"
                        view="primary"
                        position="left"
                        style="width: 100%"
                        :options="selectValues"
                        :value="selectedTemplate"
                        :canSearch="false"
                        :isCloseAfterCheck="true"
                        :defaultTitle="defaultTitle"
                        @check="onCheckTemplate"
                    >
                    </BaseSelect>
                </div>
            </div>
            <div class="template__buttons-wrap">
                <div
                    class="template__button-item"
                    v-if="selectedTemplate.length"
                    @click="onCreateTemplate"
                >
                    Создать новый
                </div>
                <div
                    class="template__button-item"
                    v-if="selectedTemplate[0]"
                    @click="onEditTemplate"
                >
                    Изменить
                </div>
                <div
                    class="template__button-item"
                    v-if="selectedTemplate[0]"
                    @click="onRemoveTemplate"
                >
                    Удалить
                </div>
            </div>
            <div class="template__actions-wrap">
                <BaseButton
                    class="template__action-btn"
                    view="border"
                    @click="$emit('onClosePopup')"
                >Отменить</BaseButton>
                <BaseButton
                    v-if="selectedTemplate.length"
                    class="template__action-btn"
                    view="secondary"
                    @click="onExportTemplate"
                >Скачать</BaseButton>
            </div>
        </div>
</template>

<script>

import BaseSelect from "@/components/Base/BaseSelect";
import BaseButton from "@/components/Base/BaseButton";

export default {
    name: 'ExportTemplateBase',
    components: {
        BaseSelect,
        BaseButton,
    },
    /**
     * Входные данные
     * @property {Array} selectValues - данные для селекта
     * @property {Array} selectedTemplate - выбранный шаблон
     */
    props: {
        selectValues: {
            type: Array,
            default: () => ([])
        },
        selectedTemplate: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        defaultTitle(){
            if(!this.selectedTemplate.length){
                return "Выберите шаблон";
            }
            let selected = this.selectValues.find(item => item.id === this.selectedTemplate[0]);
            return selected.value;
        }
    },
    methods:{
        onEditTemplate(){
            this.$emit('onEditTemplate')
        },
        onRemoveTemplate(){
            if(confirm('Уверены что хотите удалить шаблон?')){
                this.$emit('onRemoveTemplate')
            }
        },
        onCreateTemplate(){
            this.$emit('onCreateTemplate')
        },
        onCheckTemplate(value){
            this.$emit('onCheckTemplate', value.id)
        },
        onExportTemplate(){
            this.$emit('onExportTemplate')
        }
    }
}
</script>


<style lang="scss">
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;

.template .template__select .select-preview {
    background-color: $color-gray-light;
    color: $color-gray;

    &:active, &:focus {
        background-color: #fff;
        color: #000;
    }
}
</style>

<style lang="scss" scoped>
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;

.template{
    position: relative;

    &__content{
        margin-top: 20px;
    }

    &__select-wrap {
        margin-bottom: 10px;
    }

    &__label {
        font-size: 12px;

        margin-bottom: 5px;
    }

    &__buttons-wrap{
        width: 100%;
        display: flex;
        padding-bottom: 30px;
        justify-content: flex-start;
    }

    &__button-item {
        color: $color-gray;
        font-size: 12px;
        cursor: pointer;
        margin-right: 20px;
        transition: all .3s ease-out;

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: #000;
        }
    }

    &__actions-wrap {
        width: 100%;
        display: flex;
        justify-content: right;
    }

    &__action-btn {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

</style>
