import ServiceApi from '../ServiceApi';
import ServiceSelector from "../ServicesSelector/ServiceSelector";
/** Сервис для различных действий с карты */
export default class ServiceMapActions {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} changeUserStatus - маршрут для изменения пользовательского статуса
     */
    static _requestRouts = {
        changeUserStatus: '/main/set-user-status'
    };

    /**
     * Получить существующие группы
     * @public
     * @static
     * @async
     * @param {String} userStatus - пользовательский статус
     * @param {Function} actionAfter - событие, срабатывающее после получения групп
     */
    static async setUserStatus(userStatus, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.changeUserStatus,
                this._getUserStatusBody(userStatus)
            );
            const {data: priceList = []} = res;
            actionAfter(priceList);
        } catch(error) {
            const {response = {}} = error;
            const {data = {}} = response;
            const {data: errorMessages = {}} = data;
            if(Object.values(errorMessages).length){
                alert(Object.values(errorMessages)[0]);
            }
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса обновления статуса пользователя
     * @private
     * @static
     * @param {String} userStatus - пользовательский статус
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.price - тело фильтра
     * @returns {Object} reqBody.price.ids - выбранные цены
     * @returns {Object} reqBody.price.user_status - статус
     */
    static _getUserStatusBody(userStatus) {
        const priceIds = ServiceSelector.getPriceSelected();
        const reqBody = {
            select: {
                user_status: userStatus,
                ids: priceIds
            }
        };
        return reqBody;
    }
}
