<template>
    <div
        :id="id"
        :data-column-id="columnId"
        class="draggable-card"
        :class="{'preview-card': isPreview}"
        :draggable="draggable"
        @dragstart="dragStart"
        @dragend="dragEnd"
        @dragover.prevent.stop="dragOver"
    >
        <BasePreloaderSkeleton 
            v-if="isLoading" 
        />
        <slot></slot>
    </div>
</template>

<script>
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
export default {
    name: "DraggableCard",
    components: {BasePreloaderSkeleton},
    props: {
        id: {
            type: String,
            required: true,
        },
        columnId: {
            type: Number,
            required: true,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        hoverItemId: '',
    }),

    methods: {
        dragStart(evt) {
            const target = evt.target;
            if (evt.target.classList?.contains('draggable-card')) {
                evt.dataTransfer.setData('card_id', target.id);
                evt.dataTransfer.setData('column_id', target.dataset.columnId);

                // Изменить стили текущего перетаскиваемого элемента после того как событие сработает
                setTimeout(() => {
                    target.style.transition = 'all .3s ease-in';
                    target.style.height = target.scrollHeight + "px";
                    target.style.transform = 'scale(1.5)';

                }, 0);

                setTimeout(() => {
                    target.style.height = '0';
                }, 100);

                setTimeout(() => {
                    target.style.display = 'none';
                }, 300);

            } else  {
                // Отмена перетаскивания, если элемент не DraggableCard
                evt.preventDefault();
                evt.stopPropagation();
            }

        },

        dragEnd(evt) {
            evt.target.style.display = 'block';
            evt.target.style.height = "";
            evt.target.style.transform = 'scale(1)';

            this.$emit('dragEnd');
        },

        dragOver(evt) {
            const hoverCard = evt.currentTarget;
            const columnId = hoverCard.dataset.columnId;
            const hoverCardId = hoverCard.id.slice(15);

            if (hoverCard.classList.contains('draggable-card')) {
                this.$emit('dragOver', Number(hoverCardId), Number(columnId));
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$color-green: #6ec87a;
$color-gray-400: #C8CAD0;

.draggable-card {
    position: relative;
    padding: 0;
    margin-bottom: 25px;
    transform: scale(1);
    transition: all .3s ease-in;
}

.preview-card {
    display: block;
    height: 0;
    transition: height .250s ease-in;

    border-radius: 10px;
    border: 4px dotted $color-gray-400;
    background-color: #f9f9f9;
}

.dropped-card {
    transform: scale(1.3);
}
</style>
