<template>
    <a
        :href="offerAddRoute"
        :class="{ row: direction === 'row', column: direction === 'column' }"
        target="_blank"
    >
        <div class="kit-panel-offer-add">
            <div class="kit-panel-offer-add__title">
                Загрузить <br />
                предложение
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: "KitPanelOfferAdd",
    props: {
        direction: {
            type: String,
            default: "row",
        },
    },
    data: () => ({
        offerAddRoute: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/import`
    })
};
</script>

<style lang="scss" scoped>
.kit-panel-offer-add {
    min-width: 199px;
    width: 100%;
    height: 80px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #aab0b8;
        text-align: center;
        padding: 5px;
    }
}

.row {
    margin-right: 10px;
}

</style>
