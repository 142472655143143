<template>
    <div class="panel">
        <!-- <div
            @click="onSend"
            class="panel__item"
        >Создать предложение</div> -->
        <div class="panel__group-item">
            <div
                class="panel__item panel__item--text"
                v-if="isExport && !isFound && !isShare"
                @click="onMoveToBudget"
            >
                Редактирование цен
            </div>
            <div class="panel__item panel__item--text" v-if="isFound" @click.stop="onAddAll">
                Добавить все
            </div>
        </div>
        <div class="panel__group-item" v-if="isExport && !isFound">
            <div
                v-tooltip="'Скачать'"
                @click.stop="onExportExcel"
                class="panel__item panel__item--icon"
            >
                <IconDownload class="icon" :width="20" :height="20" />
            </div>
            <div
                v-tooltip="'Поделиться'"
                @click.stop="onShare"
                class="panel__item panel__item--icon"
            >
                <iconShare class="icon" :width="20" :height="20" />
            </div>
        </div>
        <!-- <div class="panel__group-item" v-if="!isFound && !isShare && isRemove">
            <div
                v-tooltip="'Удалить'"
                @click.stop="onRemove"
                class="panel__item panel__item--icon"
            >
                <IconDelete class="icon" :width="20" :height="20" />
            </div>
        </div> -->
        <div class="panel__group-item">
            <div
                v-if="isFull"
                v-tooltip="'Расширить на всю ширину'"
                class="panel__item panel__item--icon"
                @click.stop="onFullSize"
            >
                <IconFull class="icon" :width="20" :height="20" />
            </div>
            <div
                v-if="isFifty"
                v-tooltip="'Расширить на половину экрана'"
                class="panel__item panel__item--icon"
                @click.stop="onFiftySize"
            >
                <IconFifty class="icon" :width="20" :height="20" />
            </div>
            <div
                v-if="isColumnsSettings"
                v-tooltip="'Настройки столбцов'"
                class="panel__item panel__item--icon"
                @click.stop="onColumnsSettings"
            >
                <IconSettings class="icon" :width="20" :height="20" />
            </div>
            <div
                v-tooltip="'Закрыть'"
                class="panel__item panel__item--icon"
                @click.stop="onCloseFolder"
            >
                <IconClose class="icon" :width="20" :height="20" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import IconFull from "@/components/Icons/IconFull";
import IconFifty from "@/components/Icons/IconFifty";
import IconDownload from "@/components/Icons/IconDownload";
import IconShare from "@/components/Icons/IconShare";
// import IconDelete from "@/components/Icons/IconDelete";
import IconClose from "@/components/Icons/IconClose";
import IconSettings from '../Icons/IconSettings.vue';

export default {
    name: "FolderControlPanel",
    components: {
        IconFull,
        IconFifty,
        IconDownload,
        IconShare,
        // IconDelete,
        IconClose,
        IconSettings
    },
    props: {
        isExport: {
            type: Boolean,
            default: false
        },
        isFound: {
            type: Boolean,
            default: false
        },
        isRemove: {
            type: Boolean,
            default: true
        },
        isFull: {
            type: Boolean,
            default: true
        },
        isFifty: {
            type: Boolean,
            default: true
        },
        isColumnsSettings: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({ isShare: state => state.auth.isShare })
    },
    methods: {
        onSend() {
            this.$emit("send");
        },
        onExportExcel() {
            this.$emit("exportExcel");
        },
        onRemove() {
            if (confirm("Вы уверены?")) this.$emit("remove");
        },
        onAddAll() {
            this.$emit("addAll");
        },
        onMoveToBudget() {
            this.$emit("moveToBudget");
        },
        onShare() {
            this.$emit("onShare");
        },
        onFullSize() {
            this.$emit("onFullSize");
        },
        onFiftySize() {
            this.$emit("onFiftySize");
        },
        onCloseFolder() {
            this.$emit("onCloseFolder");
        },
        onColumnsSettings() {
            this.$emit('onColumnsSettings');
        }
    }
};
</script>

<style lang="scss" scoped>
$gray-text: #b9bbc2;
$border-color: #EAECED;
$background-hover: #f2f2f2;
.panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__group-item {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 15px;
        border-left: 1px solid $border-color;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 36px;
        height: 36px;

        border-radius: 4px;
        cursor: pointer;
        transition: all .4s ease-out;

        &--text {
            width: auto;
            height: auto;
            color: $gray-text;
            font-size: 12px;
            white-space: nowrap;
        }

        &--icon {
            &:hover {
                background-color: $background-hover;
            }
        }

        &:hover {
            color: #000;
        }
    }

    // .icon {
    //     transition: all .4s ease-out;
    //     ::v-deep path {
    //         fill: $gray-text;
    //     }

    //     &:hover {
    //        ::v-deep path {
    //             fill: #000;
    //         }
    //     }
    // }
}
</style>