export default class UtilValidation {
    static _numberPositiveRules = {
        maxLength: 255,
        regexp: new RegExp(/(^0$)|(^0\.\d+$)|(^[1-9]\d*$)|(^[1-9]\d*\.\d+$)/),
        errorMessages: {
            isEmpty: 'Поле не может быть пустым',
            isLength: 'Недопустимая длинна поля',
            isRegexp: 'Поле должно целым неотрицательным числом'
        },
        formatting: (value = '') => {
            return String(value).replace(',', '.');
        },
    }

    static _stringRules = {
        maxLength: 255,
        regexp: new RegExp(),
        errorMessages: {
            isEmpty: 'Поле не может быть пустым',
            isLength: 'Недопустимая длинна поля',
            isRegexp: ''
        },
        formatting: (value = '') => {
            return String(value);
        },
    }

    static _validation(rules = {}, value = '') {
        const {
            maxLength,
            regexp,
            errorMessages,
            formatting = () => {}, 
        } = rules;

        const valueFormatted = formatting(value);
        const valueLength = String(valueFormatted).length;
        const validationValue = {
            isEmpty: valueLength > 0,
            isLength: valueLength <= maxLength,
            isRegexp: regexp.test(String(valueFormatted))
        };
        const errorKey = Object.keys(validationValue).find(validationValueKey => !validationValue[validationValueKey]) ?? '';
        const errorMessage = errorMessages[errorKey] ?? '';

        const validationRes = {
            isEmpty: validationValue.isEmpty,
            isLength: validationValue.isLength,
            isRegexp: validationValue.isRegexp,
            isValid: validationValue.isLength && validationValue.isRegexp && validationValue.isEmpty,
            valueFormatted,
            errorMessage
        };
        return validationRes;
    }

    static validationPositiveNumber(value = '') {
        return this._validation(this._numberPositiveRules, value);
    }

    static validationString(value = '') {
        return this._validation(this._stringRules, value);
    }
}