<template>
	<div id="app">
		<component :is="layout">
			<RouterView />
		</component>
	</div>
</template>

<script>
import DefaultLayout from "./layouts/Default"
import WithFilterLayout from "./layouts/WithFilter"
import LayoutLoginV2 from "./layouts/LayoutLoginV2"
import LayoutUserProfile from "./layouts/LayoutUserProfile"
import {mapMutations} from 'vuex';

export default {
	name: 'App',
	components: {
		DefaultLayout,
		WithFilterLayout,
		LayoutLoginV2,
        LayoutUserProfile
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || 'DefaultLayout')
		}
	},
	created() {
        document.title = this.$route.meta.title || 'oohdesk'

		window.addEventListener("resize", this.onResizeWindow);
        this.onResizeWindow();
    },
	destroyed() {
        window.removeEventListener("resize", this.onResizeWindow);
    },
	methods: {
		...mapMutations(['setIsMobile']),

		onResizeWindow() {
            this.setIsMobile(window.innerWidth < 1024);
        },
	}
}
</script>
