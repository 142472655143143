<template>
    <div 
        class="mobile-dropdown-item"
        @click="onClickItem"
    >
        {{actionValue}}
    </div>
</template>

<script>
export default {
    name: 'MobileDropdownItem',
    props: {
        action: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        actionId() {
            return String(this.getActionProp('id') ?? '');
        },
        actionValue() {
            return String(this.getActionProp('value') ?? '');
        },
        actionIsMulti() {
            return Boolean(this.getActionProp('isMulti') ?? false);
        },
        actionIsCanShare() {
            return Boolean(this.getActionProp('isCanShare') ?? false);
        },
        actionEmitName() {
            return String(this.getActionProp('emitName') ?? '');
        },
        actionSubItems() {
            return this.getActionProp('items') ?? [];
        }
    },
    methods: {
        getActionProp(prop = '') {
            return this.action[prop] ?? null;
        },
        onClickItem() {
            if (!this.actionIsMulti)
                this.$emit('onClickAction', this.action, this.actionEmitName);
            else
                this.$emit('onClickActionMulti', this.action);
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobile-dropdown-item {
        display: flex;
        align-items: center;
        height: 54px;
        border-top: 1px solid #F4F5F5;
        font-weight: 400;
        font-size: 18px;
        line-height: 14px;
        user-select: none;
        cursor: pointer;

        &:last-child {
            border-bottom: 1px solid #F4F5F5;
        }
    }
</style>