<template>
    <div class="tags">
        <div class="tags__create" @click="$emit('onClickCreate')">
            <IconPlus style="width: 15px" color="white" />
        </div>
        <div class="tags__item" v-for="tag in enteredTags" :key="tag" @click="$emit('onRemoveTag', tag)">
            {{ tag }}
            <IconClose style="width: 15px" />
        </div>

        <div class="dropdown" v-if="isOpenedDropdown">
            <div class="select-dropdown">
                <div class="select-dropdown__triangle" />

                <!-- HEADER -->
                <div class="select-dropdown__header">
                    <div class="select-dropdown__top">
                        <slot name="header" />
                    </div>
                </div>

                <!-- SEARCH -->
                <div class="select-dropdown__search">
                    <input
                        v-model="searchValue"
                        type="text"
                        placeholder="Поиск"
                        @input="onInput"
                    />
                </div>

                <!-- OPTIONS -->
                <div
                    ref="selectOptionsContainer"
                    class="select-options"
                    @scroll="onScroll"
                >
                    <div ref="spacer" :style="getSpacerStyle()">
                        <div v-if="!virtualOptions.length">
                            <div
                                v-if="searchValue.length"
                                class="select-options__item"
                                @click="onCheck(searchValue)"
                            >
                                {{ searchValue }} (новый)
                            </div>
                            <div v-else class="select-options__item">
                                Введите название нового тега
                            </div>
                        </div>
                        <div v-else>
                            <div
                                v-for="(option, index) of virtualOptions"
                                :key="index"
                                class="select-options__item"
                                @click="onCheck(option)"
                            >
                                <span>{{ option }}</span>
                            </div>
                            <div style="padding: 4px 10px; font-size: 10px; opacity: .5;">
                                Или введите название нового тега
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconPlus from "@/components/Icons/IconPlus.vue";
import IconClose from "@/components/Icons/IconClose.vue";

export default {
    components: {
        IconPlus,
        IconClose,
    },
    props: {
        /**
         * @property {Array} options - массив опций
         * @property {Array} value - выбранное значение по умолчанию
         */
        options: {
            type: Array,
            default: () => [],
        },
        enteredTags: {
            type: Array,
            default: () => [],
        },
        value: {
            type: [String, Number, Array],
            default: null,
        },
        defaultTitle: {
            type: [String, Number],
            default: "Выбрать значение",
        },
        isVirtualScroll: {
            type: Boolean,
            default: false,
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({}),
        },
        isLoadingDropdown: {
            type: Boolean,
            default: false,
        },
        isOpenedDropdown: {
            type: Boolean,
            default: false,
        },
        requestError: {
            type: String,
            default: "",
        },
    },
    data() {
        /**
         * @property {Array} valueBuffer - буфер для мультивыбора
         */
        return {
            searchValue: "",
            scrollTop: 0,
            nodeCountVisible: 0,
            spacerHeight: 0,
            valueBuffer: "",
        };
    },
    mounted() {
        this.$emit('mounted')
    },
    computed: {
        /**
         * Вернет title для селекта
         */
        title() {
            const currentOption =
                this.options.find((i) => i === this.value) || {};
            return currentOption || this.defaultTitle;
        },
        /**
         * Массив значений по ключу поиска
         */
        filteredOptions() {
            const filteredOptions = this.options.filter((item) => {
                const optionValue = String(item.value).toLowerCase();
                const searchValue = String(this.searchValue).toLowerCase();

                return optionValue.startsWith(searchValue);
            });
            return filteredOptions;
        },
        virtualOptions() {
            return this.isVirtualScroll
                ? this.filteredOptions.slice(
                      this.nodeIndexStart,
                      this.nodeIndexEnd + 1
                  )
                : this.filteredOptions;
        },
        nodeHeight() {
            const { nodeHeight = 35 } = this.virtualScrollConfig;
            return nodeHeight;
        },
        nodeIndexStart() {
            const nodeIndexStart =
                Math.floor(this.scrollTop / this.nodeHeight) - 5;
            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const nodeIndexEnd = this.nodeIndexStart + 12;
            return Math.min(nodeIndexEnd, this.filteredOptions.length - 1);
        },
        containerHeight() {
            return this.filteredOptions.length * this.nodeHeight;
        },
        spacerTopStyle() {
            const spacerTopStyle = `${this.nodeIndexStart * this.nodeHeight}px`;
            return spacerTopStyle;
        },
        spacerBottomStyle() {
            const spacerBottomStyle = `${
                (this.filteredOptions.length - 1 - this.nodeIndexEnd) *
                this.nodeHeight
            }px`;
            return spacerBottomStyle;
        },
        localOpen() {
            return this.isLoadingDropdown;
        },
    },
    watch: {
        localOpen(isLocalOpen) {
            if (this.isVirtualScroll) {
                if (isLocalOpen)
                    this.$nextTick(() => {
                        this.getNodeCountVisible();
                        this.getSpacerHeight();
                    });
                else this.scrollTop = 0;
            }
        },
        filteredOptions() {
            if (this.localOpen && this.isVirtualScroll) {
                this.$refs.selectOptionsContainer.scrollTop = 0;
                this.scrollTop = 0;
            }
        },
        value() {
            this.valueBuffer = this.value;
        },
    },
    created() {
        // Сработает при эмите события cancel в родителе
        this.$parent.$on("$closeSelect", this.onClose);
    },
    methods: {
        onInput() {
            this.$emit("input", this.searchValue);
        },
        onScroll(event) {
            if (this.isVirtualScroll) {
                const { currentTarget = {} } = event;
                const { scrollTop = 0 } = currentTarget;
                this.scrollTop = scrollTop;
            }
        },
        getNodeCountVisible() {
            const { selectOptionsContainer = {} } = this.$refs;
            const bounding =
                selectOptionsContainer.getBoundingClientRect() || {};
            const { height = 0 } = bounding;
            const nodeCountVisible = Math.floor(height / this.nodeHeight);
            this.nodeCountVisible = nodeCountVisible;
        },
        getSpacerHeight() {
            this.spacerHeight = this.containerHeight;
        },
        getOptionStyle() {
            const optionStyle = {
                "white-space": "nowrap",
                height: `${this.nodeHeight}px`,
            };
            return this.isVirtualScroll ? optionStyle : {};
        },
        getSpacerStyle() {
            const spacerStyle = {
                "padding-top": this.spacerTopStyle,
                "padding-bottom": this.spacerBottomStyle,
            };
            return this.isVirtualScroll ? spacerStyle : {};
        },
        /**
         * @returns {Object} классы для опций
         */
        getOptionClasses(option) {
            return {
                "select-options__item_active": this.valueBuffer === option,
            };
        },
        /**
         * close
         */
        onClose() {
            this.$emit("$closeDropdown");
            this.$emit("close");
        },
        /**
         * @param {Object} option - option по которому был совершен клик
         */
        onCheck(option) {
            this.valueBuffer = option;
            this.$emit("check", option);
            this.searchValue = ""
        },
    },
};
</script>

<style lang="scss" scoped>
.tags {
    display: flex;
    align-items: center;

    &__create {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 0px 15px;
        border-radius: 6px;
        background: #4a91f1;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
            background: hsla(214, 86%, 62%, 0.8);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        height: 30px;
        margin-left: 10px;
        border-radius: 6px;
        background: #f2f2f2;
        line-height: 15px;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
            background: hsla(0, 0%, 95%, 0.5);
        }

        & svg {
            margin-left: 5px;
        }
    }
}
</style>

<style lang="scss" scoped>
$primary-color: #f0f3f8 !default;
$success-color: #6ec87a !default;
$primary-disabled-color: #dce1e8 !default;

.select .dropdown {
    padding-bottom: 10px !important;
}

.multiple-actions {
    display: flex;
    justify-content: space-between;

    &__btn {
        padding: 5px;
        background: none;
        color: #8d8d8d;
    }
}

.select {
    display: inline-block;
    position: relative;
    width: 130px;
    user-select: none;
    outline: none;
    font-size: 13px;

    &--full-width {
        width: 100%;
    }

    &-preview {
        padding: 6px 15px;
        // border: 1px solid transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.2s;
        text-align: left;
        &__circle {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background: #6ec87a;
            margin-right: 5px;
            &-icon {
                padding-left: 2px;
            }
        }

        &__icon {
            font-size: 16px;
            color: #000;
            margin-right: 10px;

            &-component {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }

        &__label {
            flex-grow: 1;

            font-size: 12px;
            font-weight: 400;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__arrow {
            font-size: 8px;

            margin-left: 5px;
        }
    }

    &-dropdown {
        height: 100%;
        max-height: 250px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 5px;

        &__search {
            width: 100%;
            cursor: pointer;
            border-radius: inherit;
            transition: 0.1s;

            input {
                border: none;
                border-bottom: 1px solid $primary-disabled-color;
                background: transparent;
                height: 34px;
                width: 100%;
                display: block;
                outline: none;
                padding-left: 16px;

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath id='iconmonstr-magnifier-2' d='M10,9.019,7.394,6.434a4.012,4.012,0,0,0,.78-2.38A4.087,4.087,0,0,0,0,4.054,4.092,4.092,0,0,0,6.391,7.4L9.011,10,10,9.019ZM1.2,4.054A2.888,2.888,0,1,1,4.087,6.92,2.88,2.88,0,0,1,1.2,4.054Z' fill='%23b9bbc2'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 10px;
                font-size: 12px;

                &::placeholder {
                    color: $primary-disabled-color !important;
                }
            }
        }

        &__header {
            flex-shrink: 0;
            flex-grow: 0;
        }
        &__top {
            margin-bottom: 4px;
        }
        &__footer {
            flex-shrink: 0;
            flex-grow: 0;
            margin-top: 10px;
        }

        &__btn-container {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-options {
        flex-grow: 1;
        overflow: auto;
        margin-top: 0;

        &__item-count {
            color: #8d8d8d;
        }

        &__item {
            border: 1px solid transparent;
            max-width: 100%;
            cursor: pointer;
            // border-radius: 400px;
            border-radius: 4px;
            transition: 0.1s;
            // padding: 8px 15px;
            padding: 6px 10px;
            display: flex;
            justify-content: space-between;
            &_required {
                color: #f84967;
            }
            &:hover:not(.select-options__item_active) {
                background: $primary-color;
            }

            span {
                margin-right: 5px;
                // white-space: nowrap;
                display: block;
                text-overflow: ellipsis;
                // white-space: nowrap;
                overflow: hidden;
            }

            &_active {
                // border: 1px solid $success-color;
                color: $success-color;
            }
        }
    }
}

.dropdown {
    cursor: initial;
    position: absolute;
    background: #fff;
    white-space: nowrap;
    z-index: 10;
    box-shadow: 0 3px 15px #21242d17;
    // border-radius: 10px;
    max-width: calc(100% - 60px);

    border: 1px solid #f4f5f5;
    border-radius: 4px;
}
.dropdown-preloader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
</style>
