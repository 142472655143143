<template>
    <div class="kit-panel-action-list">
        <!-- <transition-group
            name="action-item"
            tag="div"
            class="kit-panel-action-list"
        > -->
        <div class="kit-panel-action-list-left">
            <KitPanelActionItem
                v-for="action in kitActionsFirst"
                :key="action.id"
                :kitAction="action"
                :kitActionIsActive="getKitActionIsActive(action.id)"
                :kitColor="kitColor"
                :kitIsActive="kitIsActive"
                :isLoading="isLoading"
                :kitIsShared="kitIsShared"
                ref="kitPanelActionItemsFirst"
                @onClickAction="onClickAction"
            />
        </div>
        <div
            :class="[
                'kit-panel-action-list-right',
                { isKitActionsSecond, isKitActionsTransition },
            ]"
        >
            <KitPanelActionItem
                v-for="action in kitActionsSecond"
                :key="action.id"
                :kitAction="action"
                :kitActionIsActive="getKitActionIsActive(action.id)"
                :kitColor="kitColor"
                :kitIsActive="kitIsActive"
                :isLoading="isLoading"
                :kitIsShared="kitIsShared"
                ref="kitPanelActionItemsMore"
                @onClickAction="onClickAction"
            />
        </div>
        <!-- <KitPanelActionItem
            :kitAction="kitActionMore"
            :kitActionIsActive="getKitActionIsActive(kitActionMore.id)"
            :kitColor="kitColor"
            :kitIsActive="kitIsActive"
            :isLoading="isLoading"
            :kitIsShared="kitIsShared"
            ref="kitPanelActionItemsMore"
            @onClickAction="onClickAction"
        /> -->
        <!-- <KitPanelActionItem
                v-for="(action) in kitActionsRender"
                :key="action.id"
                :kitAction="action"
                :kitActionIsActive="getKitActionIsActive(action.id)"
                :kitColor="kitColor"
                :kitIsActive="kitIsActive"
                :isLoading="isLoading"
                :kitIsShared="kitIsShared"
                ref="kitPanelActionItems"
                @onClickAction="onClickAction"
            /> -->
        <!-- </transition-group> -->
    </div>
</template>

<script>
/** Компонент списка действий набора */
import KitPanelActionItem from "./KitPanelActionItem.vue";
export default {
    name: "KitPanelActions",
    components: { KitPanelActionItem },
    /**
     * Входные данные компонента
     * @property {Array<Object>} kitActions - действия для наборов
     * @property {String} kitActions[].id - идентификатор действия для наборов
     * @property {String} kitActions[].icon - иконка действия для наборов
     * @property {String} kitActions[].emitName - название эмита действия для наборов
     * @property {Array<String>} kitActionsActive - список идентификаторов активных кнопок
     * @property {String} kitColor - цвет набора
     * @property {Boolean} kitIsActive - активен ли набора
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitActions: {
            type: Array,
            default: () => [],
        },
        kitActionsActive: {
            type: Array,
            default: () => [],
        },
        kitColor: {
            type: String,
            default: "",
        },
        kitIsActive: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        kitIsShared: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isKitActionsSecond: false,
            isKitActionsTransition: false,
        };
    },
    computed: {
        // kitActionsRender() {
        //     const kitActionMenuContext = this.kitActions.find(kitAction => Boolean(kitAction?.isMenuContext)) ?? {};
        //     const {id = '', menuContextItems = []} = kitActionMenuContext;
        //     const kitActionsFirst = this.kitActions.filter(kitAction => {
        //         const {isMenuContext = false} = kitAction;
        //         return !isMenuContext;
        //     });
        //     return this.kitActionsActive.includes(String(id)) ? [...kitActionsFirst, ...menuContextItems, kitActionMenuContext] : this.kitActions;
        // },
        kitActionsFirst() {
            const kitActionsFirst = this.kitActions.filter((kitAction) => {
                const { isMenuContext = false } = kitAction;
                return !isMenuContext;
            });
            return kitActionsFirst;
        },
        kitActionsSecond() {
            const kitActionMenuContext =
                this.kitActions.find((kitAction) =>
                    Boolean(kitAction?.isMenuContext)
                ) ?? {};
            const { menuContextItems = [] } = kitActionMenuContext;
            return [...menuContextItems];
        },
        kitActionMore() {
            const kitActionMenuContext =
                this.kitActions.find((kitAction) =>
                    Boolean(kitAction?.isMenuContext)
                ) ?? {};
            return kitActionMenuContext;
        },
    },
    methods: {
        /**
         * Получить флаг активности действия
         * @param {String} actionId - идентификатор действия
         * @returns {Boolean}
         */
        getKitActionIsActive(actionId = "") {
            return this.kitActionsActive.includes(String(actionId));
        },

        /**
         * Событие клика по кнопке действия набора
         * @param {String} kitActionEmitName - название эмита действия
         * @param {String} kitActionId - идентификатор действия
         */
        onClickAction(kitActionEmitName = "", kitActionId = "") {
            if (kitActionId === "more") {
                this.isKitActionsTransition = true;
                this.isKitActionsSecond = !this.isKitActionsSecond;

                setTimeout(() => (this.isKitActionsTransition = false), 400);
            }
            this.$emit("onClickAction", kitActionEmitName, kitActionId);
        },

        closeMenuContext() {
            this.$refs.kitPanelActionItems?.forEach((kitPanelActionItem) => {
                kitPanelActionItem.closeMenuContext();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kit-panel-action-list {
    position: relative;
    display: flex;
    justify-content: space-between;

    &-left {
        display: flex;
    }
    &-right {
        display: flex;
        position: absolute;
        bottom: 0;
        right: -60px;
        opacity: 0;
        transition: all 0.25s ease 0.1s;

        &.isKitActionsSecond {
            opacity: 1;
            right: 30px;
            display: flex;
        }

        &.isKitActionsTransition {
            pointer-events: none;
        }
    }
}
.action-item-enter-active,
.action-item-leave-active {
    transition: all 0.5s;
}
.action-item-enter,
.action-item-leave-to {
    opacity: 0;
    transform: translateX(-30px);
}
</style>
