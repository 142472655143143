<template>
    <RealtimeSystem
        :componentKey="componentKey"
        :onAnyEventsSocketScheme="onAnyEventsSocketScheme"
        @poiUpdate="poiUpdate"
    />
</template>

<script>
import RealtimeSystem from './RealtimeSystem.vue';
import ServicePoiRealtimeSystem from '../../services/ServicesPoi/ServicePoiRealtimeSystem';
export default {
    name: 'RealtimeSystemPoi',
    components: {RealtimeSystem},
    props: {
        query: {
            type: String,
            default: 'Магнит'
        }
    },
    data: () => ({
        componentKey: 'RealtimeSystemPoi',
        onAnyEventsSocketScheme: [
			{
				id: 'poiUpdate',
				eventNameSocket: 'poi_update',
				emitNameComponent: 'poiUpdate',
			},
		],
        isEmitPoiUpdate: false,
        poiItems: []
    }),
    watch: {
        query() {
            this.poiItems = [];
        }
    },
    methods: {
        poiUpdate(data) {
            console.log(data);
            const {isLastPage = false, query = '', poiItems = []} = ServicePoiRealtimeSystem.poiRealtimeSystemUpdateAfter(data);
            
            if (this.query === query) {
                this.poiItems.push(...poiItems);
                this.isEmitPoiUpdate = isLastPage;
    
                if (this.isEmitPoiUpdate) {
                    this.$emit('poiUpdate', this.poiItems);
                    this.poiItems = [];
                    this.isEmitPoiUpdate = false;
                }
            }
        }
    }
}
</script>