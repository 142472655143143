<template>
    <div
        class="site"
        :style="{'background-image' : 'url(' + mapLink + ')'}"
    >
        <h3 class="site__title" @mousedown="$emit('mouseDown', $event)">Расположение</h3>
        <div class="site__actions">
            <button 
                class="site__button" 
                style="display: flex"
                @click="onStreetView"
            >
                <span>Панорама</span>
            </button>
            <button v-tooltip="'Скопировать'" class="site__button" style="display: flex" @click="onCopy">
                <span class="site__cord">{{params.lat}}, {{params.lng}}</span>
                <!-- <span class="site__icon-copy icon-layers"></span> -->
                <IconCopyV2 class="site__icon-copy"/>
            </button>
        </div>
    </div>
</template>

<script>
import IconCopyV2 from '../Icons/IconCopyV2.vue';
export default {
    name: "Site",
    components: {IconCopyV2},
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        mapLink(){
            return "https://static-maps.yandex.ru/1.x/?ll=" + this.params.lng + "," + this.params.lat + "&pt=" + this.params.lng + "," + this.params.lat + ",org&size=500,450&z=16&l=map";
        },
    },
    methods: {
        onCopy() {
            this.$clipboard(`${this.params.lat}, ${this.params.lng}`);
        },
        onStreetView() {
            this.$emit('streetView', this.params);
        }
    }
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-border-table: #F2F2F2;
$color-gray-300: #AFB0B4;

.site {
    min-height: 160px;
    background-color: #fff;
    /*background-image: url("https://via.placeholder.com/200x200/F9F9E1");*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-radius: 10px;
    border: 1px solid $color-border;
    //padding: 20px;
    position: relative;

    &__title {
        position: absolute;
        top: 8px;
        left: 8px;

        display: block;
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        background-color: #fff;
        padding: 6px 12px;
        border-radius: 4px;
        cursor: move;
    }

    &__image {
        margin-bottom: 10px;
    }

    &__actions {
        position: absolute;
        bottom: 8px;
        left: 8px;
        display: flex;
    }

    &__button {
        display: flex;
        // position: absolute;
        // bottom: 8px;
        // right: 8px;

        display: block;
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        background-color: #fff;
        // padding: 6px 12px;
        padding: 6px 10px;
        border-radius: 4px;

        &:hover, &:active {
            background: #f9f9f9;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    &__cord {
        margin-right: 5px;
    }

    &__icon-copy {
        width: 14px;
        height: 14px;
        ::v-deep path {
            fill: $color-gray-300;
        }
    }
}
</style>
