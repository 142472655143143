import ServiceApi from "../ServiceApi";
import ServiceFilter from "../ServicesFilter/ServiceFilter";
import ServiceSelector from "../ServicesSelector/ServiceSelector";
/** Сервис для работы с сводными данными */
export default class ServiceEspar {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} esparGroup - маршрут для получения расчетов группы
     * @property {String} esparSelected - маршрут для получения расчетов выбранного
     */
    static _requestRouts = {
        esparGroup: '/admetrix/price-group-calc',
        esparSelected: '/admetrix/selected-calc'
    };

    /**
     * Подготовить тело запроса для эспар
     * @private
     * @static
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @param {Number} days - кол-во дней
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getEsparKitBefore(kitParams = {}, days = 30) {
        const {id: kitItemId = ''} = kitParams;
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: String(kitItemId)
            },
            admetrix:{days}
        };

        return reqBody;
    }

    /**
     * Подготовить тело запроса для получения расчетов выбранного
     * @private
     * @static
     * @param {Number} days - кол-во дней
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getEsparSelectedBefore(days = 30) {
        const priceIds = ServiceSelector.getPriceSelected();
        const reqBody = {
            admetrix: {
                days,
                selected: priceIds
            }
        };
        return reqBody;
    }

    /**
     * Получить данные фильтра
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Number} days - кол-во дней
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     * @param {Function} errorAfter - событие, сработающее после получения ошибки
     */
    static async postEspar(kitParams, days, actionAfter = () => {}, errorAfter = () => {}) {
        const {type} = kitParams;
        switch (type) {
            case "group":
                this.postEsparGroup(kitParams, days, actionAfter, errorAfter)
                break;
            case "selected":
                this.postEsparSelected(kitParams, days, actionAfter, errorAfter)
                break;
        }
    }

    /**
     * Получить espar данные группы
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Number} days - кол-во дней
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     * @param {Function} errorAfter - событие, сработающее после получения ошибки
     */
    static async postEsparGroup(kitParams, days, actionAfter = () => {}, errorAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.esparGroup,
                this._getEsparKitBefore(kitParams, days)
            );
            const {data: esparData = {}} = res;
            const esparDataAfter = this._postEsparAfter(kitParams, esparData);
            actionAfter(esparDataAfter);
        } catch(error) {
            errorAfter(this._postErrorAfter(error));
        }
    }

    /**
     * Получить данные фильтра
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Number} days - кол-во дней
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     * @param {Function} errorAfter - событие, сработающее после получения ошибки
     */
    static async postEsparSelected(kitParams, days, actionAfter = () => {}, errorAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.esparSelected,
                this._getEsparSelectedBefore(days)
            );
            const {data: esparData = {}} = res;
            const esparDataAfter = this._postEsparAfter(kitParams, esparData);
            actionAfter(esparDataAfter);
        } catch(error) {
            errorAfter(this._postErrorAfter(error));
        }
    }

    /**
     *
     * @param {Object} error
     * @private
     */
    static _postErrorAfter(error){
        const {response = {}} = error;
        const {data: responseData = {}} = response;
        const {data: errorObj = {}} = responseData;
        let errorText = "Что то пошло не так";
        if(Object.keys(errorObj).length){
            let key = Object.keys(errorObj)[0];
            errorText = errorObj[key];
        }
        return errorText;
    }

    /**
     * Адаптер для фронта
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Object} summaryData - данные с бэка
     * @return {{price: {name: string, value: number}, name: string, count: number, groups: [], budget: {name: string, value: number, sums: [{name: string, value: number}, {name: string, value: number}, {name: string, value: number}]}}}
     * @private
     */
    static _postEsparAfter(kitParams, summaryData) {
        const {name: kitItemName = '', id: kitItemId} = kitParams;
        const {build_rf_approx : build = {}} = summaryData;
        const {
            ndays = 0,
            esparIdsCount = "",
            campaign_ots: ots = 0,
            campaign_grp: grp = 0,
            campaign_grp_avg: avgGrp = 0,
            campaign_freq: freq = 0,
            campaign_reach: reach = 0,
            campaign_reach_5: reach5 = 0,
            campaign_reach_10: reach10 = 0,
            campaign_reach_20: reach20 = 0
        } = build;
        return {
            id: kitItemId,
            name: kitItemName,
            params: [
                {name: "Кол-во дней", value: ndays},
                {name: "Размещений", value: esparIdsCount},
                {name: "OTS", value: ots},
                {name: "GRP", value: grp},
                {name: "Средний GRP", value: avgGrp},
                {name: "Frequency", value: freq},
                {name: "Reach", value: reach},
                {name: "Reach 5+", value: reach5},
                {name: "Reach 10+", value: reach10},
                {name: "Reach 20+", value: reach20},
            ]
        };
    }
}
