<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9877 15.9999C11.7225 15.9999 11.4682 15.8945 11.2807 15.7069L5.28071 9.70692C5.09855 9.51832 4.99776 9.26571 5.00004 9.00352C5.00232 8.74132 5.10749 8.49051 5.29289 8.3051C5.4783 8.11969 5.72911 8.01452 5.99131 8.01224C6.25351 8.00997 6.50611 8.11076 6.69471 8.29292L11.9877 13.5859L17.2807 8.29292C17.4693 8.11076 17.7219 8.00997 17.9841 8.01224C18.2463 8.01452 18.4971 8.11969 18.6825 8.3051C18.8679 8.49051 18.9731 8.74132 18.9754 9.00352C18.9777 9.26571 18.8769 9.51832 18.6947 9.70692L12.6947 15.7069C12.5072 15.8945 12.2529 15.9999 11.9877 15.9999Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrowV2'
}
</script>