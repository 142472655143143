<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4C8.44772 4 8 4.44772 8 5V15C8 15.5523 8.44772 16 9 16H19C19.5523 16 20 15.5523 20 15V5C20 4.44772 19.5523 4 19 4H9ZM6 5C6 3.34315 7.34315 2 9 2H19C20.6569 2 22 3.34315 22 5V15C22 16.6569 20.6569 18 19 18H9C7.34315 18 6 16.6569 6 15V5Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17V19C18 19.7957 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V9C2 8.20435 2.31607 7.44129 2.87868 6.87868C3.44129 6.31607 4.20435 6 5 6H7C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8H5Z" fill="black"/>
    </svg>    
</template>

<script>
export default {
    name: "IconCopyV2"
}
</script>
