export default {
    state: {
        isShare: false,
        userProfile: {}
    },
    getters: {
        isRealUser(state) {
            return Boolean(state.userProfile?.isRealUser ?? false);
        }
    },
    mutations: {
        setIsShare(state, isShare = false) {
            state.isShare = isShare;
        },
        setUserProfile(state, userProfile = {}) {
            state.userProfile = userProfile;
        }
    }
}