<template>
    <div class="remove-sides-modal">
        <base-modal
            v-if="stage === 'init'"
            :title="title"
            :description="description"
            @close="onCloseModal"
        >
            <template v-slot:modalBody>
                <div class="remove-sides-modal__body-wrap">

                    <div class="remove-sides-modal__label">
                        Вы действительно хотите удалить выбранные {{bufferCount}} стороны?
                    </div>

                    <div class="remove-sides-modal__actions">
                        <base-button
                            form="square"
                            view="border"
                            @click="onApply"
                        >Удалить</base-button>
                        <base-button
                            class="remove-sides-modal__action-btn"
                            form="square"
                            view="secondary"
                            @click="onCancel"
                        >Не хочу</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'wait'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="remove-sides-modal__body-wrap">
                    <span class="remove-sides-modal__label">Пожалуйста, подождите...</span>
                    <div class="remove-sides-modal__load-wrap">
                        <span class="remove-sides-modal__load-fill"></span>
                    </div>

                    <div class="remove-sides-modal__actions">
                        <base-button
                            class="remove-sides-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Отменить</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'complete'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="remove-sides-modal__body-wrap">

                    <div class="remove-sides-modal__label">
                        {{bufferCount}} сторон безвозвратно удалены. Восстановить их не помогут даже программисты
                    </div>

                    <div class="remove-sides-modal__actions">
                        <base-button
                            class="remove-sides-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Закрыть</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'cancel'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="remove-sides-modal__body-wrap">
                    <div class="remove-sides-modal__label">
                        Удаление сторон отменено
                    </div>

                    <div class="remove-sides-modal__actions">
                        <button class="remove-sides-modal__action-alt-btn">Повторить</button>
                        <base-button
                            class="remove-sides-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Закрыть</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
    </div>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal";
import BaseButton from "@/components/Base/BaseButton";

export default {
    name: "RemoveSidesModal",
    components: {
        BaseModal,
        BaseButton,
    },

    data: () => ({
        bufferCount: 0
    }),

    props: {
        file: {
            type: File,
            default: null,
        },
        textParams: {
            type: Array,
            default: () => [],
        },
        tabList: {
            type: Array,
            default: () => [],
        },
        modalType: {
            type: String,
            default: '',
        },
        stage: {
            type: String,
            default: 'init',
            validation: (val) => ['init', 'wait', 'complete', 'cancel'].includes(val)
        },
        activeTab: {
            type: String,
            default: '',
        },
        count: {
            type: Number,
            default: 0,
        },
    },

    beforeMount() {
        this.bufferCount = this.count;
    },

    computed: {
        // Пример структуры text-params
        // {stage: 'init', tab: 'create', title: 'Создать макет'},

        title() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .find((el) => el.title)
            return item?.title ?? '';
        },

        description() {
            return 'Выбрано ' + this.bufferCount + ' сторон';
        },

    },

    methods: {
        /**
         * Клик на кнопку закрыть модальное окно
         */
        onCloseModal() {
            this.$emit('onCloseModal');
        },

        /**
         * Клик на кнопку Применить
         */
        onApply() {
            this.$emit('onMassRemove');
        },

        /**
         * Клик на кнопку Отмена
         */
        onCancel() {
            this.onCloseModal();
        },
    },
}
</script>

<style lang="scss">
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;

.remove-sides-modal .remove-sides-modal__select .select-preview {
    background-color: $color-gray-light;
    color: $color-gray;
    justify-content: space-between;

    &:active, &:focus {
        background-color: #fff;
        color: #000;
    }
}
</style>

<style lang="scss" scoped>
$color-green: #6EC87A;
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;
$color-blue: #4A92F6;
    .remove-sides-modal {
        padding: 0;

        &__body-wrap {
            margin-top: 20px;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            margin-top: 30px;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__action-alt-btn {
            cursor: pointer;
            color: $color-blue;

            &:not(:last-child) {
                margin-right: 30px;
            }

            &:hover {
                color: #000;
            }
        }


        &__load-wrap {
            background-color: $color-gray-light;
            height: 20px;
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 30px;
        }

        &__load-fill {
            display: block;
            width: 0;
            height: inherit;
            background-color: $color-green;
            animation: loading 1.5s ease-in-out infinite;

            @keyframes loading {
                from {
                    width: 0;
                }

                to {
                    width: 100%;
                }
            }
        }
    }
</style>
