/**
 * Cхема для информационных оконо
 * @property {Object} contents - список контентов
 */
const SchemeInfoWindow = {
    replaceHtml: 'replaceHtmlPart',
    contents: {
        default: '',
        infoWindowRadius: `
            <div class="circle_info_window">
                <div class="modal">
                    <div class="modal-title">
                        Радиус области
                    </div>
                    <div class="modal-content">
                        <div class="modal-content-form">
                            <input id="radius-value" type="text" name="descr" class="descr" value="" placeholder="Введите радиус области">
                        </div>
                    </div>
                    <div class="modal-button">
                        <button id="js-set-radius" class="btn btn-default">
                            Сохранить
                        </button>
                        <button class="btn btn-default btn-cancel">
                            Отмена
                        </button>
                    </div>
                </div>
            </div>
        `,
        infoWindowSideSelection: `
            <div class="info-window__side-selecction">
                <div class="info-window__container">
                    <div class="info-window__title">
                        Выбор сторон
                    </div>
                    <div class="info-window__content">
                        replaceHtmlPart
                    </div>
                    <div class="info-window__actions">
                        <button id="js-side-select-all" class="info-window__btn">
                            Выбрать все
                        </button>
                        <button id="js-side-clear-all" class="info-window__btn">
                            Очистить
                        </button>
                    </div>
                </div>
            </div>
        `
    }
};

export default SchemeInfoWindow;
