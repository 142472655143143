<template>
    <label class="base-checkbox">
        <input
            type="checkbox"
            class="base-checkbox__input"
            :value="value"
            v-model="model"
        >
        <span class="base-checkbox__checkmark" :style="{'--size': size + 'px'}">
            <span
                v-if="!isPartChecked"
                class="base-checkbox__active"
                :style="activeCheckboxStyle">&#10003;</span>
            <span
                class="base-checkbox__part-active"
                :class="{'base-checkbox__part-active--is-part' : isPartChecked}"
            ></span>
        </span>
    </label>
</template>

<script>
export default {
    name: "BaseCheckboxV3",
    model: {
        prop: 'checked',
        event: 'change',
    },
    /**
     * Входные данные
     * @property {Number|String} value - значение
     * @property {Boolean} checked - флаг
     * @property {Boolean} isPartChecked - флаг частичного выделения
     */
    props: {
        value: {
            type: [Number, String],
            default: '',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        isPartChecked: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 24,
        },
    },
    computed: {
        /**
         * Модель для привязки к v-model
         */
        model: {
            /**
             * Геттер модели
             * @returns {Boolean}
             */
            get() {
                return this.checked;
            },
            /**
             * Сеттер модели
             * @param {Number|String} val - значение
             */
            set(val) {
                this.$emit('change', val);
            },
        },
        /**
         * Изменить размер чекбокса
         */
        activeCheckboxStyle() {
            let size = this.size - Math.round(((this.size / 100) * 10)) + 'px';
            if (this.size <= 16) {
                size = '14px';
            }

            let fontWeight = 400;
            if (this.size <= 16) {
                fontWeight = 'bold';
            }

            let boxShadow = 'none';
            if (this.isPartChecked && this.checked) {
                boxShadow = `inset 0 0 0 ${(this.size / 2) + 2}px #258df5`
            }

            return {
                '--size': size,
                'font-weight': fontWeight,
                'box-shadow': boxShadow,
            };
        },

    }
}
</script>

<style lang="scss" scoped>
    .base-checkbox {
        &__input {
            display: none;
            visibility: hidden;
            cursor: default;

            &:checked + .base-checkbox__checkmark {
                border: 1px solid #0b76ef;
                background-color: #0b76ef;
                animation: shrink-bounce 400ms cubic-bezier(0.4, 0, 0.23, 1);
                box-shadow: inset 0 0 0 20px #258df5;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked + .base-checkbox__checkmark > .base-checkbox__active {
                transform: scale(1);
            }

            &:checked + .base-checkbox__checkmark > .base-checkbox__part-active {
                display: none;
            }

            &:checked + .base-checkbox__checkmark > .base-checkbox__part-active--is-part {
                display: block;
            }

        }

        &__checkmark {
            --size: 24px;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            width: var(--size);
            height: var(--size);

            margin: 0;

            font-size: var(--size);
            line-height: var(--size);
            background: #fff;
            border: 1px solid #e1e1e1;
            border-radius: 4px;
            cursor: pointer;
            transition: all 400ms cubic-bezier(0.4, 0, 0.23, 1);
        }

        &__part-active {
            --size: 16px;

            position: absolute;
            top: 22%;
            left: 22%;

            width: inherit;
            height: inherit;

            max-height: 50%;
            max-width: 50%;
            border-radius: 50%;
            background-color: #fff;

            margin: 0;
        }

        &__active {
            --size: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: var(--size);
            line-height: var(--size);
            color: #fff;

            width: inherit;
            height: inherit;

            transform: scale(0);
            transition: all 400ms;
        }
    }
</style>
