<template>
	<div
		class="base-icon"
		:class="iconClasses"
		:style="iconStyles"
	></div>
</template>

<script>
export default {
	name: "BaseIcon",
	props: {
		icon: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: 'elg',
			validator: (value) => ['esm', 'sm', 'md', 'lg', 'elg'].includes(value)
		},
		color: {
			type: String,
			default: '',
		}
	},
	computed: {
		iconClasses() {
			return [
				this.icon,
				`base-icon_${this.size}`
			]
		},
		iconStyles() {
			return {
				color: this.color
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.base-icon {
	display: inline-block;

	&_esm { font-size: 6px; }
	&_sm { font-size: 10px; }
	&_md { font-size: 12px; }
	&_lg { font-size: 14px; }
	&_elg { font-size: 18px; }
}
</style>