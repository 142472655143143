import UtilLocalStorage from '../../utils/UtilLocalStorage';
export default class ServiceMapLegends {
    static _localStoragePositionLegendsKey = 'positionLegends';

    static get _isPositionLegends() {
        return UtilLocalStorage.isData(this._localStoragePositionLegendsKey);
    }

    static get _positionLegendsDefault() {
        return {
            placement: {
                top: '215px',
                left: `${window.innerWidth - 325}px`
            },
            offer: {
                top: '215px',
                left: `${window.innerWidth - 485}px`
            },
            colorPanel: {
                top: '215px',
                left: `${window.innerWidth - 705}px`
            }
        };
    }

    static getPositionLegends() {
        return this._isPositionLegends ? UtilLocalStorage.getData(this._localStoragePositionLegendsKey) : this._positionLegendsDefault;
    }

    static setPositionLegends(positionLegends = {}) {
        UtilLocalStorage.setData(this._localStoragePositionLegendsKey, positionLegends);
    }
}