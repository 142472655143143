const SchemeUserMenu = {
    userMenuDropdownItems: [
        {value: 'Мой профиль', emit: 'myProfile'},
        {value: 'Права доступа', emit: 'access'},
        {value: 'Менеджер ссылок', emit: 'managerLink'},
        {value: 'Импорт', emit: 'import'},
        {value: 'Настройки', emit: 'settings'},
    ],
};

export default SchemeUserMenu;
