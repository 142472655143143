import UtilLocalStorage from "../../utils/UtilLocalStorage";

export default class ServiceCompetitorSelector {
    static _localStorageCompetitorDataCheckedKey = 'competitorDataChecked';

    static getCompetitorGroupsChecked() {
        return UtilLocalStorage.getData(this._localStorageCompetitorDataCheckedKey) ?? [];
    }

    static setCompetitorGroupsChecked(groupId = '') {
        let competitorGroupsChecked = this.getCompetitorGroupsChecked();
        competitorGroupsChecked.push(String(groupId));

        UtilLocalStorage.setData(this._localStorageCompetitorDataCheckedKey, competitorGroupsChecked);
    }

    static unsetCompetitorGroupsChecked(groupId = '') {
        let competitorGroupsChecked = this.getCompetitorGroupsChecked();
        competitorGroupsChecked = competitorGroupsChecked.filter(competitorGroupId => String(competitorGroupId) !== String(groupId));

        UtilLocalStorage.setData(this._localStorageCompetitorDataCheckedKey, competitorGroupsChecked);
    }
}
