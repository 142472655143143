export default {
	namespaced: true,
	/**
	 * @property {Array} folders - открытые папки
	 * @property {Array} folderQueue - массив служит для расчета zindex папок если их 2 и более
	 */
	state: {
		folders: [
			// {
			// 	id: 1234,
			// 	isMinimized: true,
			// 	title: 'Kamaahmedhanov'
			// },
			// {
			// 	id: 12234,
			// 	isMinimized: true,
			// 	title: 'Kamaahmedhanov'
			// }
		],
		folderQueue: [],
	},
	getters: {
		folders: state => state.folders,
		expandedFolders: state => state.folders.filter(item => !item.isMinimized),
		minimizedFolders: state => state.folders.filter(item => item.isMinimized === true),
		folderQueue: state => state.folderQueue,
	},
	mutations: {
		/**
		 * Добавляет папку в state
		 * @param {Object} state
		 * @param {String, Number} id
		 */
		openFolder(state, id) {
			// Если папка уже есть в массиве folders
			if (state.folders.findIndex(i => i.id === id) !== -1) { return }

			state.folders.push({id})
			state.folders = [ ...state.folders ]
			// фиксирую папку в очереди
			if (!state.folderQueue.includes(id)) {
				state.folderQueue.push(id)
			}
		},
		/**
		 * Удаляет папку из state
		 * @param {Object} state
		 * @param {String, Number} id
		 */
		closeFolder(state, id) {
			// удаляю из массива folders
			let index = state.folders.findIndex(item => item.id === id)
			if (index !== -1)
				state.folders.splice(index, 1)

			// удаляю из массива folderQueue
			index = state.folderQueue.indexOf(id)
			if (index !== -1)
				state.folderQueue.splice(index, 1)
		},
        /**
         * Переключить расположение окна (весь экран/половина)
         * @param {Object} state
         * @param {Object} item
         */
        toggleResizeFolder(state, item) {
            let folderItem = state.folders.find((el) => String(el.id) === String(item.id));
            folderItem.resize = item.resize;
            state.folders = [...state.folders];
        },
		collapseFolder(state, id) {
			let folderItem = state.folders.find(item => item.id === id)
			folderItem.isMinimized = true
			state.folders = [...state.folders]
		},
		expandFolder(state, id) {
			let folderItem = state.folders.find(item => item.id === id)
			folderItem.isMinimized = false
			state.folders = [...state.folders]
		},
		/**
		 * Если открыто несколько папок,
		 * то при клике на определенную папку она выходит на передний план
		 * @param {Object} state
		 * @param {String, Number} id
		 */
		bringToFront(state, id) {
			const index = state.folderQueue.indexOf(id)
			const item = state.folderQueue.splice(index, 1)
			state.folderQueue.push(item[0])
		},
		// setFolderData(state, data) {
		// 	const {id, title} = data
		// 	const folderItem = state.folders.find(item => item.id == id)

		// 	if (folderItem) {
		// 		folderItem.title = title
		// 		state.folders = [...state.folders]
		// 	}
		// }

	},
}