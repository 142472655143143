<template>
    <div>
        <div
            @click.self="isOpenedRequestDropdown = false"
            class="poi-modal__row"
        >
            <p class="poi-modal__text">
                {{ addGroupModal.textGroupName }}
                <span style="color: red">*</span>
            </p>
            <base-text-input
                view="primary"
                :placeholder="addGroupModal.textGroupNamePlaceholder"
                v-model.trim="groupName"
            />
        </div>

        <slot name="icons" />

        <div class="poi-modal__actions">
            <base-button
                class="poi-modal__cancel"
                form="square"
                view="border"
                @click="onCloseModal"
            >
                {{ addGroupModal.textCancel }}
            </base-button>
            <base-button
                form="square"
                view="secondary"
                :disabled="isDisabled"
                @click="onCreateNewMoiPoiGroup"
            >
                {{ addGroupModal.textStartCreateMoiPoint }}
            </base-button>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from "@/components/Base/BaseTextInput";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

export default {
    name: "AddPoiModalContent",

    components: {
        BaseButton,
        BaseTextInput,
    },

    data: () => ({
        addGroupModal: containerPoi.addGroupModal,
        groupName: "",
    }),

    computed: {
        isDisabled() {
            return this.groupName.length === 0;
        },
    },

    methods: {
        onInput(value) {
            this.groupName = value;
        },

        onCloseModal() {
            this.$emit("onCloseModal");
        },

        onCreateNewMoiPoiGroup() {
            const requestCreateGroupParams = {
                name: this.groupName,
            };

            this.$emit("onCreateNewMoiPoiGroup", requestCreateGroupParams);
        },

        onStartAddingPoints() {
            this.onCreateNewMoiPoiGroup();
            this.$emit("onStartAddingPoints");
        },
    },
};
</script>
