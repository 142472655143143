<template>
    <img
        class="main-logo"
        :src="logo"
        width="250"
        alt=""
    >
</template>

<script>

export default {
    name: 'DefaultLayout',
    computed: {
        logo(){
            return require('@/assets/images/logo-big.png');
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-right: -50%;
    }
</style>
