<template>
    <div class="wrapper" :class="getRootClasses">
        <input
            type="text"
            class="input"
            ref="input"
            :value="value"
            v-bind="$attrs"
            :style="styleObj"
            :placeholder="placeholder"
            :readonly="isReadOnly"
            v-on:keyup.enter="onBlur"
            @input="onInput"
            @blur="onBlur"
        />
        <div
            v-if="isClear"
            class="clear icon-tabler-icon-trash"
            @click="onClear"
        ></div>
        <!-- Был символ крестик - &#x2715;-->
        <div v-if="!isValid" class="input-error-msg">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseInput",
    props: {
        modificator: {
            type: String,
            default: "",
        },
        value: {
            type: String,
        },
        canClear: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
            default: "line",
            validator: (value) =>
                ["base", "line", "primary", "table"].includes(value),
        },
        styleObj: {
            type: Object,
            default: () => ({}),
        },
        placeholder: {
            type: String,
            default: "",
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isValidation: {
            type: Boolean,
            default: false,
        },
        validationRule: {
            type: Function,
            default: () => ({
                isLength: true,
                isRegexp: true,
                isValid: true,
                valueFormatted: "",
                errorMessage: "",
            }),
        },
    },
    data: () => ({
        inputLazyTimerId: null,
        isValid: true,
        errorMessage: "",
    }),
    computed: {
        /**
         * Условие рендера крестика очистки инпута
         * @returns {Boolean}
         */
        isClear() {
            return (
                this.canClear && this.value !== "" && this.value !== undefined
            );
        },
        /**
         * Классы для корневого элемента
         */
        getRootClasses() {
            const classes = ["wrapper_" + this.view];

            if (this.modificator) {
                classes.push(`wrapper--${this.modificator}`);
            }

            if (!this.isValid) classes.push(`wrapper_error`);

            return classes;
        },
    },
    methods: {
        onInput(event) {
            const value = event === null ? "" : event.target.value;
            if (this.isValidation) {
                const {
                    isValid = true,
                    valueFormatted = "",
                    errorMessage = "",
                } = this.validationRule(value);
                this.isValid = isValid;
                this.errorMessage = errorMessage;
                this.onInputAfter(valueFormatted);
            } else this.onInputAfter(value);
        },
        onInputAfter(value) {
            this.$emit("input", value, this.isValid);
            //TODO: при событии ввода вызываю событие лениового ввода, которое либо дропнет предудыщее, либо выполнится
            this.onInputLazy(value);
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = "") {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit("onInputLazy", value, this.isValid);
            }, 300);
        },
        onClear() {
            this.$refs.input.value = "";
            this.onInput(null);
        },
        /**
         * Событие для фокусировки инпута
         */
        onFocus() {
            this.$refs.input.focus();
        },
        /**
         * Событие снятия фокусировки с инпута
         */
        onBlur(event) {
            this.$emit("onBlur", event);
        },
    },
};
</script>

<style lang="scss" scoped>
$primary-color: #f0f3f8 !default;
$primary-lighten-color: #f9fafb !default;
$primary-disabled-color: #dce1e8 !default;
$assistant-color: #adb0bb !default;
$active-color: #4a92f6 !default;
$active-darker-color: #1f79d2 !default;
$danger-color: #f84967 !default;
$success-color: #6ec87a !default;
$success-lighten-color: #c5e9ca !default;
$reserved-color: #ffb300 !default;
$bg-input-color: #f5f5f5;
$input-color: #000000;
$placeholder-color: #c8cad1;

.input-error-msg {
    position: absolute;
    color: $danger-color;
    bottom: -20px;
}

.wrapper {
    position: relative;
    display: block;
    width: 100%;

    &_error {
        border-radius: 10px;
        border: 1px solid $danger-color;
    }

    &_base {
        .input {
            display: block;
            width: 100%;
            padding: 0 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
            &::placeholder {
                color: $placeholder-color;
            }
        }
    }

    &_line {
        .input {
            height: 14px;
            display: block;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            border: none;
            // border-bottom: 1px solid;
            border-color: #e7e7eb;
            background: none;

            &:focus {
                outline: none;
                border-color: #000;
                color: #000;
            }
        }

        &:after {
            position: absolute;
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            background-color: #000;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    &_primary {
        .input {
            background: $primary-color;
            border: none;
            outline: none;
            height: 36px;
            width: 100%;
            display: block;
            padding-left: 15px;
            font-weight: 700;
            font-size: 12px;
            color: #000;
            border-radius: 10px;
            transition: all ease-in-out 0.2s;
            border: 1px solid transparent;

            &::placeholder {
                color: $placeholder-color;
            }

            &:focus {
                color: #000;
                background: #fff;
                border-color: $success-color ;
            }
        }
    }

    &_table {
        .input {
            display: block;
            width: 100%;
            font-size: 12px;
            line-height: 14px;
            background: #fff;
            border: none;

            padding: 5px 15px;
            padding-right: 30px;

            &:focus {
                background: #f2f2f2;
                border-color: transparent;
                outline: none;
                color: #6ec87a;
            }
        }
    }

    .clear {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        cursor: pointer;
        line-height: 1.5;
        font-weight: 700;
        font-size: 14px;
        color: #adb0bb;

        &:hover {
            color: red;
        }
    }

    &--login {
        .input {
            height: 44px;
            font-size: 14px;
            font-weight: 400;
            color: $input-color;
            background: $bg-input-color;
            box-sizing: border-box;
        }
    }
}
</style>
