<template>
    <BaseDraggableWindowSimple>
        <template #content>
            <base-modal
                @close="onCloseModal"
                :withoutBackdrop="true"
                class="add-poi-modal"
            >
                <template v-slot:modalBody>
                    <div
                        @click.self="closeDropdowns"
                        class="add-poi-modal__body"
                    >
                        <div v-if="isLoading" class="add-poi-modal__preloader">
                            <BasePreloaderGifV2 :size="60" />
                        </div>
                        <template v-if="!isLoading">
                            <div class="add-poi-modal__header">
                                {{ addMoiPoiPointsModal.textAddPoi }}
                            </div>

                            <div
                                class="add-poi-modal__row coords"
                                @click.self="closeDropdowns"
                            >
                                <div class="add-poi-modal__info">
                                    <div class="add-poi-modal__info-img">
                                        <IconMapClick />
                                    </div>
                                    <div class="add-poi-modal__info-text">
                                        <div
                                            class="add-poi-modal__info-text-title"
                                        >
                                            Кликните точку на карте
                                        </div>
                                        <div
                                            class="add-poi-modal__info-text-subtitle"
                                        >
                                            Карта кликабельна. Кликните левой
                                            кнопки мыши на нужном месте
                                        </div>
                                    </div>
                                </div>
                                <div class="add-poi-modal__coords">
                                    Координаты:
                                    <div :class="{ active: true }">
                                        {{ latCoord }}
                                    </div>
                                    ,
                                    <div :class="{ active: true }">
                                        {{ lngCoord }}
                                    </div>
                                </div>

                                <div class="add-poi-modal__control">
                                    <BaseInput
                                        type="number"
                                        class="add-poi-modal__control-item"
                                        :value="enteredMoiPoiCoords.lat"
                                        @onInput="onInputCoords($event, 'lat')"
                                    />
                                    <BaseInput
                                        type="number"
                                        class="add-poi-modal__control-item"
                                        :value="enteredMoiPoiCoords.lng"
                                        @onInput="onInputCoords($event, 'lng')"
                                    />
                                </div>
                            </div>

                            <div
                                class="add-poi-modal__row"
                                @click.self="closeDropdowns"
                            >
                                <div style="text-align: center; opacity: 0.5">
                                    ИЛИ
                                </div>
                            </div>

                            <div
                                class="add-poi-modal__row"
                                @click.self="closeDropdowns"
                            >
                                <div style="margin-bottom: 10px">
                                    {{ addMoiPoiPointsModal.textInputLabel }}
                                    <span style="color: red">*</span>
                                </div>
                                <MoiPoiSelectSingle
                                    :isFullWidth="true"
                                    :options="requestItems"
                                    :value="selectedRequestItemId"
                                    :searchValueFromCoords="requestQuery"
                                    :isLoadingDropdown="isLoadingRequest"
                                    :isOpenedDropdown="isOpenedRequestDropdown"
                                    :requestError="requestError"
                                    :canSearch="true"
                                    @input="onInput"
                                    @onInputLazy="onInputLazy"
                                    @check="onSelectRequestId"
                                />
                            </div>

                            <div
                                class="add-poi-modal__row"
                                @click.self="closeDropdowns"
                            >
                                <div style="margin-bottom: 10px">Теги</div>
                                <MoiPoiTags
                                    :isFullWidth="true"
                                    :options="allTags"
                                    :enteredTags="enteredPointTags"
                                    :value="selectedTag"
                                    :searchValueFromCoords="requestedTag"
                                    :isLoadingDropdown="isLoadingTagRequest"
                                    :isOpenedDropdown="isOpenedTagsRequestDropdown"
                                    :requestError="tagsRequestError"
                                    @mounted="$emit('openedTags')"
                                    @input="onTagInput"
                                    @check="onTagSelect"
                                    @onRemoveTag="onRemoveTag"
                                    @onClickCreate="
                                        isOpenedTagsRequestDropdown =
                                            !isOpenedTagsRequestDropdown
                                    "
                                />
                            </div>

                            <div class="add-poi-modal__actions">
                                <base-button
                                    form="square"
                                    view="secondary"
                                    :disabled="isDisabled"
                                    @click="onAddPoint"
                                >
                                    {{ addMoiPoiPointsModal.textAddPoint }}
                                </base-button>
                            </div>
                        </template>
                    </div>
                </template>
            </base-modal>
        </template>
    </BaseDraggableWindowSimple>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButton from "@/components/Base/BaseButton";
import BaseInput from "@/components/Base/BaseInput";
import IconMapClick from "@/components/Icons/IconMapClick";
import MoiPoiSelectSingle from "../MapV2/components/ContainerPoi/MoiPoiSelectSingle";
import MoiPoiTags from "../MapV2/components/ContainerPoi/MoiPoiTags.vue";
import schemeWidgetMap from "@/components/MapV2/schemes/SchemeWidgetMap";
import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2";
import ServicePoi from "../../services/ServicesPoi/ServicePoi";
import BaseDraggableWindowSimple from "../Base/BaseDraggableWindowSimple.vue";

export default {
    name: "AddPoiModal",
    components: {
        BasePreloaderGifV2,
        BaseModal,
        BaseButton,
        MoiPoiSelectSingle,
        MoiPoiTags,
        BaseInput,
        IconMapClick,
        BaseDraggableWindowSimple,
    },
    /**
     * Входные Данные компонента
     * @property {Boolean} isLoading - Флаг предыдущее добавление группы еще выполняется
     */
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestItems: {
            type: Array,
            default: () => [],
        },
        userTags: {
            type: Array,
            default: () => [],
        },
        isLoadingRequest: {
            type: Boolean,
            default: false,
        },
        isLoadingTagRequest: {
            type: Boolean,
            default: false,
        },
        requestError: {
            type: String,
            default: "",
        },
        tagsRequestError: {
            type: String,
            default: "",
        },
        clickedMoiPoiCoords: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        selectedTag: "",
        requestedTag: "",
        requestQuery: "",
        selectedRequestItemId: "",
        selectedRequestItem: {},
        isOpenedRequestDropdown: false,
        isOpenedTagsRequestDropdown: false,
        enteredMoiPoiCoords: {
            lat: 0,
            lng: 0,
        },
        enteredPointTags: [],
    }),

    watch: {
        clickedMoiPoiCoords(coords) {
            this.enteredMoiPoiCoords = {
                lat: coords[0].toPrecision(6),
                lng: coords[1].toPrecision(6),
            };
        },
        enteredMoiPoiCoords(coords) {
            if (coords?.noEdit) return;

            ServicePoi.findAddressByCoords(
                [coords.lat, coords.lng],
                (result) => {
                    if (!result.length) return;
                    this.onInput(result[0].address);
                }
            );
        },
    },

    computed: {
        allTags() {
            return [
                ...new Set([
                    ...this.userTags?.map((item) => item.name),
                    ...this.enteredPointTags,
                ]),
            ];
        },

        latCoord() {
            return (
                Number(this.enteredMoiPoiCoords.lat).toPrecision(6) ||
                "Не выбрано"
            );
        },

        lngCoord() {
            return (
                Number(this.enteredMoiPoiCoords.lng).toPrecision(6) ||
                "Не выбрано"
            );
        },
        /**
         * Подключить scheme
         */
        addMoiPoiPointsModal() {
            const { containerPoi = {} } = schemeWidgetMap;
            const { addMoiPoiPointsModal = {} } = containerPoi;
            return addMoiPoiPointsModal;
        },

        /**
         * Вкл/выкл состояние Disabled для кнопки Применить
         */
        isDisabled() {
            return !this.selectedRequestItemId.length;
        },
    },

    methods: {
        onInputCoords(value, coord) {
            switch (coord) {
                case "lat":
                    this.enteredMoiPoiCoords.lat = Number(value).toPrecision(6);
                    break;

                case "lng":
                    this.enteredMoiPoiCoords.lng = Number(value).toPrecision(6);
                    break;
            }

            ServicePoi.findAddressByCoords(
                [this.enteredMoiPoiCoords.lat, this.enteredMoiPoiCoords.lng],
                (result) => {
                    if (!result.length) return this.onInput("");
                    this.onInput(result[0].address);
                }
            );
        },
        onInput(value) {
            this.selectedRequestItemId = "";
            this.requestQuery = value;
        },
        onInputLazy(value) {
            this.isOpenedRequestDropdown = true;
            this.$emit("onAutoComplete", value);
        },
        onSelectRequestId(value) {
            this.isOpenedRequestDropdown = false;
            this.selectedRequestItemId = value.id;
            this.selectedRequestItem = {
                ...value,
                categories: this.enteredPointTags,
            };
            this.enteredMoiPoiCoords = {
                lat: Number(value.lat).toPrecision(6),
                lng: Number(value.lng).toPrecision(6),
                noEdit: true,
            };
        },
        onTagInput(value) {
            this.isOpenedTagsRequestDropdown = true;
            this.$emit("onTagsAutoComplete", value);
        },
        onTagSelect(value) {
            this.isOpenedTagsRequestDropdown = false;

            if (this.enteredPointTags.includes(value)) return;
            this.selectedTag = value;
            this.enteredPointTags.push(value);
        },
        onRemoveTag(value) {
            this.enteredPointTags = this.enteredPointTags.filter(
                (item) => item !== value
            );
        },
        onCloseModal() {
            this.$emit("onCloseModal");
        },
        onAddPoint() {
            this.$emit("onAddPoint", this.selectedRequestItem);
        },
        closeDropdowns() {
            this.isOpenedRequestDropdown =
                this.isOpenedTagsRequestDropdown = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.add-poi-modal {
    @media (max-width: 576px) {
        width: 300px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &-img {
            margin-right: 10px;
        }

        &-text {
            &-title {
                font-size: 15px;
                font-weight: 600;
            }
            &-subtitle {
                font-size: 13px;
            }
        }
    }
    &__coords {
        display: flex;
        align-items: center;

        & > div {
            margin-left: 5px;
        }
        & * {
            opacity: 0.5;
        }
        & *.active {
            opacity: 1;
        }
    }
    &__control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        &-item {
            padding: 8px 15px;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        /*width: 360px;*/
        min-height: max-content;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
    }

    &__preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &__header {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #000;

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    &__row {
        margin-bottom: 20px;

        &.coords {
        }

        &:last-child {
            margin-bottom: 25px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
    }

    &__error {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
        color: #ff7c8e;
    }

    &__attach-file-button {
        display: inline-block;
        //border-radius: 1000px;
    }

    &__icons {
        display: flex;
        gap: 5px;
    }

    &__icon-wrap {
        width: 22px;
        height: 22px;
        background: none;
        border-radius: 2px;
        border: 1px solid #e4e8f0;
        cursor: pointer;

        &--active {
            border: 1px solid $active-color;
        }
    }

    &__file-info {
        //max-width: 100%;
        //width: 100%;
        display: flex;
        text-align: left;

        &-title {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-icon {
            font-size: 12px;
            flex-shrink: 0;
            margin-left: 10px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__cancel {
        color: #c8cad1;

        &:hover,
        &:active {
            color: #333;
        }
    }
}
</style>
