<template>
    <div class="action-panel">
        <div class="action-panel__toggle">
            <BaseButtonToggleVisible
                :isVisible.sync="isVisibleToggleBtnBuffer"
                class="action-panel__toggle-btn"
            />
        </div>
        <div
            :class="{'action-panel__found-block_loading': isLoading}"
            class="action-panel__found-block"
        >
            <BasePreloaderSkeleton
                v-if="isLoading"
                class="action-panel__preloader"
            />
            <p :class="['action-panel__found-block-text', { 'disabled': !countFound }]">
                <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                Найдено
                <span class="action-panel__found-block-value">
                    {{ countFound }}
                </span>
            </p>
            <BaseButtonIcon
                :isDisabled="!countFound"
                tooltip-text="Выбрать все"
                icon-component="IconCopy"
                class="action-panel__btn"
                @onBaseButtonIcon="$emit('onSetAllSelected')"
            >
                <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
            </BaseButtonIcon>
            <KitSharedPanel
                v-if="isShare"
                :kitItems="kitItems"
                :kitActions="kitActions"
                :isMobile="isMobile"
                @onToggleMarkers="onToggleMarkers"
                @onToggleSummary="onToggleSummary"
                @onExport="onExport"
                @onToggleAdmetrix="onToggleAdmetrix"
                @onToggleTable="onToggleTable"
            />
        </div>
        <div
            :class="{'action-panel__found-block_loading': isLoading}"
            class="action-panel__select-block"
        >
            <BasePreloaderSkeleton
                v-if="isLoading"
                class="action-panel__preloader"
            />
            <p :class="['action-panel__select-block-text', { 'disabled': !countSelected }]">
                <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                Выбрано
                <span class="action-panel__select-block-value">
                    {{ countSelected }}
                </span>
            </p>
            <div class="action-panel__additional-block">
                <MobileDropdownMenu
                    v-if="isMobile"
                    :isDisabled="!countSelected"
                    :actions="actionsMobileScheme"
                    @onClearSelected="$emit('onClearSelected')"
                    @onSummarySelected="$emit('onSummarySelected')"
                    @onEsparSelected="$emit('onEsparSelected')"
                    @onExportSelected="$emit('onExportSelected')"
                    @onDelete="onDelete"
                    @onSelectAction="onSelectAction"
                    @onChangeUserStatus="onChangeUserStatus"
                />
                <template v-if="!isMobile">
                    <BaseButtonIcon
                        :isDisabled="!countSelected"
                        tooltip-text="Очистить"
                        icon-component="IconEraser"
                        class="action-panel__btn"
                        @onBaseButtonIcon="$emit('onClearSelected')"
                    >
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                    </BaseButtonIcon>
                    <BaseButtonIcon
                        :isDisabled="!countSelected"
                        :isActive="isSelectedSummary"
                        tooltip-text="Сводные данные"
                        icon-component="IconInfo"
                        class="action-panel__btn"
                        @onBaseButtonIcon="$emit('onSummarySelected')"
                    >
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                    </BaseButtonIcon>
                    <BaseButtonIcon
                        :isDisabled="!countSelected"
                        :isActive="isSelectedAdmetrix"
                        tooltip-text="AdMetrix"
                        icon-component="IconRocket"
                        class="action-panel__btn"
                        @onBaseButtonIcon="$emit('onEsparSelected')"
                    >
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                    </BaseButtonIcon>
                    <BaseButtonIcon
                        :isDisabled="!countSelected"
                        tooltip-text="Скачать Excel"
                        icon-component="IconFileDownload"
                        class="action-panel__btn"
                        @onBaseButtonIcon="$emit('onExportSelected')"
                    >
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                    </BaseButtonIcon>
                    <BaseButtonIcon
                        v-if="!isShare"
                        :isDisabled="!countSelected"
                        tooltip-text="Удалить"
                        icon-component="IconTrash"
                        class="action-panel__btn"
                        @onBaseButtonIcon="onDelete"
                    >
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                    </BaseButtonIcon>
                </template>
            </div>
            <div class="action-panel__actions-block" v-if="!isMobile">
                <div class="action-panel__actions-select">
                    <div class="action-panel__select">
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                        <BaseSelect
                            :isDisabled="!countSelected"
                            default-title="Создать"
                            :is-fixed="false"
                            :options="getOptions"
                            :is-close-after-check="true"
                            @check="onSelectAction"
                        />
                    </div>
                    <div class="action-panel__select">
                        <!-- <BasePreloaderSkeleton v-if="isLoading" /> -->
                        <BaseSelect
                            :isDisabled="!countSelected"
                            default-title="Статус"
                            :is-fixed="false"
                            :options="clientStatuses"
                            :is-close-after-check="true"
                            @check="onChangeUserStatus"
                        />
                    </div>
                    <BaseButton
                        class="action-panel__button"
                        :class="{'action-panel__button_disabled': !countSelected}"
                        :disabled="!countSelected"
                        @click="onMassEditModal"
                    >
                        Редактировать
                    </BaseButton>
                </div>
            </div>
        </div>

        <transition name="transition-modal">
            <CreateModal
                v-if="createModalType.length"
                :modal-type="createModalType"
                :stage="modalStage"
                :file="file"
                :tab-list="tabList"
                :active-tab="activeModalTab"
                :text-params="createModalTextParams"
                :options="modalOptions"
                :count="countSelected"
                @changeFile="changeFile"
                @onApplyModal="onModifyPriceGroup"
                @clearFile="file = null"
                @onCloseModal="onCloseModal"
                @toggleTab="toggleTab"
            />
        </transition>

        <transition name="transition-modal">
            <ModalMassEdit
                v-if="isMassEditModal"
                :count="countSelected"
                :massEditData="massEditData"
                @onCloseModal="onCloseModal"
                @saveMassEdit="saveMassEdit"
            />
        </transition>

        <!-- <massEditModal
            v-if="isMassEditModal"
            :stage="modalStage"
            :text-params="massSelectModalParams"
            @onCloseModal="onCloseModal"
        /> -->

        <transition name="transition-modal">
            <RemoveSidesModal
                v-if="isRemoveModal"
                :text-params="removeModalParams"
                :stage="modalStage"
                :count="countSelected"
                @onCloseModal="onCloseModal"
                @onMassRemove="onMassRemove"
            />
        </transition>

    </div>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue';
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseSelect from "@/components/Base/BaseSelect";
import CreateModal from "@/components/Modals/CreateModal";
// import MassEditModal from "@/components/Modals/MassEditModal";
import ModalMassEdit from '../Modals/ModalMassEdit.vue';
import RemoveSidesModal from "@/components/Modals/RemoveSidesModal";
import BasePreloaderSkeleton from "../Base/BasePreloaderSkeleton.vue";
import ServicePriceGroup from "../../services/servicesPriceGroup/ServicePriceGroup";
import ServiceConstructionData from '../../services/ServicesConstructionData/ServiceConstructionData';
import SchemeActionPanel from "../../schemes/SchemeActionPanel";
import KitSharedPanel from "../KitPanel/KitSharedPanel";
import MobileDropdownMenu from '../Mobile/MobileDropdown/MobileDropdownMenu.vue';
import BaseButtonToggleVisible from '../Base/BaseButtonToggleVisible.vue';
const {clientStatuses, actionsMobile} = SchemeActionPanel;
export default {
    name: "ActionPanel",
    components: {
        BaseButton,
        RemoveSidesModal,
        // MassEditModal,
        ModalMassEdit,
        CreateModal,
        BaseButtonIcon,
        BaseSelect,
        BasePreloaderSkeleton,
        KitSharedPanel,
        MobileDropdownMenu,
        BaseButtonToggleVisible
    },
    props: {
        kitItems: {
            type: Array,
            default: () => ([])
        },
        kitActions: {
            type: Array,
            default: () => ([])
        },
        isActiveTable: {
            type: Boolean,
            default: false,
        },
        isActiveMap: {
            type: Boolean,
            default: true,
        },
        isSelectedSummary: {
            type: Boolean,
            default: true,
        },
        isSelectedAdmetrix: {
            type: Boolean,
            default: true,
        },
        countFound: {
            type: Number,
            default: 0
        },
        countSelected: {
            type: Number,
            default: 0
        },
        selectedPriceIds: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isActiveKitsIdsEndEdit: {
            type: Boolean,
            default: false
        },
        isVisibleToggleBtn: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({
        createModalType: '',  // maket, project, proposal, kit
        isExporting: false,
        isShareModal: false,
        isMassEditModal: false,
        isRemoveModal: false,
        file: null,
        modalStage: 'init',  // init, wait, complete, cancel
        activeModalTab: '',
        modalOptions: [],
        clientStatuses: clientStatuses,
        actionsMobile: [...actionsMobile],
        isMobile: false,
        massEditData: []
    }),

    created() {
        this.activeModalTab = this.tabList[0]?.value;
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },

    computed: {
        isVisibleToggleBtnBuffer: {
            get() {
                return this.isVisibleToggleBtn;
            },
            set(isVisibleToggleBtn) {
                this.$emit('update:isVisibleToggleBtn', isVisibleToggleBtn);
            }
        },
        actionsMobileScheme() {
            return this.isShare ? this.actionsMobile.filter(action => action.isCanShare === true) : this.actionsMobile;
        },

        getOptions() {
            return [
                {value: 'Создать макет', id: 'createMaket'},
                {value: 'Создать проект', id: 'createProject'},
                {value: 'Создать предложение', id: 'createProposal'},
                {value: 'Создать набор', id: 'createKit'},
                // {value: 'Массовое редактирование', id: 'massEdit'},
            ];
        },

        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },

        createModalTextParams() {
            if (this.createModalType === 'project') {
                return [
                    {stage: 'init', tab: 'create', title: 'Создать проект'},
                    {stage: 'init', tab: 'attach', title: 'Прикрепить к проекту'},
                    {stage: 'init', tab: 'create', inputText: 'Название проекта'},
                    {stage: 'init', tab: 'attach', selectText: 'Выберите проект'},
                    {stage: 'init', tab: 'attach', selectDefaultValue: 'Название выбранного проекта'},
                    {stage: 'init', tab: 'create', applyBtnText: 'Создать'},
                    {stage: 'init', tab: 'attach', applyBtnText: 'Прикрепить'},
                    {stage: 'wait', tab: 'create', title: 'Создание проекта'},
                    {stage: 'wait', tab: 'attach', title: 'Прикрепление сторон'},
                    {stage: 'complete', tab: 'create', title: 'Проект создан'},
                    {stage: 'complete', tab: 'attach', title: 'Стороны прикреплены'},
                    {stage: 'complete', tab: 'create', altBtnText: 'Перейти в проект'},
                    {stage: 'complete', tab: 'attach', altBtnText: 'Перейти в проект'},
                    {stage: 'complete', tab: 'create', descr: 'проект'},
                    {stage: 'complete', tab: 'attach', descr: 'проекту'},
                    {stage: 'cancel', tab: 'create', title: 'Проект не создан'},
                    {stage: 'cancel', tab: 'attach', title: 'Стороны не прикреплены'},
                    {stage: 'cancel', tab: 'create', descr: 'Создание проекта отменено'},
                    {stage: 'cancel', tab: 'attach', descr: 'Прикрепление сторон к проекту отменено'},
                ];
            }

            if (this.createModalType === 'proposal') {
                return [
                    {stage: 'init', tab: 'create', title: 'Создать предложение'},
                    {stage: 'init', tab: 'attach', title: 'Прикрепить к предложению'},
                    {stage: 'init', tab: 'create', inputText: 'Название предложения'},
                    {stage: 'init', tab: 'attach', selectText: 'Выберите предложение'},
                    {stage: 'init', tab: 'attach', selectDefaultValue: 'Название выбранного предложения'},
                    {stage: 'init', tab: 'create', applyBtnText: 'Создать'},
                    {stage: 'init', tab: 'attach', applyBtnText: 'Прикрепить'},
                    {stage: 'wait', tab: 'create', title: 'Создание предложения'},
                    {stage: 'wait', tab: 'attach', title: 'Прикрепление сторон'},
                    {stage: 'complete', tab: 'create', title: 'Предложение создано'},
                    {stage: 'complete', tab: 'attach', title: 'Стороны прикреплены'},
                    {stage: 'complete', tab: 'create', altBtnText: 'Перейти в предложение'},
                    {stage: 'complete', tab: 'attach', altBtnText: 'Перейти в предложение'},
                    {stage: 'complete', tab: 'create', descr: 'предложение'},
                    {stage: 'complete', tab: 'attach', descr: 'к предложению'},
                    {stage: 'cancel', tab: 'create', title: 'Предложение не создано'},
                    {stage: 'cancel', tab: 'attach', title: 'Стороны не прикреплены'},
                    {stage: 'cancel', tab: 'create', descr: 'Создание предложения отменено'},
                    {stage: 'cancel', tab: 'attach', descr: 'Прикрепление сторон к предложению отменено'},
                ];
            }

            if (this.createModalType === 'kit') {
                return [
                    {stage: 'init', tab: 'create', title: 'Создать набор'},
                    {stage: 'init', tab: 'attach', title: 'Прикрепить к набору'},
                    {stage: 'init', tab: 'create', inputText: 'Название набора'},
                    {stage: 'init', tab: 'attach', selectText: 'Выберите набор'},
                    {stage: 'init', tab: 'attach', selectDefaultValue: 'Название выбранного набора'},
                    {stage: 'init', tab: 'create', applyBtnText: 'Создать'},
                    {stage: 'init', tab: 'attach', applyBtnText: 'Прикрепить'},
                    {stage: 'wait', tab: 'create', title: 'Создание набора'},
                    {stage: 'wait', tab: 'attach', title: 'Прикрепление сторон'},
                    {stage: 'complete', tab: 'create', title: 'Набор создан'},
                    {stage: 'complete', tab: 'attach', title: 'Стороны прикреплены'},
                    {stage: 'complete', tab: 'create', altBtnText: 'Перейти в нвбор'},
                    {stage: 'complete', tab: 'attach', altBtnText: 'Перейти в нвбор'},
                    {stage: 'complete', tab: 'create', descr: 'нвбор'},
                    {stage: 'complete', tab: 'attach', descr: 'к предложению'},
                    {stage: 'cancel', tab: 'create', title: 'Набор не создан'},
                    {stage: 'cancel', tab: 'attach', title: 'Стороны не прикреплены'},
                    {stage: 'cancel', tab: 'create', descr: 'Создание набора отменено'},
                    {stage: 'cancel', tab: 'attach', descr: 'Прикрепление сторон к набору отменено'},
                ];
            }

            return [
                {stage: 'init', tab: 'create', title: 'Создать макет'},
                {stage: 'init', tab: 'attach', title: 'Прикрепить к макету'},
                {stage: 'init', tab: 'create', inputText: 'Название макета'},
                {stage: 'init', tab: 'attach', selectText: 'Выберите макет'},
                {stage: 'init', tab: 'attach', selectDefaultValue: 'Выберите макет'},
                {stage: 'init', tab: 'create', applyBtnText: 'Создать'},
                {stage: 'init', tab: 'attach', applyBtnText: 'Прикрепить'},
                {stage: 'wait', tab: 'create', title: 'Создание макета'},
                {stage: 'wait', tab: 'attach', title: 'Прикрепление сторон'},
                {stage: 'complete', tab: 'create', title: 'Макет создан'},
                {stage: 'complete', tab: 'attach', title: 'Стороны прикреплены'},
                {stage: 'complete', tab: 'create', altBtnText: 'Перейти в макет'},
                {stage: 'complete', tab: 'attach', altBtnText: 'Перейти в макет'},
                {stage: 'complete', tab: 'create', descr: 'макет'},
                {stage: 'complete', tab: 'attach', descr: 'макету'},
                {stage: 'cancel', tab: 'create', title: 'Макет не создан'},
                {stage: 'cancel', tab: 'attach', title: 'Стороны не прикреплены'},
                {stage: 'cancel', tab: 'create', descr: 'Создание макета отменено'},
                {stage: 'cancel', tab: 'attach', descr: 'Прикрепление сторон к макету отменено'},
            ];
        },

        massSelectModalParams() {
            return [
                {stage: 'init', title: 'Массовое редактирование'},
                {stage: 'init', inputText: 'Напишите значение'},
                {stage: 'init', selectDefaultValue: 'Выбрать'},
                {stage: 'init', applyBtnText: 'Изменить'},
                {stage: 'wait', title: 'Изменение данных'},
                {stage: 'complete', title: 'Данные изменены'},
                {stage: 'cancel', title: 'Данные не изменены'},
            ];
        },

        removeModalParams() {
            return [
                {stage: 'init', title: 'Удаление сторон'},
                {stage: 'wait', title: 'Удаляем стороны'},
                {stage: 'complete', title: 'Стороны удалены'},
                {stage: 'cancel', title: 'Стороны не удалены'},
            ];
        },

        tabList() {
            return [
                {id: 0, tabName: 'Создать', value: 'create'},
                {id: 1, tabName: 'Прикрепить', value: 'attach'},
            ];
        },

    },

    methods: {
        onDelete() {
            if (this.isActiveKitsIdsEndEdit) {
                alert('В выборке маркеров присутствуют маркеры предложения, редактирование которого завершено');
            } else {
                this.isRemoveModal = true
            }
        },
        onMassEditModal() {
            if (this.isActiveKitsIdsEndEdit) {
                alert('В выборке маркеров присутствуют маркеры предложения, редактирование которого завершено');
            } else {
                ServiceConstructionData.getMassEditData((massEditData) => this.massEditData = massEditData);
                this.isMassEditModal = true;
            }
        },

        saveMassEdit(propId = '', propValue = '') {
            ServiceConstructionData.setMassEditData(this.selectedPriceIds, propId, propValue, (kitItemsIds) => this.$emit('saveMassEdit', kitItemsIds));
            this.onCloseModal();
        },

        onResize() {
            this.isMobile = window.innerWidth <= 988;
        },

        onSelectAction(value) {
            switch (value.id) {
                case 'createMaket':
                    this.createModalType =  'maket';
                    break;
                case 'createProject':
                    this.createModalType =  'project';
                    break;
                case 'createProposal':
                    this.createModalType =  'proposal';
                    break;
                case 'createKit':
                    this.createModalType =  'kit';
                    break;
                case 'massEdit':
                    this.isMassEditModal =  true;
                    break;
            }
            if(this.createModalType){
                ServicePriceGroup.getPgList(this.createModalType, (options) => {
                    this.modalOptions = options;
                });
            }
        },

        onChangeUserStatus(value){
            this.$emit("onChangeUserStatus", value.id);
        },

        onCloseModal() {
        this.modalStage = 'init';
          this.createModalType = '';
          this.isExporting = false;
          this.isShareModal = false;
          this.isMassEditModal = false;
          this.isRemoveModal = false;
          this.activeModalTab = this.tabList[0]?.value;
        },

        onMassRemove(){
            this.modalStage = 'wait';
            ServicePriceGroup.massDelete(this.selectedPriceIds, () => {
                this.$emit('refreshAllKits')
                this.modalStage = 'complete';
            }, (error) => {
                const {response = {}} = error;
                const {data: responseData = {}} = response;
                const {data = {}} = responseData;
                const {message = "Что то пошло не так"} = data;
                alert(message);
            });
        },

        onModifyPriceGroup(form){
            this.modalStage = 'wait';
            let {name = "", priceGroupId = -1} = form;
            switch (this.activeModalTab) {
                case "create":{
                    ServicePriceGroup.create(name, this.createModalType, this.selectedPriceIds, (priceGroup) => {
                        this.modalStage = 'complete';
                        this.$emit('refreshKit', priceGroup);
                    });
                    break;
                }
                case "attach":{
                    ServicePriceGroup.update(priceGroupId, this.selectedPriceIds, (priceGroup) => {
                        this.modalStage = 'complete';
                        this.$emit('refreshKit', priceGroup)
                    });
                    break;
                }
            }
        },

        changeFile(file = null) {
            this.file = file;
        },

        toggleTab(tabId = -1) {
            const activeTab = this.tabList.find((tab) => Number(tab.id) === Number(tabId));
            this.activeModalTab = activeTab?.value ?? this.tabList[0]?.value;
        },

        onMoveToPg(){
            this.$emit("onMoveToPg");
        },

        /**
         * Переключить отображение маркеров
         * @param {String} kitActionId - идентификатор события
         * @param {String} kitItemId - идентификатор набора
         */
        onToggleMarkers(kitActionId = '', kitItemId = '') {
            this.$emit("onToggleMarkers", kitActionId, kitItemId);
        },

        /**
         * Переключить отображение сводных данных набора
         * @param {String} kitActionId - идентификатор события
         * @param {String} kitItemId - идентификатор набора
         */
        onToggleSummary(kitActionId = '', kitItemId = ''){
            this.$emit("onToggleSummary", kitActionId, kitItemId);
        },

        /**
         * Экспорт набора эксель
         * @param {String} kitActionId - идентификатор события
         * @param {String} kitItemId - идентификатор набора
         */
        onExport(kitActionId = '', kitItemId = ''){
            this.$emit("onExport", kitActionId, kitItemId);
        },
        /**
         * Расчет эспара
         * @param {String} kitActionId - идентификатор события
         * @param {String} kitItemId - идентификатор набора
         */
        onToggleAdmetrix(kitActionId = '', kitItemId = ''){
            this.$emit("onToggleAdmetrix", kitActionId, kitItemId);
        },

        onToggleTable(kitActionId = '', kitItemId = '') {
            this.$emit("onToggleTable", kitActionId, kitItemId);
        }
    },
}
</script>

<style lang="scss" scoped>
$color-gray-hover: #F2F2F2;
$color-gray-text: #AFB0B4;
$color-green: #6EC87A;
.action-panel {
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-bottom: 8px;
        // border-bottom: 1px solid $color-gray-hover;

        &__toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            
            &-btn {
                height: 40px;
                width: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border: 1px solid transparent;
            width: 100%;
            cursor: pointer;
            transition: .2s;
            color: #000;
            font-weight: 400;
            background-color: transparent;
            box-sizing: border-box;

            &_disabled {
                color: $assistant-color
            }

            &:hover {
                background-color: $color-gray-hover;
            }

            &:focus {
                border: 1px solid $color-green;
                background-color: #fff;
                color: #000;
            }
        }

        &__actions-select {
            display: flex;

            & .select-preview {
                height: 36px;
            }
        }

        &__btn {
            position: relative;
        }
        &__select {
            position: relative;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        &__preloader {
            top: 0;
            left: 0;
            border-radius: 10px;
        }

        &__found-block,
        &__select-block,
        &__additional-block {
            display: flex;
            align-items: center;
        }

        &__found-block,
        &__select-block {
            position: relative;
            border: 1px solid #dddfe4;
            border-radius: 10px;
            padding: 0 20px 0 20px;
            @media screen and (max-width: 1024px) {
                padding: 0 10px;
            }

            &_loading {
                border: none;
            }

            height: 44px;
        }

        &__found-block,
        // &__additional-block,
        &__actions-block {
            margin-left: 20px;
        }

        &__select-block {
            margin-left: 10px;
        }

        // &__found-block,
        // &__select-block,
        // &__additional-block,
        // &__actions-block,
        &__status-block,
        &__toggle-block{
            display: flex;
            align-items: center;
        }

        &__status-block {
            flex-direction: column;
            align-items: flex-end;
            padding-right: 20px;
            padding-left: 40px;
            font-size: 11px;
            letter-spacing: 0;
            color: $color-gray-text;
        }

        // &__found-block,
        // &__select-block,
        // &__additional-block,
        // &__additional-block,
        // &__toggle-block{
        //     // border-right: 1px solid $color-gray-hover;
        // }

        // &__found-block,
        // &__select-block,
        &__toggle-block{
            padding: 0 10px 0 20px;
        }

        // &__additional-block,
        // &__actions-block {
        //     padding: 0 20px;
        // }

        // &__actions-block {
        //     flex-grow: 1;
        //     justify-content: space-between;
        // }

        &__found-block-text,
        &__select-block-text{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            // font-size: 14px;
            letter-spacing: 0;

            margin-right: 10px;

            position: relative;

            white-space: nowrap;
        }

        &__select-block-text.disabled,
        &__found-block-text.disabled {
            color: #ADB0BB;
        }

        &__found-block-value,
        &__select-block-value{
            font-weight: 700;
        }
    }
</style>
