export default {
	data() {
		return {
			isLoading: false,
			lastScrollTop: 0
		}
	},
	computed: {
		rowsLength() {
			return this.rows.length
		}
	},
	methods: {
		onLazyLoad(event) {
			if (this.lastScrollTop == event.currentTarget.scrollTop) { return }

            const currentTarget = event.currentTarget
            const {scrollTop, scrollHeight, clientHeight} = currentTarget
			const difference = scrollHeight - (clientHeight + scrollTop)

            if (difference < 400) {
				if (!this.isLoading) {
					this.$emit('lazyload')
				}
				this.isLoading = true
            }

			this.lastScrollTop = event.currentTarget.scrollTop
        }
	},
	watch: {
		rowsLength() {
			this.isLoading = false
		}
	}
}