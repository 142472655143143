<template>
    <div class="toolbar">
        <!-- <base-text-input
            view="primary"
            :styleObj="{height: '44px', width: '192px'}"
            placeholder="Найти адрес"
            class="toolbar__search"
        /> -->
        <toolbar-block
            v-for="(toolbarBlock, index) in toolbarBlocks"
            :key="index"
            :toolbarBlock="toolbarBlock"
            @onClickToolbarItem="onClickToolbarItem"
        />
    </div>
</template>

<script>
// import BaseTextInput from '@/components/Base/BaseTextInput';
import ToolbarBlock from './ToolbarBlock';
import MixinPanel from '../../mixins/MixinPanel';
import SchemeToolbar from "../../schemes/SchemeToolbar";
const {toolbarBlocks} = SchemeToolbar;
export default {
    name: 'Toolbar',
    components: {
        // BaseTextInput,
        ToolbarBlock
    },
    mixins: [MixinPanel],
    /**
     * Данные компонента
     * @property {Array} toolbarBlocks - блоки для тулбара
     */
    data: () => ({
        toolbarBlocks: toolbarBlocks
    }),
    computed: {
        /**
         * Элементы блоков тулбара
         * @returns {Array}
         */
        toolbarItems() {
            return this.getItems(this.toolbarBlocks);
        }
    },
    methods: {
        /**
         * Событие клика по элементу тулбара
         * @param {string} toolbarItemId - идентификатор элемента тулбара
         */
        onClickToolbarItem(toolbarItemId = '') {
            const emit = this.getItemEmit(toolbarItemId, this.toolbarItems);
            this.$emit(emit);
        },
    }
}
</script>

<style lang="scss" scoped>
    .toolbar {
        background: #FFFFFF;
        display: flex;
        padding: 8px;
        box-shadow: 2px 3px 20px #3b4a741a;
        border-radius: 10px;
        &__search {
            margin: 2px 8px 0 0;
        }
    }
</style>
