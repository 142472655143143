var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{staticClass:"poi-modal",on:{"close":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modalBody",fn:function(){return [(_vm.isLoading)?_c('div',{staticClass:"poi-modal__preloader"},[_c('BasePreloaderGifV2',{attrs:{"size":60}})],1):_vm._e(),(!_vm.isLoading)?[_c('div',{staticClass:"poi-modal__body",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.isOpenedRequestDropdown = false}}},[_c('h3',{staticClass:"poi-modal__header"},[_vm._v(" "+_vm._s(_vm.addGroupModal.textAddPoi)+" ")]),_c('div',{staticClass:"poi-modal__tabs"},_vm._l((_vm.poiTabsList),function(tab){return _c('div',{key:tab.id,class:[
                            'poi-modal__tabs--item',
                            { _active: _vm.activeTabId === tab.id } ],on:{"click":function($event){return _vm.onTabClickAction(tab.id)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0),_c(_vm.activeTabContent(),{tag:"component",attrs:{"requestItems":_vm.requestItems,"requestId":_vm.requestId,"requestError":_vm.requestError,"fileComposingType":_vm.fileComposingType},on:{"onCloseModal":_vm.onCloseModal,"onChangeFileComposingType":function($event){return _vm.onChangeFileComposingType($event)},"onAutoComplete":function($event){return _vm.onAutoComplete($event)},"onCreateNewGroup":function($event){return _vm.onApply($event, 'onCreateNewGroup')},"onCreateNewEmptyGroup":function($event){return _vm.onApply($event, 'onCreateNewGroup')},"onCreateNewMoiPoiGroup":function($event){return _vm.onApply($event, 'onCreateNewMoiPoiGroup')},"onCreateNewFileGroup":function($event){return _vm.onApply($event, 'onCreateNewFileGroup')}},scopedSlots:_vm._u([{key:"icons",fn:function(){return [_c('div',{staticClass:"poi-modal__row",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.isOpenedRequestDropdown = false}}},[_c('div',[_vm._v(" "+_vm._s(_vm.addGroupModal.textSelectColorAndIcon)+" ")]),_c('div',{staticClass:"poi-modal__row-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.color),expression:"color"}],class:[
                                        'poi-modal__color',
                                        {
                                            'poi-modal__color--active':
                                                !_vm.iconId && _vm.color,
                                        } ],attrs:{"type":"color","name":"color","id":"input-color"},domProps:{"value":(_vm.color)},on:{"focus":function($event){_vm.iconId = null},"input":function($event){if($event.target.composing){ return; }_vm.color=$event.target.value}}}),_c('div',{staticClass:"poi-modal__or-text"},[_vm._v("или")]),_c('div',{staticClass:"poi-modal__icons"},_vm._l((_vm.icons),function(icon,index){return _c('div',{key:index,staticClass:"poi-modal__icon-wrap",class:{
                                            'poi-modal__icon-wrap--active':
                                                String(_vm.iconId) ===
                                                String(icon.iconId),
                                        },on:{"click":function($event){return _vm.onSelectIcon(icon.iconId)}}},[_c('img',{attrs:{"src":icon.src,"alt":'Иконка ' + (index + 1)}})])}),0),_c('div',{staticClass:"poi-modal__file"},_vm._l((_vm.iconUploadErrors),function(iconUploadError){return _c('span',{key:iconUploadError,staticClass:"poi-modal__error"},[_vm._v(" "+_vm._s(iconUploadError)+" ")])}),0),_c('base-button',{class:[
                                        'poi-modal__attach-file-button',
                                        { margin: _vm.icons.length >= 4 } ],attrs:{"type":"button","view":"primary","role":"label"}},[_c('icon-clip'),_c('input',{attrs:{"type":"file","hidden":""},on:{"change":_vm.onAddFile}}),_vm._v(" "+_vm._s(_vm.addGroupModal.textAddFile)+" ")],1)],1)])]},proxy:true}],null,false,507464733)})],1)]:_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }