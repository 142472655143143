<template>
    <div>
        <div class="select-wrapper" v-for="select in selects" :key="select.id">
            <div class="select-label">
                {{ select.label }}
            </div>
            <BaseSelect
                :default-title="select.value || select.placeholder"
                :options="select.options"
                :isCanRemove="isCanRemove(select.options, select.id)"
                isCloseAfterCheck
                isFullWidth
                @check="onSelectAction($event, select)"
                @removeChecked="onRemoveChecked($event, select)"
            />
        </div>
    </div>
</template>

<script>
import BaseSelect from "@/components/Base/BaseSelect";

export default {
    components: {
        BaseSelect,
    },

    props: {
        selects: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        isCanRemove(options, selectId) {
            return options.every((option) => option.id === selectId);
        },

        onSelectAction(option, select) {
            this.$emit("onSelectAction", { ...option, id: select.id });
        },

        onRemoveChecked(option, select) {
            this.$emit("onRemoveChecked", { ...option, id: select.id });
        },
    },
};
</script>

<style lang="scss" scoped>
.select {
    &-wrapper {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    &-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 5px;
    }
}
</style>
