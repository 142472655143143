<template>
    <div class="budget">
        <h3 class="budget__title" @mousedown="$emit('mouseDown', $event)">Бюджет</h3>
        <div class="budget__flex-wrap">
            <h4 class="budget__sum">{{ params.budget }}</h4>
            <div class="budget__price-wrap" v-if="params.price">
                <div class="budget__price">
                    Прайс:  <span>{{ params.price }}</span>
                </div>
                <div v-if="params.discount" class="budget__discount">
                    Скидка:  <span>{{ params.discount }}</span>
                </div>
            </div>
        </div>

        <div class="budget__table">
            <div class="budget__table-row" v-if="params.agencyPrice">
                <div class="budget__table-col-name">Размещение</div>
                <div class="budget__table-col-value">{{params.agencyPrice}}</div>
            </div>
            <div class="budget__table-row" v-if="params.printPrice">
                <div class="budget__table-col-name">Печать</div>
                <div class="budget__table-col-value">{{params.printPrice}}</div>
            </div>
            <div class="budget__table-row" v-if="params.installPrice">
                <div class="budget__table-col-name">Монтаж</div>
                <div class="budget__table-col-value">{{params.installPrice}}</div>
            </div>
            <div class="budget__table-row" v-if="params.additionalPrintPrice">
                <div class="budget__table-col-name">Доп. монтаж</div>
                <div class="budget__table-col-value">{{params.additionalPrintPrice}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Budget",
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-border-table: #F2F2F2;
$color-gray-300: #AFB0B4;

.budget {
    background: #fff;
    border-radius: 10px;
    border: 1px solid $color-border;

    padding: 15px 20px 20px 20px;

    &__title {
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        cursor: move;
        padding: 5px 0;

        margin-bottom: 10px;
    }

    &__flex-wrap {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 10px;
    }

    &__sum {
        font-size: 16px;
        font-weight: 800;
    }

    &__price-wrap {
        text-align: right;
    }

    &__price,
    &__discount {
        font-size: 12px;
    }

    &__table {
        display: flex;
        flex-direction: column;
    }

    &__table-row {
        display: flex;
        justify-content: space-between;

        font-size: 12px;
        border-top: 1px solid $color-border-table;
        padding: 7px 0;
    }

    &__table-col-value {
        color: $color-gray-300;
    }
}
</style>
