<template>
    <BaseModal
        title="Массовое редактирование"
        :description="description"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="modal-mass-edit__section">
                <div class="modal-mass-edit__section-label">
                    Выберите параметр для редактирования
                </div>
                <BaseSelect
                    :canSearch="true"
                    :options="massEditData"
                    :value="propSelectedId"
                    :isBorder="true"
                    defaultTitle="Выберите значение"
                    style="width: 100%"
                    widthCustom="100%"
                    @check="onCheckProp"
                />
            </div>
            <div
                v-if="childComponentName !== ''"
                class="modal-mass-edit__section"
            >
                <div class="modal-mass-edit__section-label">
                    Введите значение для редактирования
                </div>
                <component
                    :is="childComponentName"
                    :key="propSelectedId"
                    v-bind="getChildComponentProps()"
                    @input="onInputValue"
                    @check="onCheckValue"
                />
            </div>
        </template>
        <template v-slot:actionButtons>
            <div class="modal-mass-edit__actions">
                <BaseButton
                    form="square"
                    view="border"
                    class="modal-mass-edit__action-btn"
                    @click="onCloseModal"
                >
                    Отменить
                </BaseButton>
                <BaseButton
                    form="square"
                    view="secondary"
                    class="modal-mass-edit__action-btn"
                    :disabled="!propValueValid"
                    @click="saveMassEdit"
                >
                    Сохранить
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../Base/BaseModal.vue';
import BaseSelect from '../Base/BaseSelect.vue';
import BaseTextInput from '../Base/BaseTextInput.vue';
import BaseButton from '../Base/BaseButton.vue';
export default {
    name: 'ModalMassEdit',
    components: {
        BaseModal,
        BaseSelect,
        BaseTextInput,
        BaseButton
    },
    props: {
        count: {
            type: Number,
            default: 0,
        },
        massEditData: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        propSelected: {},
        childComponentsProps: {
            BaseTextInput: {
                canClear: true,
                value: '',
                view: 'primary',
                style: {width: '100%'},
                isValidation: true,
                validationRule: () => true
            },
            BaseSelect: {
                canSearch: true,
                options: [],
                value: '',
                isBorder: true,
                defaultTitle: 'Выберите значение',
                style: {width: '100%'},
                widthCustom: "100%",
                isValidation: true,
                validationRule: () => true
            }
        },
        propValue: '',
        propValueValid: false,
    }),
    computed: {
        description() {
            return `Выбрано сторон: ${this.count}`;
        },
        propSelectedId() {
            return String(this.propSelected.id ?? '');
        },
        childComponentName() {
            return String(this.propSelected.componentName ?? '');
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal');
        },

        onCheckProp(prop = {}) {
            this.propValue = '';
            this.propValueValid = false;
            if (this.childComponentName !== '')
                this.childComponentsProps[this.childComponentName].value = '';
            this.propSelected = prop;
        },

        getChildComponentProps() {
            let childComponentProps = this.childComponentsProps[this.childComponentName] ?? {};
            childComponentProps.validationRule = this.propSelected.validationRule ?? (() => true);

            if (this.childComponentName === 'BaseSelect')
                childComponentProps.options = this.propSelected.values ?? [];

            return childComponentProps;
        },

        onInputValue(value = '', isValid = false) {
            this.propValue = String(value);
            this.propValueValid = Boolean(isValid);
            this.childComponentsProps[this.childComponentName].value = String(value);
        },

        onCheckValue(value = {}) {
            const {id = ''} = value;
            this.propValue = String(id);
            this.propValueValid = true;
            this.childComponentsProps[this.childComponentName].value = String(id);
        },

        saveMassEdit() {
            this.$emit('saveMassEdit', this.propSelectedId, this.propValue);
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal-mass-edit {
        &__section {
            margin-bottom: 20px;

            &-label {
                font-size: 12px;
                margin-bottom: 10px;
            }
        }

        &__actions {
            display: flex;
            justify-content: end;
            margin-top: 30px;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
</style>
