<template>
    <div class="kit-shared-panel">
        <template v-if="isMobile">
            <MobileDropdownMenu
                :actions="availableActionsMobile"
                @onClickActionNative="onClickKitAction" 
            />
        </template>
        <template v-else>
            <BaseButtonIcon
                v-for="(action, index) in availableActions"
                :isDisabled="!kitItems.length"
                :key="index"
                :tooltip-text="action.desc"
                :isActive="isActionActive(action.id)"
                :iconName="getIconName(action.icon)"
                :iconComponent="action.iconComponent || ''"
                @onBaseButtonIcon="onClickKitAction(action)"
            />
        </template>
    </div>
</template>

<script>
    import BaseButtonIcon from "../Base/BaseButtonIcon";
    import MobileDropdownMenu from '../Mobile/MobileDropdown/MobileDropdownMenu.vue';
    export default {
        name: "KitSharedPanel",
        components: {
            BaseButtonIcon,
            MobileDropdownMenu
        },
        props: {
            kitItems: {
                type: Array,
                default: () => ([])
            },
            kitActions: {
                type: Array,
                default: () => ([])
            },
            isMobile: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            sharedKit(){
                return this.kitItems.length ? this.kitItems[0] : {};
            },
            availableActions(){
                return this.kitActions.filter(action => this.isAvailable(action))
            },
            availableActionsMobile() {
                return this.availableActions.map(action => {
                    const {desc = ''} = action;
                    const actionMobile = {
                        ...action,
                        value: String(desc),
                        isMulti: false,
                        isCanShare: true,
                    };

                    return actionMobile;
                });
            }
        },
        methods: {
            /**
             * Проверка на доступность действия при расшаривании
             * @param {Object} action действие
             */
            isAvailable(action){
                const {isGuest = true} = action;
                return isGuest;
            },
            /**
             * Получение названия иконки
             * @param {String} className - класс иконки
             */
            getIconName(className){
                return className ? className.replace('icon-', '') : '';
            },
            /**
             * Является ли данное действие активным в панели наборов
             * @returns {Boolean}
             */
            isActionActive(action) {
                let kitActions = this.getKitItemProp('actionsActive') ?? [];
                return kitActions.includes(action);
            },
            /**
             * Получить свойство объекта набора
             * @param {String} propKey - ключ объекта набора
             * @returns {String|Number|Object}
             */
            getKitItemProp(propKey = '') {
                return this.sharedKit[propKey] ?? null;
            },
            /**
             * Срабатывает при нажатии на кнопку
             * @param action
             */
            onClickKitAction(action){
                this.$emit(action.emitName, action.id, this.sharedKit.id);
            }
        }
    }
</script>

<style scoped>
    .kit-shared-panel{
        display: inherit;
    }
</style>
