<template>
    <div class="draggable-zone">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "DropZone",
}
</script>

<style lang="scss" scoped>
.draggable-zone {
    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: 600px;
    //height: 100vh;

    overflow: hidden;

    margin: 0 auto;
    padding: 0;
}
</style>
