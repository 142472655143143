<template>
    <Folder
        :id="kitItemId"
        :title="kitItemName"
        :color="kitItemColor"
        :width="calculateFolderWidth('kit', folderSize, isMobile)"
        :height="calculateFolderHeight('kit', folderSize, isMobile)"
        :left="getPositionFolderKit(kitItemId, 'left', folderSize)"
        :top="getPositionFolderKit(kitItemId, 'top', folderSize)"
        :isMobile="isMobile"
        @click.native="onClickFolder"
    >
        <template v-slot:panel>
            <FolderControlPanel
                :isFull="!isMobile"
                :isFifty="!isMobile"
                :isColumnsSettings="true"
                @onFullSize="onFullSize"
                @onFiftySize="onFiftySize"
                @onCloseFolder="onCloseFolder"
                @onColumnsSettings="onColumnsSettings"
            />
        </template>
        <template v-slot:content>
            <!-- <TableKit :columns="kitTableColumnsBase"/> -->
            <KitTab
                :isProgressBarLoading="isLoading"
                :folderId="kitItemId"
                :columns="columnsWithStyles"
                :tableData="rows"
                :canCheck="true"
                :rowsSelected="rowsSelected"
                :kitStatusColors="kitStatusColors"
                :style="{'height': '100%'}"
                @filter="onFilterTable"
                @sort="onSortTable"
                @lazyload="onLazyloadTable"
                @onCheckRow="onCheckRowTable"
                @onCheckRowsAll="onCheckRowsAllTable"
                @onSelectStatus="onSelectStatus"
                @editCell="onEditCell"
                @onClickCell="onClickCell"
                @resizeColumn="onResizeColumn"
            />
        </template>
    </Folder>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Folder from '../components/Folder/Folder.vue';
import FolderControlPanel from '../components/Folder/FolderControlPanel.vue';
// import TableKit from '../components/Tables/TableKit.vue';
import KitTab from '../components/HandbookComponents/KitTab.vue';
import MixinKitFolder from '@/mixins/MixinsPageBayuerdesk/MixinKitFolder';
import ServiceMapTable from '../services/ServicesMap/ServiceMapTable';
import UtilNumbers from '../utils/UtilNumbers';

export default {
    name: 'LayoutTablesKit',
    components: {
        Folder,
        FolderControlPanel,
        // TableKit,
        KitTab
    },
    mixins: [MixinKitFolder],
    provide() {
        return { getEditTableValues: this.getEditTableValues }
    },
    props: {
        kitItemId: {
            type: String,
            default: ''
        },
        kitItemName: {
            type: String,
            default: ''
        },
        kitItemColor: {
            type: String,
            default: ''
        },
        kitStatusColors: {
            type: Object,
            default: () => ({})
        },
        kitItemFinishedAt: {
            type: [Object, String],
            default: () => null
        }
    },
    data: () => ({
        columns: [],
        columnsWithStyles: [],
        rows: [],
        isLoading: false,
        pageCurrent: -1,
        pageCount: 1,
        filter: {},
        sort: {}
    }),
    computed: {
        ...mapState({
            selectedSides: state => state.selection.selectedSides,
            isMobile: state => state.isMobile,
        }),

        rowsSelected() {
            const rowsSelected = this.rows.filter(row => {
                const {id = ''} = row;
                const index = this.selectedSides.findIndex(side => {
                    const {sideId = '', kitItemId = ''} = side;
                    return String(sideId) === String(id) && String(this.kitItemId) === String(kitItemId);
                })
                return index !== -1;
            }).map(row => String(row?.id));

            return rowsSelected;
        },

        tableFilterData() {
            return {
                filter: {...this.filter},
                sort: {...this.sort}
            };
        },
    },
    watch: {
        filter() {
            this.$emit('changeFilterTable', this.kitItemId, this.filter);
        },
        columns() {
            this.updateTableColumnsStyles();
        }
    },
    mounted() {
        this.updateTableColumnsStyles();
    },
    methods: {
        ...mapMutations('folders', ['toggleResizeFolder']),

        updateTableColumnsStyles() {
            const columnsWidth = ServiceMapTable.getColumnsWidth();
            const columnsWithWidth = this.columns.map(column => {
                const columnProp = String(column?.prop ?? '');
                let columnWithStyles = { ...column };

                if (columnsWidth[columnProp]) {
                    columnWithStyles.columnStyles = {
                        ...columnWithStyles?.columnStyles,
                        width: String(columnsWidth[columnProp])
                    }
                }

                return columnWithStyles;
            });

            const columnsWithSticky = columnsWithWidth.map(column => {
                const isSticky = Boolean(column?.isSticky ?? false);
                let columnWithSticky = { ...column };

                if (isSticky) {
                    const columnStickyPosition = parseInt(column?.stickyPosition ?? 0);
                    const columnsPrevBySticky = columnsWithWidth.filter(columnPrev => columnPrev.stickyPosition && columnPrev.stickyPosition < columnStickyPosition);

                    const columnPrevWidth = columnsPrevBySticky.reduce((columnPrevWidth, columnPrevBySticky) => {
                        const columnWidth = parseInt(String(columnPrevBySticky?.columnStyles?.width ?? '0').replace(/\D/g, ''));

                        return `${parseInt(String(columnPrevWidth).replace(/\D/g, '')) + columnWidth}px`;
                    }, '54px');

                    columnWithSticky.columnStyles = {
                        ...columnWithSticky.columnStyles,
                        left: columnPrevWidth
                    };
                }

                return columnWithSticky;
            });

            this.columnsWithStyles = [...columnsWithSticky];
        },

        async getKitTable() {
            if (this.pageCurrent < this.pageCount - 1) {
                this.isLoading = true;
                this.pageCurrent += 1;
                await ServiceMapTable.getMapTable(this.pageCurrent, this.kitItemId, this.tableFilterData, (rows, columns, pageCount) => {
                    this.columns = [...columns];
                    this.rows.push(...rows);
                    this.pageCount = pageCount;
                });
                this.isLoading = false;
            }
        },

        async editKitTableStatus(statusSelectedData = {}) {
            if (this.kitItemFinishedAt !== null) {
                alert('Вы завершили редактирование, сменить статус нельзя');
            } else {
                const {entityName, attribute, priceId, newValue, rowData, columnProp} = statusSelectedData;

                await ServiceMapTable.editMapTableStatus(entityName, priceId, attribute, newValue, (statusData) => {
                    const {id: rowId = ''} = rowData;
                    const newRowData = {
                        ...rowData,
                        [columnProp]: {
                            ...rowData[columnProp],
                            value: String(statusData?.value ?? ''),
                            defaultTitle: String(statusData?.label ?? '')
                        }
                    };

                    const rowIndex = this.rows.findIndex(row => String(row?.id) === String(rowId));
                    this.rows.splice(rowIndex, 1, newRowData);

                    this.$emit('onSelectStatus', statusSelectedData, this.kitItemId);
                });
            }
        },

        async editCellTable(cellEditableData = {}) {
            const entityName = String(cellEditableData?.entityName ?? '');
            const attribute = entityName === 'nmp_price' ? String(cellEditableData?.prop ?? '').split('__')[0] ?? '' : String(cellEditableData?.prop ?? '');
            const entityId = entityName === 'nmp_price' ? String(cellEditableData?.priceId ?? '') : String(cellEditableData?.rowId ?? '');
            const value = String(cellEditableData?.value ?? '');

            await ServiceMapTable.editTable(entityName, attribute, entityId, value);

            const rowIndex = this.rows.findIndex(row => String(row?.id) === String(cellEditableData?.rowId ?? ''));

            if (rowIndex !== -1) {
                let newRowData = { ...this.rows[rowIndex] };

                if (String(cellEditableData?.type ?? '') === 'text')
                    newRowData[cellEditableData?.prop ?? ''] = entityName === 'nmp_price' ? UtilNumbers.toPrice(cellEditableData?.value ?? '') : String(cellEditableData?.value ?? '');
                else
                    newRowData[cellEditableData?.prop ?? ''] = String(cellEditableData?.valueLabel ?? '');

                if (entityName === 'nmp_price') {
                    const agencyPriceTotal = Object.keys(newRowData).filter(newRowDataKey => {
                        return String(newRowDataKey).split('__')[0] === 'agency_price'
                    }).reduce((agencyPriceTotal, newRowDataKey) => {
                        agencyPriceTotal += parseInt(String(newRowData[newRowDataKey]).replace(/[^\d]/g, ""));

                        return agencyPriceTotal
                    }, 0);

                    const priceTotal = Object.keys(newRowData).filter(newRowDataKey => {
                        return String(newRowDataKey).split('__')[0] === 'price'
                    }).reduce((priceTotal, newRowDataKey) => {
                        priceTotal += parseInt(String(newRowData[newRowDataKey]).replace(/[^\d]/g, ""));

                        return priceTotal
                    }, 0);

                    const discountKeys = Object.keys(newRowData).filter(newRowDataKey => String(newRowDataKey).split('__')[0] === 'discount');

                    newRowData.total_agency_price = UtilNumbers.toPrice(agencyPriceTotal);
                    newRowData.total_price = UtilNumbers.toPrice(priceTotal);

                    discountKeys.forEach(discountKey => {
                        const priceRow = String(newRowData[`price__${ discountKey.split('__')[1] ?? '' }`] ?? '0');
                        const price = Number(priceRow.replace(/[^\d]/g, ""));

                        const agencyPriceRow = String(newRowData[`agency_price__${ discountKey.split('__')[1] ?? '' }`] ?? '0');
                        const agencyPrice = Number(agencyPriceRow.replace(/[^\d]/g, ""));

                        const discount = price > 0 ? (price - agencyPrice) / price : 0;

                        newRowData[discountKey] = UtilNumbers.toDiscount(discount);
                    });
                }

                this.rows.splice(rowIndex, 1, newRowData);
            }
        },

        onSelectStatus(statusSelectedData = {}) {
            this.editKitTableStatus(statusSelectedData);
        },

        onEditCell(cellEditableData = {}) {
            this.editCellTable(cellEditableData);
        },

        onClickCell(tableData = {}) {
            this.$emit('clickCell', tableData);
        },

        onColumnsSettings() {
            this.$emit('onColumnsSettings');
        },

        onFilterTable(dataFilter = {}) {
            this.filter = {...dataFilter};

            this.onFilterTableAfter();
        },

        onSortTable(dataSort = {}) {
            const {type = '', key = ''} = dataSort;
            this.sort = {
                attribute: String(key),
                value: String(type)
            };

            this.onFilterTableAfter();
        },

        onFilterTableAfter() {
            this.clearData(false);
            this.getKitTable();
        },

        onLazyloadTable() {
            this.getKitTable();
        },

        onCheckRowTable(checkData = {}) {
            const {row = {}} = checkData;
            const {id: rowId = '', extraData: {marker_id: markerId = ''} = {}} = row;
            this.$emit('onCheckRowTable', this.kitItemId, String(rowId), String(markerId));
        },

        // eslint-disable-next-line no-unused-vars
        onCheckRowsAllTable(isSelected = false) {
            const rows = this.rows.filter(row => isSelected ? !this.rowsSelected.includes(String(row?.id)) : this.rowsSelected.includes(String(row?.id)));
            this.$emit('onCheckRowsAllTable', this.kitItemId, rows);
        },

        onClickFolder() {
            this.bringToFront(this.kitItemId);
        },

        /**
         * Расширить на все окно
         */
        onFullSize() {
            this.toggleResizeFolder({ id: this.kitItemId, resize: 'full' });
        },

        /**
         * Расширить на половину ширины окна
         */
        onFiftySize() {
            this.toggleResizeFolder({ id: this.kitItemId, resize: 'fifty' });
        },

        onCloseFolder() {
            this.toggleFolder(this.kitItemId);
            this.clearData(false);
            this.$emit('onCloseTable', this.kitItemId);
        },

        toggleTable() {
            this.toggleFolder(this.kitItemId, 'custom');

            if (!this.kitsFolderIdsActive.includes(this.kitItemId))
                this.clearData(false);
            else
                this.getKitTable();
        },

        updateTable() {
            if (this.kitsFolderIdsActive.includes(this.kitItemId)) {
                this.clearData(false);
                this.getKitTable();
            }
        },

        clearData(isClearFilter = true) {
            this.rows = [];
            this.pageCurrent = -1;
            this.pageCount = 1;

            if (isClearFilter) {
                this.filter = {};
                this.sort = {};
            }
        },

        async getEditTableValues(entityName = '', attribute = '') {
            const resultAttribute = entityName === 'nmp_price' ? String(attribute ?? '').split('__')[0] ?? '' : String(attribute ?? '')
            const editTableValues = await ServiceMapTable.getEditValues(entityName, resultAttribute);

            return editTableValues;
        },

        onResizeColumn(columnProp = '', columnWidth = '') {
            ServiceMapTable.setColumnWidth(columnProp, columnWidth);

            this.updateTableColumnsStyles();
        }
    }
}
</script>
