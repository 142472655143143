<template>
    <BaseButtonIcon
        :icon-name="panelItemIcon"
        :tooltip-text="panelItemDescription"
        :is-active="isActive"
        class="control-panel-item"
        @onBaseButtonIcon="onClickPanelItem"
    />
</template>

<script>
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";

export default {
    name: "ControlPanelItem",
    components: {
        BaseButtonIcon,
    },
    /**
     * Входные данные
     * @property {Object} panelItem - элемент панели
     * @property {boolean} isActive - активность элемента панели
     */
    props: {
        panelItem: {
            type: Object,
            default: () => ({})
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Идентификатор элемента панели
         * @returns {String}
         */
        panelItemId() {
            let {id = ''} = this.panelItem;
            return String(id);
        },
        /**
         * Описание элемента панели
         * @returns {string}
         */
        panelItemDescription() {
            const {description = ''} = this.panelItem;
            return String(description);
        },
        /**
         * Иконка панели
         * @returns {string}
         */
        panelItemIcon() {
            let {icon = ''} = this.panelItem;
            return String(icon);
        },
    },
    methods: {
        /**
         * Событие клика по элементу панели
         */
        onClickPanelItem() {
            this.$emit('onClickPanelItem', this.panelItemId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel-item {
        margin: 1px 1px 0 0 !important;

        &:last-child {
            margin-right: 0 !important;
        }
    }
</style>
