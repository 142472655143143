<template>
    <base-modal
        :title="'Отправить предложение'"
        @close="onClose"
    >
        <template v-slot:modalBody>
            <div class="send-kit__body-wrap">
                <BaseTextInput
                    class="send-kit__input-text"
                    id="field_name"
                    maxlength="70"
                    view="primary"
                    :value="email"
                    :placeholder="'Введите Email'"
                    @input="onInputName"
                />
                <div v-if="error" class="send-kit__error">
                    {{error}}
                </div>

                <div class="send-kit__actions">
                    <base-button
                        class="send-kit__action-btn"
                        form="square"
                        view="border"
                        @click="onClose"
                    >Отменить</base-button
                    >
                    <base-button
                        form="square"
                        view="secondary"
                        :disabled="email === ''"
                        @click="onSend"
                    >Отправить</base-button
                    >
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>

import BaseModal from "@/components/Base/BaseModal";
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from '@/components/Base/BaseTextInput';
import ServicePriceGroup from "../../services/servicesPriceGroup/ServicePriceGroup";
export default {
    name: 'SendKit',
    components: {
        BaseModal,
        BaseButton,
        BaseTextInput
    },
    /**
     * Входные данные
     * @property {Number|String} sendKitId - id набора
     */
    props: {
        sendKitId: {
            type: [Number,String],
            default: null
        }
    },
    /**
     * Локальные данные
     * @property {String} formType - режим работы с шаблоном
     * @property {Object} bufferCurrentTemplate - текущий шаблон
     */
    data() {
        return {
            email: "",
            error: ""
        }
    },
    mounted() {

    },
    watch:{

    },
    computed: {

    },
    methods:{
        onClose(){
            this.$emit('onCloseModal');
        },
        onInputName(value){
            this.error = ""
            this.email = value;
        },
        onSend(){
            console.log(this.email, this.sendKitId)
            this.error = ""
            ServicePriceGroup.send(this.sendKitId, this.email, () => {
                this.onClose()
            }, (error) => {
                this.error = error;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    $color-green: #6ec87a;
    $color-gray-light: #f2f2f2;
    $color-gray: #afb0b4;
    $color-blue: #4a92f6;
    $color-red: #ff8787;
    .send-kit {
        padding: 0;

        &__select .select-preview {
            background-color: $color-gray-light;
            color: $color-gray;
            justify-content: space-between;

            &:active,
            &:focus {
                background-color: #fff;
                color: #000;
            }
        }

        &__body-wrap {
            margin-top: 30px;
        }

        &__body-tabs {
            margin-bottom: 10px;
        }

        &__error {
            display: flex;
            align-items: center;
            color: $color-red;
            font-size: 13px;
        }

        &__label-icon {
            font-size: 11px;
            font-weight: 400;
            color: $color-gray;
            margin-left: 4px;
            cursor: pointer;

            &:hover {
                color: #333;
            }
        }

        &__input-text {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        &__input-file {
            margin-top: 5px;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            margin-top: 30px;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__action-alt-btn {
            cursor: pointer;
            color: $color-blue;

            &:not(:last-child) {
                margin-right: 30px;
            }

            &:hover {
                color: #000;
            }
        }

        &__select {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        &__attach-image-block {
            font-size: 12px;
        }

        &__attach-image-text {
            display: block;
            margin-bottom: 5px;
        }

        &__attach-image {
            width: 135px;
            height: 90px;
            border-radius: 10px;
        }

        &__load-wrap {
            background-color: $color-gray-light;
            height: 20px;
            border-radius: 4px;
            margin-top: 5px;
        }

        &__load-fill {
            display: block;
            width: 0;
            height: inherit;
            background-color: $color-green;
            animation: loading 1.5s ease-in-out infinite;

            @keyframes loading {
                from {
                    width: 0;
                }

                to {
                    width: 100%;
                }
            }
        }
    }
</style>
