<template>
    <div>
        <div class="poi-modal__row">
            <p class="poi-modal__text">
                {{ addGroupModal.textQueryName }}
                <span style="color: red">*</span>
            </p>
            <poi-select-multiple
                :options="requestItems"
                :value="selectedRequestItemIds"
                :isLoadingDropdown="isLoadingRequest"
                :isOpenedDropdown="isOpenedRequestDropdown"
                :requestError="requestError"
                :canSearch="true"
                :placeholder="addGroupModal.textQueryNamePlaceholder"
                @input="onInput"
                @onInputLazy="onInputLazy"
                @check="onSelectRequestIds"
            />
        </div>

        <div
            @click.self="isOpenedRequestDropdown = false"
            class="poi-modal__row"
        >
            <p class="poi-modal__text">
                {{ addGroupModal.textGroupName }}
                <span style="color: red">*</span>
            </p>
            <base-text-input
                view="primary"
                :placeholder="addGroupModal.textGroupNamePlaceholder"
                v-model.trim="groupName"
            />
        </div>

        <slot name="icons" />

        <div class="poi-modal__actions">
            <base-button
                class="poi-modal__cancel"
                form="square"
                view="border"
                @click="onCloseModal"
                >{{ addGroupModal.textCancel }}</base-button
            >
            <base-button
                form="square"
                view="secondary"
                :disabled="isDisabled"
                @click="onApply"
                >{{ addGroupModal.textApply }}</base-button
            >
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from "@/components/Base/BaseTextInput";
import PoiSelectMultiple from "../MapV2/components/ContainerPoi/PoiSelectMultiple";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

export default {
    name: "AddPoiModalContent",
    components: {
        BaseTextInput,
        BaseButton,
        PoiSelectMultiple,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestItems: {
            type: Array,
            default: () => [],
        },
        requestId: {
            type: Number,
            default: null,
        },
        requestError: {
            type: String,
            default: "",
        },
        isLoadingRequest: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        addGroupModal: containerPoi.addGroupModal,
        groupName: "",
        requestQuery: "",
        selectedRequestItemIds: [],
        isOpenedRequestDropdown: false,
    }),

    computed: {
        isDisabled() {
            return (
                this.groupName.length === 0 ||
                this.requestId === null ||
                this.requestId === 0 ||
                !this.selectedRequestItemIds.length
            );
        },
    },

    methods: {
        onInput(value) {
            this.selectedRequestItemIds = [];
            if (this.requestQuery === this.groupName) {
                this.groupName = value;
            }
            this.requestQuery = value;
        },
        onInputLazy(value) {
            this.isOpenedRequestDropdown = true;
            this.$emit("onAutoComplete", value);
        },
        onSelectRequestIds(values) {
            this.isOpenedRequestDropdown = false;
            this.selectedRequestItemIds = values;
        },
        onApply() {
            const requestCreateGroupParams = {
                name: this.groupName,
                request_id: this.requestId,
                request_item_ids: this.selectedRequestItemIds,
            };

            this.$emit("onCreateNewGroup", requestCreateGroupParams);
        },
        onCloseModal() {
            this.$emit("onCloseModal");
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    width: 100%;
}
</style>
