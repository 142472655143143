import Vue from 'vue'
import Vuex from 'vuex'

import filter from './modules/filter'
import selection from './modules/selection'
import folders from './modules/folders'
import kit from './modules/kit';
import pageBayuerdesk from './modules/pageBayuerdesk';
import auth from './modules/auth';
import mapWidget from './modules/mapWidget';
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		filter,
		folders,
        kit,
        pageBayuerdesk,
        auth,
        selection,
        mapWidget
	},
    state: {
        currentComponent: '',
        isMobile: false
    },
    mutations: {
        setCurrentComponent(state, currentComponent = '') {
            state.currentComponent = currentComponent;
        },
        setIsMobile(state, isMobile = false) {
            state.isMobile = Boolean(isMobile);
        }
    }
})
