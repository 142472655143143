export default {
    namespaced: true,
    state: {
        filterSelectedData: {},
        withoutPricesStepCount: 0,
        withoutPricesSideCount: 0,
        isFilterLoading: false,
    },
    mutations: {
        setFilterSelectedData(state, filterSelectedData) {
            state.filterSelectedData = filterSelectedData;
        },
        setWithoutPricesStepCount(state, withoutPricesStepCount) {
            state.withoutPricesStepCount = withoutPricesStepCount;
        },
        setWithoutPricesSideCount(state, withoutPricesSideCount) {
            state.withoutPricesSideCount = withoutPricesSideCount;
        },
        setIsFilterLoading(state, isFilterLoading) {
            state.isFilterLoading = isFilterLoading;
        }
    }
}
