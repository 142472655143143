<template>
    <div
        :class="{'notification--vertical': orientation === 'vertical'}"
        class="notification"
        :style="{'--bg-color': getBackgroundColor}">
        <span class="notification__icon">
            <img v-if="type === 'success'" width="16" height="16" src="@/assets/images/icons/success.svg" alt="notification icon">
            <img v-if="type === 'warn'" width="16" height="16" src="@/assets/images/icons/warn.svg" alt="notification icon">
        </span>
        <span class="notification__text" v-html="text"></span>
    </div>
</template>

<script>
export default {
    name: "Notification",
    props: {
        text: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'success',
            validator: (val) => ['success', 'warn'].includes(val)
        },
        orientation: {
            type: String,
            default: 'horizontal',
            validator: (val) => ['horizontal', 'vertical'].includes(val)
        },
    },

    computed: {
        getBackgroundColor() {
            let color = '';

            switch (this.type) {
                case 'success':
                    color = '#D2F6D7';
                    break;
                case 'warn':
                    color = '#FFFFD5';
                    break;
            }

            return color;
        },
    }
}
</script>

<style lang="scss" scoped>
$success-color: #D2F6D7;
    .notification {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        background-color: var(--bg-color);

        &--vertical {
            display: flex;
            flex-direction: column;
            align-items: center;

            .notification__icon {
                margin-top: 0;
                margin-bottom: 5px;
            }

            .notification__text {
                display: block;
                font-size: 10px;
                text-align: center;
                margin-left: 0;
            }
        }

        &__icon {
            display: block;
            margin-top: 2px;
        }

        &__text {
            display: block;
            font-size: 12px;
            color: #000;
            margin-left: 10px;
        }
    }
</style>
