<template>
    <LinceMapWrapper
        ref="linceMapWrapper"
        :driver="driver"
        :renderDomElementId="renderDomElementId"
        :apiKeys="mapApiKeys"
        :clusteringServerParams="clusteringServerParams"
        :markerIconHost="markerIconHost"
        :mapParams="mapParams"
        :searchItemsApproxed="searchItemsApproxed"
        :panelItemsVisible="panelItemsVisible"
        :mapModeSelected="mapModeSelected"
        :legends="legendsMap"
        :legendConfig="legendConfig"
        :isLoading="isLoadingLegend"
        :isLoadingToolbar="isLoadingToolbar"
        :heatmapList="heatmapList"
        :heatmapData="heatmapData"
        :positionLegendColorPanel="positionLegendColorPanelMap"
        class="lince-map-wrapper"
        @onInitMap="onInitMap"
        @onReadyMap="onReadyMap"
        @onToggleDriver="onToggleDriver"
        @onInputSearch="onInputSearch"
        @onSelectItemSearch="onSelectItemSearch"
        @onClickMarker="onClickMarker"
        @onAfterCreatePolygon="onAfterCreatePolygon"
        @onToggleSelectMode="onToggleSelectMode"
        @onToggleViewMode="onToggleViewMode"
        @onToggleMassSelectMode="onToggleMassSelectMode"
        @onToggleDirectionEditMode="onToggleDirectionEditMode"
        @onToggleDirectionAngleView="onToggleDirectionAngleView"
        @onToggleDirectionFlowSide="onToggleDirectionFlowSide"
        @toggleOpn="onToggleOpn"
        @onSelectLegendGroup="onSelectLegendGroup"
        @onToggleLegendItem="onToggleLegendItem"
        @onSelectLegendColor="onLegendSelectColor"
        @onSelectLegendFigure="onLegendSelectFigure"
        @onFetchHeatmapList="onFetchHeatmapList"
        @onFetchHeatmapData="onFetchHeatmapData"
        @onMapClick="onMapClick"
        @moveLegend="onMoveLegend"
        @moveLegendColorPanel="onMoveLegendColorPanel"
    />
</template>

<script>
/** Компонент для отображения глобального компонента карты */
import ServiceMap from '../../services/ServicesMap/ServiceMap';
import ServiceMapLegends from '../../services/ServicesMap/ServiceMapLegends';
import ServiceToken from '../../services/ServicesAuth/ServiceToken';
import SchemeMap from '../../schemes/SchemeMap';
const {panelItemsVisible} = SchemeMap;

export default {
    name: 'LinceMapView',
    /**
     * Входные данные компонента
     * @property {Object} legends - легенды карты
     * @property {Object} legends[] - легенда карты
     * @property {String} legends[].title - название шапки
     * @property {Array<Object>} legends[].groups - группы легенды
     * @property {String} legends[].groups[].id - id группы
     * @property {String} legends[].groups[].value - значение группы
     * @property {String} legends[].groupSelected - выбранная группа
     * @property {Object} legends[].items - элементы легенды
     * @property {Array<Object>} legends[].items[] - элементы легенды по группе
     * @property {String} legends[].items[][].id - id элемента легенды
     * @property {String} legends[].items[][].title - заголовок элемента легенды
     * @property {String} legends[].items[][].color - цвет элемента легенды
     * @property {Number} legends[].items[][].count - колличество сторон элемента легенды
     * @property {Boolean} legends[].items[].active - активность элемента легенды
     * @property {Boolean} isLoadingLegend
     */
    props: {
        legends: {
            type: Object,
            default: () => ({})
        },
        isLoadingLegend: {
            type: Boolean,
            default: false
        },
        legendConfig: {
            type: Object,
            default: () => ({})
        },
        heatmapList: {
            type: Array,
            default: () => ([])
        },
        heatmapData: {
            type: Array,
            default: () => ([])
        },
    },
    /**
     * Данные компонента
     * @returns {String} driver - движок карты
     * @returns {String} renderDomElementId - id dom элемента куда будет рендериться карта
     * @returns {Object} mapApiKeys - ключи для апи карт
     * @returns {String} mapApiKeys.google - ключ для гугл карты
     * @returns {String} mapApiKeys.yandex - ключ для яндекс карты
     * @returns {Object} mapParams - параметры карты
     * @returns {Object} mapParams.center - центр карты
     * @returns {Number} mapParams.center.lat - широта центра карты
     * @returns {Number} mapParams.center.lng - долгота центра карты
     * @returns {Number} mapParams.zoom - значение зума карты
     * @returns {String} markerIconHost - host для получения иконки маркера
     * @returns {Array<Object>} searchItemsApproxed - результат поиска по карте
     * @returns {String} searchItemsApproxed[].id - id значения результат поиска по карте
     * @returns {String} searchItemsApproxed[].value - строка с адресом значения результат поиска по карте
     * @returns {String} searchItemsApproxed[].lat - широта адреса значения результат поиска по карте
     * @returns {String} searchItemsApproxed[].lng - долгота адреса значения результат поиска по карте
     * @returns {Array<String>} panelItemsVisible - конфиг видимых элементов панели управления
     * @returns {String} mapModeSelected - выбранный режим выбора маркеров
     */
    data: () => ({
        driver: 'google',
        renderDomElementId: 'map-wrapper',
        mapApiKeys: {
            google: process.env.VUE_APP_MAP_API_GOOGLE,
            yandex: process.env.VUE_APP_MAP_API_YANDEX
        },
        markerIconHost: process.env.VUE_APP_FREE_BACKEND_HOST,
        clusteringServerHost: process.env.VUE_APP_API_MAP_HOST,
        clusteringServerRoute: '/main/cluster-get',
        apiAuthToken: ServiceToken.productToken,
        mapParams: {
            center: {lat: 55.7522200, lng: 37.6155600},
            zoom: 8,
        },
        searchItemsApproxed: [],
        panelItemsVisibleScheme: {...panelItemsVisible},
        mapModeSelected: 'selection',
        isLoadingToolbar: false,
        positionLegends: {}
    }),
    computed: {
        panelItemsVisible() {
            return this.panelItemsVisibleScheme[this.driver] ?? [];
        },
        clusteringServerParams() {
            return {
                apiAuthToken: this.apiAuthToken,
                clusteringServerHost: this.clusteringServerHost,
                clusteringServerRoute: this.clusteringServerRoute
            };
        },
        legendsMap() {
            return Object.keys(this.legends).reduce((legendsMap, legendKey) => {
                const position = this.positionLegends[legendKey] ?? {};
                legendsMap[legendKey] = {
                    ...this.legends[legendKey],
                    position
                }

                return legendsMap;
            }, {});
        },
        positionLegendColorPanelMap() {
            return this.positionLegends?.colorPanel ?? {};
        }
    },
    beforeMount() {
        /** Перед монтированием получаем движок карты */
        this.driver = ServiceMap.getMapDriver();
    },
    mounted() {
        if (!this.heatmapList.length) {
            this.panelItemsVisibleScheme[this.driver]
                = this.panelItemsVisibleScheme[this.driver].filter(item => item !== 'heatmap')
        }

        this.getPositionLegends();
    },
    methods: {
        getPositionLegends() {
            this.positionLegends = ServiceMapLegends.getPositionLegends();
        },
        onMoveLegend(legendKey = '', leftPosition = '', topPosition = '') {
            this.positionLegends = {
                ...this.positionLegends,
                [legendKey]: {
                    top: String(topPosition),
                    left: String(leftPosition)
                }
            };

            ServiceMapLegends.setPositionLegends(this.positionLegends);
        },
        onMoveLegendColorPanel(leftPosition = '', topPosition = '') {
            this.positionLegends = {
                ...this.positionLegends,
                colorPanel: {
                    top: String(topPosition),
                    left: String(leftPosition)
                }
            };

            ServiceMapLegends.setPositionLegends(this.positionLegends);
        },
        setMarkerViewModesDefault() {
            this.$refs?.linceMapWrapper?.setMarkerViewModesDefault();
        },
        /** Карта инициализируется */
        onInitMap() {
            this.$emit('onInitMap');
        },
        /** Карта готова */
        onReadyMap() {
            this.$emit('onReadyMap');
            this.$refs.linceMapWrapper.onToggleClustering(false, () => true);
        },
        /**
         * Переключить движок карты
         * @param {String} driver - движок карты
         */
        onToggleDriver(driver = '') {
            ServiceMap.setMapDriver(String(driver));
            window.location.reload();
        },
        /**
         * Событие поиска на карте
         * @param {String} searchValue - значение поля поиска
         */
        onInputSearch(searchValue = '') {
            console.log(searchValue );
            this.searchMap(String(searchValue));
        },
        /**
         * Событие выбора элемента поиска
         * @param {Object} item - элемент из поиска
         * @param {String} item.id - id значения результата поиска по карте
         * @param {String} item.value - строка с адресом значения результата поиска по карте
         * @param {String} item.lat - широта адреса значения результата поиска по карте
         * @param {String} item.lng - долгота адреса значения результата поиска по карте
         */
        onSelectItemSearch(item = {}) {
            const {value = ''} = item;
            this.searchMap(String(value));
        },
        /**
         * Событие поиска на карте
         * @param {String} searchValue - значение поля поиска
         */
        async searchMap(searchValue = '') {
            if (this.getIsSearchValue(searchValue)) {
                this.isLoadingToolbar = true;
                await ServiceMap.searchMap(searchValue, (searchItems) => {
                    this.searchItemsApproxed = [...searchItems];
                    this.isLoadingToolbar = false;
                });
            }
            else
                this.searchItemsApproxed = [];
        },
        /**
         * Получить условие непустой строки
         * @param {String} searchValue - строка поиска
         * @returns {Boolean}
         */
        getIsSearchValue(searchValue = '') {
            // eslint-disable-next-line no-useless-escape
            return String(searchValue).replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()?]/g, '') !== '';
        },

        /**
         * Перерисовать маркеры
         * @param {Function} actionFilter - событие для фильтрации маркеров на карте
         * @param {Array<Object>} markers - массив маркеров
         * @param {Number} markers[].id - id маркера
         * @param {Number} markers[].lat - широта маркера
         * @param {Number} markers[].lng - долгота маркера
         * @param {Object} markers[].colors - цвет маркера по статусам
         * @param {NUmber} markers[].direction - направления
         * @param {Object} markers[].lighted - освещение у маркера
         * @param {Object} markers[].format_id - id форматов сторон
         * @param {Object} markers[].kind_id - id видов сторон
         * @param {Object} markers[].supp_id - id подрядчики сторон
         * @param {Object} markers[].type_id - id типы сторон
         * @param {Array<Object>} markers[].sides - стороны маркера
         * @param {String} markers[].sides.code - код стороны маркера
         * @param {Number} markers[].sides.id - id стороны маркера
         * @param {Array<Object>} markers[].sides.prices - цены стороны маркера
         * @param {String} markers[].sides.prices[].date - дата для цены
         * @param {Number} markers[].sides.prices.id - id цены
         * @param {String} markers[].sides.prices.status - статус цены
         * @param {String} markers[].sides.prices.user_status - пользовательский статус цены
         * @param {Boolean} isRender - условие отрисовки
         * @param {Boolean} isUpdateClustering - условие обновления кластеризации
         */
        redrawMarkers(actionFilter = () => {}, markers = [], isRender = true, isUpdateClustering = true) {
            this.$refs.linceMapWrapper.redrawMarkers(actionFilter, markers, isRender, isUpdateClustering);
        },

        updateMarkers(actionUpdate = () => {}, isUpdateClustering = true) {
            this.$refs.linceMapWrapper.updateMarkers(actionUpdate, isUpdateClustering);
        },

        /** Масштабировать карту */
        fitBounds() {
            this.$refs.linceMapWrapper.fitBounds(() => true);
        },

        focusMap(lat, lng, zoom){
            this.$refs.linceMapWrapper.scaleMap([{lat, lng}], zoom);
        },

        onClickMarker(marker = {}, typeEvent = '') {
            switch (typeEvent) {
                case 'left':
                    if (this.mapModeSelected === 'selection')
                        this.$emit('onSelectMarkerLeft', marker);
                    else if (this.mapModeSelected === 'directionEdit')
                        this.$emit('onDirectionEditMarker', marker);
                    else if (this.mapModeSelected === 'view')
                        this.$emit('onSelectMarkerRight', marker);
                    break;
                case 'right':
                    this.$emit('onSelectMarkerRight', marker);
                    break;
                default:
                    break;
            }
        },

        /**
         * Событие инициализации информационного окна
         * @param {Object} center - координаты точки
         * @param {Object} poiInfo - координаты точки
         */
        initWindowInfoPoi(center = {}, poiInfo = {}) {
            this.$refs.linceMapWrapper.initWindowInfoPoi(center, poiInfo);
        },

        /**
         * Переключить режим выбора
         */
        onToggleSelectMode() {
            if (this.mapModeSelected !== 'selection')
                this.selectMode('selection');
        },

        /**
         * Переключить массовый режим выбора
         */
        onToggleMassSelectMode() {
            if (this.mapModeSelected !== 'massSelection')
                this.selectMode('massSelection');
        },

        onToggleViewMode() {
            if (this.mapModeSelected !== 'view')
                this.selectMode('view');
        },

        onToggleDirectionEditMode() {
            if (this.mapModeSelected !== 'directionEdit')
                this.selectMode('directionEdit');
        },

        /**
         * Событие выбора режима
         * @param {String} id - идентификатор режима
         */
        selectMode(id = '') {
            this.mapModeSelected = String(id);
            if (this.mapModeSelected === 'selection' || this.mapModeSelected === 'directionEdit' || this.mapModeSelected === 'view')
                this.$refs.linceMapWrapper.destroyInitPolygon();
            else
                this.$refs.linceMapWrapper.initPolygon();
        },

        /**
         * Событие сработающее после создания полигона
         * @param {Object} polygon - полигон
         */
        onAfterCreatePolygon(polygon = {}) {
            this.$refs.linceMapWrapper.initPolygon();
            const markersContainsLocation = this.$refs.linceMapWrapper.containsLocation(polygon);
            this.$emit('onMassSelectMarkers', markersContainsLocation);
        },

        onToggleOpn(active = false) {
            this.$refs.linceMapWrapper.toggleOpn(active, this.actionFilterMarkersToggleDirection);
        },

        /**
         * Событие переключения направлений "Угол обзора"
         * @param {Boolean} active - активность кнопки
         */
        onToggleDirectionAngleView(active = false) {
            this.$refs.linceMapWrapper.onToggleDirectionAngleView(active, this.actionFilterMarkersToggleDirection);
        },

        /**
         * Событие переключения направлений "Поток на сторону"
         * @param {Boolean} active - активность кнопки
         */
        onToggleDirectionFlowSide(active = false) {
            this.$refs.linceMapWrapper.onToggleDirectionFlowSide(active, this.actionFilterMarkersToggleDirection);
        },

        /**
         * Событие фильтрации маркеров для работы с направлениями
         * @param {Object} marker - обертка над маркером с карты
         * @param {Number} marker.direction - направление маркера
         * @param {Object} marker.extraData - доп. данные маркера
         * @param {String} marker.extraData.kitItemId - id набора маркера
         * @param {String} marker.figure - фигура маркера
         * @param {String} marker.icon - иконка маркера
         * @param {String} marker.iconType - тип иконки
         * @param {String} marker.id - id маркера
         * @param {Boolean} marker.isClusterer - нужно ли кластеризовывать маркер
         * @param {Boolean} marker.isRender - нужно ли отрисовывать маркер
         * @param {Boolean} marker.isVisible - видим ли маркер
         * @param {Number} marker.lat - широта маркера
         * @param {Number} marker.lng - долгота маркера
         * @param {Object} marker.map - ссылка на карту
         * @param {String} marker.markerIconHost - хост для запроса на иконку маркера
         * @param {Object} marker.markerObj - ссылка на объект маркера того или иного апи
         * @param {Number} marker.oldState - предыдущее состояние маркера
         * @param {Object} marker.sidesColors - цвета сторон маркера
         * @param {Array<String>} marker.sidesFiltered - доступные стороны маркера
         * @param {String} marker.src - путь до иконки маркера
         * @param {Number} marker.state - текущее состояние маркера
         */
        actionFilterMarkersToggleDirection(marker) {
            const {extraData = {}} = marker;
            const {type = 'default'} = extraData;
            return type !== 'poi';
        },

        /**
         * Событие выбора группы в легенде
         * @param {String} legendKey - ключ легенды
         * @param {String} groupId - id группы
         */
        onSelectLegendGroup(legendKey = '', groupId = '') {
            this.$emit('onSelectLegendGroup', legendKey, groupId);
        },

        /**
         *
         */
        onLegendSelectColor(legendData, color){
            console.log(color, legendData)
            this.$emit('onLegendSelectColor', legendData, color);
        },

        /**
         *
         */
        onLegendSelectFigure(legendData, figure){
            this.$emit('onLegendSelectFigure', legendData, figure);
        },

        /**
         * Событие переключения элемента легенды
         * @param {Object} legendData - данные легенды
         * @param {String} legendData.legendKey - ключ легенды
         * @param {String} legendData.group - ключ группы
         * @param {String} legendData.legendItemId - id элемента легенды
         * @param {Boolean} legendData.active - активность элемента легенды
         */
        onToggleLegendItem(legendData = {}) {
            this.$emit('onToggleLegendItem', legendData);
        },

        /**
         * Событие активации панели тепловой карты
         */
        onFetchHeatmapList() {
            this.$emit('onFetchHeatmapList')
        },

        /**
         * Событие активации слоя тепловой карты
         */
        onFetchHeatmapData(heatmapId) {
            this.$emit('onFetchHeatmapData', heatmapId)
        },

        onMapClick(coords) {
            this.$emit('onMapClick', coords)
        },

        /**
         * Включить панораму
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        setStreetView(lat = '', lng = '') {
            this.$refs.linceMapWrapper.setStreetView(lat, lng);
        },
    }
}
</script>

<style lang="scss" scoped>
    .lince-map-wrapper {
        position: absolute;
    }
</style>
