const schemeWidgetMap = {
    containerPoi: {
        headerDropdownItems: [
            {
                name: 'Закрыть POI',
                emit: 'onClosePoi',
            },
            {
                name: 'Удалить всё',
                emit: 'onRemoveAll',
            },
        ],
        headerCreateDropdownItems: [
            {
                name: 'Из системы',
                emit: 'onAddNewGroup',
            },
            {
                name: 'Мои точки',
                emit: 'onAddNewMoiPoiGroup',
            },
        ],

        groupMenuItems: [
            {
                name: 'Удалить группу',
                emit: 'removeGroupPoi',
            },
        ],

        poiMenuItems: [
            // {
            //     name: 'Удалить метку',
            //     emit: 'removePoi',
            // },
        ],

        addGroupModal: {
            textAddPoi: 'Добавить POI',
            textGroupName: 'Название группы POI',
            textGroupQuery: 'Запрос на поиск POI',
            textQueryPlaceholder: 'Пример: Магнит',
            textPlaceholder: 'Пример: Рестораны',
            textSelectColor: 'Выберите цвет группы (необязательное поле)',
            textSelectIcon: 'Или загрузите собственную иконку группы (необязательное поле)',
            textSelectIconFromIconList: 'Выберите иконку группы (необязательное поле)',
            textAddFile: 'Выберите файл',
            textCancel: 'Отменить',
            textApply: 'Добавить',
        },

        addMoiPoiGroupModal: {
            textAddPoi: 'Добавить мои POI',
            textGroupName: 'Название группы мои POI',
            textGroupQuery: 'Запрос на поиск мои POI',
            textQueryPlaceholder: 'Пример: Магнит',
            textPlaceholder: 'Пример: Рестораны',
            textSelectColor: 'Выберите цвет группы (необязательное поле)',
            textSelectIcon: 'Или загрузите собственную иконку группы (необязательное поле)',
            textSelectIconFromIconList: 'Выберите иконку группы (необязательное поле)',
            textAddFile: 'Выберите файл',
            textSaveAndStartAddingPoint: 'Сохранить и перейтки к добавлению точек',
            textSaveWithoutPoint: 'Создать пустую группу'
        },

        addMoiPoiPointsModal: {
            textAddPoi: 'Добавить точку на карте',
            textInputLabel: 'Адрес (в диапазоне "Москва")',
            textPlaceholder: 'Например: улица Пушкина, дом 42',
            textAddPoint: 'Создать точку POI'
        },
    },

}

export default schemeWidgetMap;
