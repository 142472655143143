/** Утилита определения вхождения точек в радиус */
export default class UtilPointDistance {

    /**
     *
     * @param {Object} marker1
     * @param {Object} marker2
     * @param {Number} radius
     * @return {Boolean}
     */
    static isIncludes(marker1, marker2 = 0, radius) {
        const degreeLength = 40015041 / 360;
        let {lat: lat1, lng: lng1} = marker1;
        let {lat: lat2, lng: lng2} = marker2;
        lat1 = Number(lat1);
        lat2 = Number(lat2);
        lng1 = Number(lng1);
        lng2 = Number(lng2);
        let radius2 = radius * radius;
        let lngLength = degreeLength * Math.cos(this.degreesToRadians(lat1));
        let latLengthInDegrees = radius / degreeLength;
        let lngLengthInDegrees = radius / lngLength;
        let upperPart = lat1 + latLengthInDegrees;
        let lowerPart = lat1 - latLengthInDegrees;
        let leftPart = lng1 - lngLengthInDegrees;
        let rightPart = lng1 + lngLengthInDegrees;
        return lat2 < upperPart && lat2 > lowerPart
            && lng2 < rightPart && lng2 > leftPart
            && (Math.pow((lat2 - lat1) * degreeLength, 2) + Math.pow((lng2 - lng1) * lngLength, 2)) < radius2
    }

    /**
     * @param degrees
     * @return {number}
     */
    static degreesToRadians(degrees)
    {
        var pi = Math.PI;
        return degrees * (pi/180);
    }
}
