import ServiceApi from "../ServiceApi";
import ServiceFilter from "../ServicesFilter/ServiceFilter";
import UtilDate from "../../utils/UtilDate";
import ServiceConstructionInfo from "../ServicesConstructionInfo/ServiceConstructionInfo";
/** Сервис для работы с конкурентами */
export default class ServiceCompetitor {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} competitorDates - получение доступных дат конкурентов
     * @property {String} competitorList - список конкурентов для выбранного типа
     * @property {String} competitorUserListAdd - добавление конкурента в список пользователя
     * @property {String} competitorUserListRemove - удаление конкурента из списка пользователя
     * @property {String} competitorChangeColor - изменние цвета
     * @property {String} competitorMarkers - получение маркеров
     * @property {String} competitorMarkerDetail - получение инфо о маркере
     */
    static _requestRouts = {
        competitorDates: '/competitor/dates',
        competitorList: '/competitor/list',
        competitorUserList: '/competitor/user-list',
        competitorUserListAdd: '/competitor/user-list-add',
        competitorUserListRemove: '/competitor/user-list-remove',
        competitorChangeColor: '/competitor/set-color',
        competitorMarkers: '/competitor/markers',
        competitorMarkerDetail: '/competitor/marker-detail'
    };

    /**
     * Подготовить тело запроса для доступных дат
     * @private
     * @static
     * @returns {Object} - тело запроса
     */
    static _getCompetitorDatesBefore() {
        const {city_id} = ServiceFilter.getFilterSelected();
        return {city_ids: city_id};
    }

    /**
     * Подготовить тело запроса для общего списка доступных конкурентов
     * @private
     * @static
     * @param {String} type - тип конкурента
     * @param {Array<Object>} dates - выбранные даты
     * @returns {Object} - тело запроса
     */
    static _getCompetitorListBefore(type, dates) {
        const {city_id} = ServiceFilter.getFilterSelected();
        let stringDates = [];
        dates.forEach((date) => {
            stringDates.push(UtilDate.fromDatepickerDate(date))
        });
        return {
            competitor: {
                city_ids: city_id,
                dates: stringDates,
                type
            }
        };
    }

    /**
     * Подготовить тело запроса для маркеров
     * @private
     * @static
     * @param {Object} competitorObj
     * @param {Array<Object>} dates - выбранные даты
     * @returns {Object} - тело запроса
     */
    static _getCompetitorMarkersBefore(competitorObj, dates) {
        const {city_id} = ServiceFilter.getFilterSelected();
        const [type, esparId] = competitorObj.id.split("-");
        let stringDates = [];
        dates.forEach((date) => {
            stringDates.push(UtilDate.fromDatepickerDate(date))
        });
        return {
            competitor: {
                city_ids: city_id,
                dates: stringDates,
                type,
                espar_ids: [esparId]
            }
        };
    }

    /**
     * Получить доступные даты
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorDates(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorDates,
                this._getCompetitorDatesBefore()
            );
            const {data: datesData = {}} = res;
            const datesDataAfter = this._postCompetitorDatesAfter(datesData);
            actionAfter(datesDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить список конкурентов
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Array} dates - выбранные даты
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorList(type, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorList,
                this._getCompetitorListBefore(type, dates)
            );
            const {data: competitors = {}} = res;
            const {result: competitorsResult = {}} = competitors;
            const competitorsAfter = this._postCompetitorListAfter(type, competitorsResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить список конкурентов пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Array} dates
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserList(type, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserList,
                this._getCompetitorListBefore(type, dates)
            );
            const {data: competitors = {}} = res;
            const {result: competitorsResult = {}} = competitors;
            // console.log(competitorsResult);
            const competitorsAfter = this._postCompetitorUserListAfter(type, competitorsResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Добавление в список пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserListAdd(type, competitorId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserListAdd,
                this._getCompetitorUserListAddBefore(type, competitorId)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Удаление из список пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserListRemove(type, competitorId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserListRemove,
                this._getCompetitorUserListAddBefore(type, competitorId)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Изменение цвета конкурента
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {String} color
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorChangeColor(type, competitorId, color, actionAfter = () => {}) {
        try {
            await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.competitorChangeColor,
                this._getCompetitorChangeColorBefore(type, competitorId, color)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {String} color
     * @private
     */
    static _getCompetitorChangeColorBefore(type, competitorId, color){
        return {
            competitor: {
                competitor_id: competitorId,
                type,
                color
            }
        };
    }

    /**
     *
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @private
     */
    static _getCompetitorUserListAddBefore(type, competitorId){
        return {
            competitor: {
                competitor_id: competitorId,
                type
            }
        };
    }

    /**
     * Получить список конкурентов
     * @public
     * @static
     * @async
     * @param {Object} competitorObj
     * @param {Array} dates - выбранные даты
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorMarkers(competitorObj, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorMarkers,
                this._getCompetitorMarkersBefore(competitorObj, dates)
            );
            const {data: competitorMarkers = {}} = res;
            const {result: markersResult = {}} = competitorMarkers;
            const competitorsAfter = this._postCompetitorMarkersAfter(competitorObj, markersResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Адаптер для фронта
     * @param {Object} competitorObj
     * @param {Array<Object>} markersResult
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorMarkersAfter(competitorObj, markersResult) {
        let markers = [];
        Object.values(markersResult).forEach((markerResult) => {
            let sidesColors = {};
            markerResult.sides.forEach((sideName) => {
                sidesColors[sideName] = competitorObj.color;
            })
            markers.push({
                id: String(markerResult.marker_id),
                additionalId: String(competitorObj?.id),
                lat: String(markerResult.lat),
                lng: String(markerResult.lng),
                direction: String(markerResult.direction),
                fill_color: "#" + competitorObj.color,
                figure: "circle",
                sidesColors,
                selected: false,
                extraData: {
                    groupId: competitorObj.id,
                    type: 'competitor'
                },
                clusterType: 'competitor',
            });
        });
        return markers;
    }

    /**
     * Адаптер для фронта
     * @param {Array<String>} datesData - даты с бэка
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorDatesAfter(datesData) {
        let preparedDates = [];
        let nearest = UtilDate.getNearDate(datesData, false);
        datesData.forEach((date) => {
            preparedDates.push(UtilDate.toDatepickerDate(date))
        })
        UtilDate.getNearDate()
        return {preparedDates, nearest : UtilDate.toDatepickerDate(nearest)};
    }

    /**
     * Адаптер для фронта
     * @param {String} type - тип конкурента
     * @param {Object} competitors - объект конкурентов ключ (id), значение - (name)
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorListAfter(type, competitors) {
        let preparedList = [];
        Object.keys(competitors).forEach((competitorId) => {
            preparedList.push({
                id: competitorId,
                value: competitors[competitorId]
            });
        })
        return preparedList;
    }

    /**
     * Адаптер для фронта
     * @param {String} type - тип конкурента
     * @param {Object} competitors - объект конкурентов ключ (id), значение - (name)
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorUserListAfter(type, competitors) {
        let preparedList = [];
        competitors.forEach((competitor) => {
            const {id = 0, name = "", color = "", count = "0"} = competitor;
            preparedList.push({
                id: type + "-" + id,
                key: type,
                description: name,
                color,
                count
            });
        })
        return preparedList;
    }

    /**
     *
     * @param {Number} markerId
     * @param {Number} competitorId
     * @param {Array} dates
     * @param {Function} actionAfter
     */
    static async postMarkerDetail(markerId, competitorId, dates, actionAfter = () => {}) {
        try {
            const response = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorMarkerDetail,
                this._postMarkerDetailBefore(markerId, competitorId, dates)
            );
            const {data = {}} = response;
            const {result = {}} = data;
            const constructionData = this._postMarkerDetailAfter(result);
            console.log(constructionData);
            const {activeSide, activePrice, availablePrices} =  ServiceConstructionInfo._prepareCardData('marker', markerId, constructionData)
            actionAfter(constructionData, activeSide, activePrice, availablePrices);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param {Number} markerId
     * @param {String} competitorId
     * @param {Array} dates
     * @returns {Object}
     * @private
     */
    static _postMarkerDetailBefore(markerId, competitorId, dates) {
        const [type, esparId] = competitorId.split("-");
        return {
            competitor: {
                marker_id: markerId,
                dates: dates,
                type,
                espar_ids: [esparId]
            }
        };
    }

    /**
     *
     * @param {Object} result
     * @private
     */
    static _postMarkerDetailAfter(result) {
        const {sides = [], address = null} = result;
        const resultSides = Object.values(sides).reduce((resultSides, side) => {
            const {prices = [], updated_at: updatedAt = ""} = side;
            const sideUpdatedAtObject = new Date(Date.parse(updatedAt))

            const resultPrices = Object.values(prices).reduce((resultPrices, price) => {
                const {
                    date = "",
                    updated_at: updatedAt = "",
                    esparBrands = [],
                    esparProjects = [],
                    esparAdvertisers = [],
                    esparIndustries	 = []
                } = price;
                const dateObject = new Date(Date.parse(date))
                const updatedAtObject = new Date(Date.parse(updatedAt))

                resultPrices.push({
                    id: String(price.id),
                    side_id: String(price.item_id),
                    date: UtilDate.toString(dateObject.getFullYear(), dateObject.getMonth() + 1),
                    price: price.price,
                    agency_price: price.agency_price,
                    print_price: price.print_price,
                    additional_print_price: price.additional_print_price,
                    install_price: price.install_price,
                    status: price.status,
                    user_status: price.user_status,
                    brands: esparBrands.map(item => item.name).join(", "),
                    projects: esparProjects.map(item => item.name).join(", "),
                    advertisers: esparAdvertisers.map(item => item.name).join(", "),
                    industries: esparIndustries.map(item => item.name).join(", "),
                    created_at: String(updatedAtObject.getTime() / 1000),
                    updated_at: String(updatedAtObject.getTime() / 1000),
                    priceGroups: []
                });
                return resultPrices;
            }, []);

            resultSides.push({
                id: String(side.id),
                marker_id: String(side.marker_id),
                code: side.side,
                side_name: side?.side_item?.value ?? null,
                catalog_id: side.catalog_id,
                created_at: String(sideUpdatedAtObject.getTime() / 1000),
                updated_at: String(sideUpdatedAtObject.getTime() / 1000),
                propertyLinks: {
                    type_id: {
                        property_id: String(side?.type_item?.id ?? 0),
                        value: String(side?.type_item?.value ?? "Не определено")
                    },
                    kind_id: {
                        property_id: String(side?.kind_item?.id ?? 0),
                        value: String(side?.kind_item?.value ?? "Не определено")
                    },
                    format_id: {
                        property_id: String(side?.format_item?.id ?? 0),
                        value: String(side?.format_item?.value ?? "Не определено")
                    },
                    supp_id: {
                        property_id: String(side?.supplier_item?.id ?? 0),
                        value: String(side?.supplier_item?.value ?? "Не определено")
                    },
                    lighted: {
                        property_id: String(side?.light_item?.id ?? 0),
                        value: String(side?.light_item?.value ?? "Не определено")
                    },
                    district_id: {
                        property_id: String(side?.district_item?.id ?? 0),
                        value: String(side?.district_item?.value ?? "Не определено")
                    }
                },
                sideUserInfo: {
                    grp: side?.grp ?? null,
                    ots: side?.ots ?? null,
                    image_src: side?.image_src ?? null,
                    realAddress: address,
                    supp_item_id: side?.supp_item_id ?? null,
                    ext_field_1: side?.ext_field_1 ?? null,
                    ext_field_2: side?.ext_field_2 ?? null,
                    ext_field_3: side?.ext_field_3 ?? null,
                    ext_field_4: side?.ext_field_4 ?? null,
                    ext_field_5: side?.ext_field_5 ?? null,
                    ext_field_6: side?.ext_field_6 ?? null,
                    ext_field_7: side?.ext_field_7 ?? null,
                    ext_field_8: side?.ext_field_8 ?? null,
                    ext_field_9: side?.ext_field_9 ?? null,
                    ext_field_10: side?.ext_field_10 ?? null,
                    additional_install_price: side?.additional_install_price ?? null,
                    install_price: side?.install_price ?? null,
                    print_price: side?.print_price ?? null,
                    material: side?.material ?? null,
                    move_direction: side?.move_direction ?? null,
                    opn_number: side?.opn_number ?? null,
                    video_block: side?.video_block ?? null,
                    video_block_clients: side?.video_block_clients ?? null,
                    video_frequency: side?.video_frequency ?? null,
                    video_time: side?.video_time ?? null
                },
                prices: resultPrices
            })
            return resultSides;
        }, []);

        return {
            id: String(result?.id ?? 0),
            lat: String(result?.lat ?? 0),
            lng: String(result?.lng ?? 0),
            direction: String(result?.direction ?? 0),
            city: {
                id: String(result?.city_item?.id ?? 0),
                name: String(result?.city_item?.name ?? ""),
            },
            sides: resultSides
        }
    }
}
