<template>
    <base-modal
        v-if="stage === 'init'"
        :title="title"
        :description="description"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="create-modal__body-wrap">
                <div class="create-modal__body-tabs">
                    <tabList :tabs="tabList" @onCheck="toggleTab" />
                </div>

                <template v-if="activeTab === 'create'">
                    <label for="field_name" class="create-modal__label">
                        {{ inputTitle }}
                        <span
                            class="create-modal__label-icon icon-question-1"
                        ></span>
                    </label>
                    <BaseTextInput
                        class="create-modal__input-text"
                        id="field_name"
                        maxlength="70"
                        view="primary"
                        :value="inputName"
                        @input="onInputName"
                    />

                    <!--                        <template v-if="modalType === 'maket'">-->
                    <!--                            <label for="field_file" class="create-modal__label">-->
                    <!--                                Изображение <span class="create-modal__label-icon icon-question-1"></span>-->
                    <!--                            </label>-->
                    <!--                            <AttachFile-->
                    <!--                                class="create-modal__input-file"-->
                    <!--                                :file="file"-->
                    <!--                                id="field_file"-->
                    <!--                                @change="changeFile"-->
                    <!--                                @clear="clearFile"/>-->

                    <!--                        </template>-->
                </template>

                <template v-if="activeTab === 'attach'">
                    <label for="modal_select" class="create-modal__label">
                        {{ selectTitle }}
                        <span
                            class="create-modal__label-icon icon-question-1"
                        ></span>
                    </label>
                    <BaseSelect
                        id="modal_select"
                        class="create-modal__select"
                        :canSearch="true"
                        :is-fixed="true"
                        :is-virtual-scroll="true"
                        :options="options"
                        :value="activeOptionId"
                        :defaultTitle="selectDefaultValue"
                        style="width: 100%"
                        @check="checkSelect"
                    />

                    <!--                        <div v-if="modalType === 'maket'" class="create-modal__attach-image-block">-->
                    <!--                            <span class="create-modal__attach-image-text">Изображение выбранного макета</span>-->
                    <!--                            <img-->
                    <!--                                class="create-modal__attach-image"-->
                    <!--                                width="135"-->
                    <!--                                height="90"-->
                    <!--                                src="https://loremflickr.com/640/400"-->
                    <!--                                alt="Изображение макета">-->
                    <!--                        </div>-->
                </template>

                <div class="create-modal__actions">
                    <base-button
                        class="create-modal__action-btn"
                        form="square"
                        view="border"
                        @click="onCancel"
                        >Отменить</base-button
                    >
                    <base-button
                        form="square"
                        view="secondary"
                        @click="onApply"
                        :disabled="!file && activeOptionId === -1 && !inputName"
                        >{{ applyBtnText }}</base-button
                    >
                </div>
            </div>
        </template>
    </base-modal>
    <base-modal v-else-if="stage === 'wait'" :title="title" @close="onCloseModal">
        <template v-slot:modalBody>
            <div
                class="create-modal__body-wrap create-modal__body-wrap--large-mt"
            >
                <span class="create-modal__label"
                    >Пожалуйста, подождите...</span
                >
                <div class="create-modal__load-wrap">
                    <span class="create-modal__load-fill"></span>
                </div>

                <div class="create-modal__actions">
                    <base-button
                        class="create-modal__action-btn"
                        form="square"
                        view="border"
                        @click="onCancel"
                        >Отменить</base-button
                    >
                </div>
            </div>
        </template>
    </base-modal>
    <base-modal
        v-else-if="stage === 'complete'"
        :title="title"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div
                class="create-modal__body-wrap create-modal__body-wrap--large-mt"
            >
                <template v-if="activeTab === 'create'">
                    <div class="create-modal__label">
                        <p>
                            Создан {{ cancelDescription }}
                            <span>«{{ inputName }}»</span> <br />
                            <span>{{ count }} сторон</span>
                        </p>
                    </div>
                </template>

                <template v-if="activeTab === 'attach'">
                    <div class="create-modal__label">
                        <p>
                            {{ count }} сторон прикреплены к
                            {{ cancelDescription }} <br />
                            <span>«{{ selectValue }}»</span>
                        </p>
                    </div>
                </template>

                <div class="create-modal__actions">
                    <base-button
                        class="create-modal__action-alt-btn"
                        @click="onMoveToPg"
                        >{{ altBtnText }}</base-button
                    >
                    <base-button
                        class="create-modal__action-btn"
                        form="square"
                        view="border"
                        @click="onCancel"
                        >Закрыть</base-button
                    >
                </div>
            </div>
        </template>
    </base-modal>
    <base-modal v-else-if="stage === 'cancel'" :title="title" @close="onCloseModal">
        <template v-slot:modalBody>
            <div
                class="create-modal__body-wrap create-modal__body-wrap--large-mt"
            >
                <div class="create-modal__label">
                    <p>{{ cancelDescription }}</p>
                </div>

                <div class="create-modal__actions">
                    <button class="create-modal__action-alt-btn">
                        Повторить
                    </button>
                    <base-button
                        class="create-modal__action-btn"
                        form="square"
                        view="border"
                        @click="onCancel"
                        >Закрыть</base-button
                    >
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal";
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from "@/components/Base/BaseTextInput";
import BaseSelect from "@/components/Base/BaseSelect";
import TabList from "@/components/Tabs/TabList";
// import AttachFile from "@/components/AttachFile";

export default {
    name: "CreateModal",
    components: {
        // AttachFile,
        BaseModal,
        BaseButton,
        BaseTextInput,
        BaseSelect,
        TabList,
    },

    props: {
        file: {
            type: File,
            default: null,
        },
        textParams: {
            type: Array,
            default: () => [],
        },
        tabList: {
            type: Array,
            default: () => [],
        },
        modalType: {
            type: String,
            default: "",
        },
        stage: {
            type: String,
            default: "init",
            validation: (val) =>
                ["init", "wait", "complete", "cancel"].includes(val),
        },
        activeTab: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 0,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        activeOptionId: -1,
        inputName: "",
    }),

    computed: {
        selectValue() {
            const option = this.options.filter(
                (option) => String(option.id) === String(this.activeOptionId)
            );
            return option.length ? option[0].value : "";
        },
        // Пример структуры text-params
        // {stage: 'init', tab: 'create', title: 'Создать макет'},

        title() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.title);
            return item?.title ?? "";
        },

        inputTitle() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.inputText);
            return item?.inputText ?? "";
        },

        selectTitle() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.selectText);
            return item?.selectText ?? "";
        },

        selectDefaultValue() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.selectDefaultValue);
            return item?.selectDefaultValue ?? "";
        },

        description() {
            return "Выбрано " + this.count + " сторон";
        },

        cancelDescription() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.descr);
            return item?.descr ?? "";
        },

        applyBtnText() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.applyBtnText);
            return item?.applyBtnText ?? "Применить";
        },

        altBtnText() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.altBtnText);
            return item?.altBtnText ?? "";
        },

        // options() {
        //     return [
        //         // {value: 'Академика Павлова_Октябрь', id: 0},
        //         // {value: 'Саларьево Парк_Ноябрь', id: 1},
        //         // {value: 'Западный Порт_Ноябрь', id: 2},
        //         // {value: 'Влюблино_Ноябрь', id: 3},
        //     ];
        // },
    },

    methods: {
        /**
         * Клик на кнопку закрыть модальное окно
         */
        onCloseModal() {
            this.$emit("onCloseModal");
        },

        onMoveToPg() {
            this.$emit("onCloseModal");
        },

        /**
         * Клик на кнопку Применить
         */
        onApply() {
            const body = {
                name: this.inputName,
                priceGroupId: this.activeOptionId,
            };
            this.$emit("onApplyModal", body);
        },

        /**
         * Клик на кнопку Отмена
         */
        onCancel() {
            this.onCloseModal();
        },

        changeFile(file) {
            this.$emit("changeFile", file);
        },

        toggleTab(tabId) {
            this.$emit("toggleTab", tabId);

            this.activeOptionId = -1;
            this.inputName = "";
        },

        clearFile(name = "") {
            this.$emit("clearFile", name);
        },

        checkSelect(option) {
            this.$emit("checkSelect", option);
            this.activeOptionId = String(option.id);
        },

        onInputName(value = "") {
            this.inputName = value;
        },
    },
};
</script>

<style lang="scss" scoped>
$color-green: #6ec87a;
$color-gray-light: #f2f2f2;
$color-gray: #afb0b4;
$color-blue: #4a92f6;
.create-modal {
    padding: 0;

    &__select .select-preview {
        background-color: $color-gray-light;
        color: $color-gray;
        justify-content: space-between;

        &:active,
        &:focus {
            background-color: #fff;
            color: #000;
        }
    }

    &__body-wrap {
        margin-top: 20px;
    }

    &__body-tabs {
        margin-bottom: 10px;
    }

    &__label {
        display: flex;
        align-items: center;

        font-size: 12px;
    }

    &__label-icon {
        font-size: 11px;
        font-weight: 400;
        color: $color-gray;
        margin-left: 4px;
        cursor: pointer;

        &:hover {
            color: #333;
        }
    }

    &__input-text {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    &__input-file {
        margin-top: 5px;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        margin-top: 30px;
    }

    &__action-btn {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__action-alt-btn {
        cursor: pointer;
        color: $color-blue;

        &:not(:last-child) {
            margin-right: 30px;
        }

        &:hover {
            color: #000;
        }
    }

    &__select {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    &__attach-image-block {
        font-size: 12px;
    }

    &__attach-image-text {
        display: block;
        margin-bottom: 5px;
    }

    &__attach-image {
        width: 135px;
        height: 90px;
        border-radius: 10px;
    }

    &__load-wrap {
        background-color: $color-gray-light;
        height: 20px;
        border-radius: 4px;
        margin-top: 5px;
    }

    &__load-fill {
        display: block;
        width: 0;
        height: inherit;
        background-color: $color-green;
        animation: loading 1.5s ease-in-out infinite;

        @keyframes loading {
            from {
                width: 0;
            }

            to {
                width: 100%;
            }
        }
    }
}
</style>
