/**
 * Схема для панели наборов
 * @property {Array<Object>} SchemeKitPanel.kitActions - действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].id - идентификатор действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].icon - иконка действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].iconComponent - иконка действия для наборов в виде компонента
 * @property {String} SchemeKitPanel.kitActions[].emitName - название эмита действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].isGuest - флаг доступности действия для шары
 * @property {Array<Object>} SchemeKitPanel.kitItemsShadow - набор для скелетной загрузки
 * @property {String} SchemeKitPanel.kitItemsShadow[].id - идентификатор набора
 * @property {String} SchemeKitPanel.kitItemsShadow[].name - название набора
 * @property {String|Number} SchemeKitPanel.kitItemsShadow[].count - колличество сторон набора
 * @property {String|Number} SchemeKitPanel.kitItemsShadow[].sum - сумма цен набора
 */
const SchemeKitPanel = {
    kitActions: [
        {
            id: "markers",
            icon: "icon-tabler-icon-map-pin",
            iconComponent: "",
            emitName: "onToggleMarkers",
            desc: "Метки на карте",
        },
        {
            id: "table",
            icon: "icon-tabler-icon-border-all",
            iconComponent: "",
            emitName: "onToggleTable",
            desc: "Таблица",
        },
        {
            id: "summary",
            icon: "",
            iconComponent: "IconInfo",
            emitName: "onToggleSummary",
            desc: "Сводные данные",
        },
        // {
        //     id: 'admetrix',
        //     icon: 'icon-tabler-icon-rocket',
        //     iconComponent: "",
        //     emitName: 'onToggleAdmetrix'
        // },
        {
            id: "share",
            icon: "icon-tabler-icon-link",
            iconComponent: "",
            emitName: "onShare",
            isGuest: false,
            isHasLabelChecked: true,
            desc: "Поделиться ссылкой",
        },
        {
            id: "export",
            icon: "icon-tabler-icon-file-download",
            iconComponent: "",
            emitName: "onExport",
            desc: "Скачать Excel",
        },
        {
            id: "admetrix",
            iconComponent: "IconRocket",
            emitName: "onToggleAdmetrix",
            desc: "Рассчитать Admetrix",
        },
        {
            id: "send",
            iconComponent: "IconCopy",
            emitName: "onSendOffer",
            skipTypes: ["1"],
            desc: "Отправить",
        },
        {
            id: "delete",
            iconComponent: "IconTrash",
            emitName: "onRemoveKit",
            desc: "Удалить",
        },
        // {
        //     id: "endEdit",
        //     iconComponent: "IconClose",
        //     emitName: "onEndEdit",
        //     desc: "Завершить редактирование",
        //     kitTypes: ['2']
        // },
        // {
        //     id: "more",
        //     icon: "",
        //     iconComponent: "IconHamburger",
        //     emitName: "onMore",
        //     desc: "Больше",
        //     isGuest: false,
        //     isMenuContext: true,
        //     menuContextItems: [

        //     ],
        // },
    ],
    kitItemsShadow: [
        {
            id: "666",
            name: "Fucking Oohdesk",
            sum: "666666",
            count: "666",
        },
        {
            id: "777",
            name: "Fucking Oohdesk",
            sum: "666666",
            count: "666",
        },
        {
            id: "888",
            name: "Fucking Oohdesk",
            sum: "666666",
            count: "666",
        },
    ],
    kitSidePanelActions: [
        {
            id: "markers",
            icon: "icon-tabler-icon-map-pin",
            iconComponent: "",
            emitName: "onToggleMarkers",
            desc: "Метки на карте",
        },
        {
            id: "table",
            icon: "icon-tabler-icon-border-all",
            iconComponent: "",
            emitName: "onToggleTable",
            desc: "Таблица",
        },
        {
            id: "summary",
            icon: "",
            iconComponent: "IconInfo",
            emitName: "onToggleSummary",
            desc: "Сводные данные",
        },
        // {
        //     id: 'admetrix',
        //     icon: 'icon-tabler-icon-rocket',
        //     iconComponent: "",
        //     emitName: 'onToggleAdmetrix'
        // },
        {
            id: "share",
            icon: "icon-tabler-icon-link",
            iconComponent: "",
            emitName: "onShare",
            isGuest: false,
            isHasLabelChecked: true,
            desc: "Поделиться ссылкой",
        },
        {
            id: "export",
            icon: "icon-tabler-icon-file-download",
            iconComponent: "",
            emitName: "onExport",
            desc: "Скачать Excel",
        },
        {
            id: "admetrix",
            iconComponent: "IconRocket",
            emitName: "onToggleAdmetrix",
            desc: "Рассчитать Admetrix",
        },
        {
            id: "delete",
            iconComponent: "IconTrash",
            emitName: "onRemoveKit",
            desc: "Удалить",
        },
    ],
    kitSidePanelItems: [
        {
            id: "25775",
            name: "3.1 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "3",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "25726",
            name: "3.2 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "3",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "252706",
            name: "4.1 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "4",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "27613",
            name: "4.2 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "4",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "12617",
            name: "4.3 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "4",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "012706",
            name: "5.1 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "5",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "291613",
            name: "5.2 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "5",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "45671",
            name: "5.3 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "5",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
        {
            id: "43545",
            name: "5.4 МТС_MTC NY Premium Q4 TV_Москва_CF_январь 2023.xls",
            type: "5",
            color: "930070",
            figure: "circle",
            externalId: "29085",
            created_at: "1670881668",
            updated_at: "1675323672",
            count: "397",
            sum: "4764000.00",
            last_updated: "1670881674",
            is_shared: false,
        },
    ],

    sidePanelTabs: [
        {
            id: "layout",
            type: 3,
            icon: "IconImages",
            title: "Макеты",
        },
        {
            id: "project",
            type: 4,
            icon: "IconFolders",
            title: "Проекты",
        },
        {
            id: "kit",
            type: 5,
            icon: "IconSaves",
            title: "Наборы",
        },
    ],

    kitTypes: {
        placement: 1,
        offer: 2,
        layout: 3,
        project: 4,
        kit: 5,
    },
    kitTypesReverse: {
        1: "placement",
        2: "offer",
        3: "layout",
        4: "project",
        5: "kit",
    },
};

export default SchemeKitPanel;
