import ServiceApi from '../ServiceApi';
import UtilLocalStorage from '../../utils/UtilLocalStorage';
import UtilDate from '../../utils/UtilDate';
/** Сервис для работы с фильтром */
export default class ServiceFilter {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Ключ для получения фильтра из локального хранилища
     * @private
     * @static
     * @type {String}
     */
    static _localStorageDataKey = 'filterData';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} filterMain - маршрут для получения фильтра
     */
    static _requestRouts = {
        filterMain: '/filter-main',
        filterExtra: '/filter-extra',
        lastDate: '/filter/last-update'
    };

    static _filterExtraParamsKeys = [
        "supp_id",
        "type_id",
        "kind_id",
        "format_id",
        "side_name",
        "status",
        "user_status",
    ]

    /**
     * Получить данные фильтра
     * @public
     * @static
     * @async
     * @param {Array} cityId - выбранные города
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postFilterMain(cityId, kitShowed = {}, actionAfter = () => {}) {
        try {
            let reqBody = {city_id: cityId};
            
            const {externalId = '', type = ''} = kitShowed;
            if (externalId !== '', type !== '')
                reqBody.kitShowed = kitShowed;
            
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.filterMain, reqBody);
            const {data: filterData = {}} = res;
            const filterDataAfter = this._postFilterMainAfter(filterData);
            actionAfter(filterDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Событие, сработающее после получения фильтра
     * @private
     * @static
     * @param {Object} filterData - данные фильтра с бэка
     * @param {Object} filterData.filter - данные фильтра с бэка
     * @param {Array<Object>} filterData.filter.city_id - список городов с бэка
     * @param {String|Number} filterData.filter.city_id[].id - идентификатор города с бэка
     * @param {String} filterData.filter.city_id[].value - название города с бэка
     * @param {Array<String>} filterData.filter.date - список дат с бэка
     * @returns {Object} filterDataAfter - данные после постобработки
     * @returns {Object} filterDataAfter.filter - данные фильтра с бэка
     * @returns {Object} filterDataAfter.selected - выбранные данные фильтра
     * @returns {Array<Number|String>} filterDataAfter.selected.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} filterDataAfter.selected.date - выбранные даты фильтра
     */
    static _postFilterMainAfter(filterData = {}) {
        const {filter = {}} = filterData;
        if (!this._isFilterSelected)
            this._setFilterSelectedDefault(filter);
        else
            this._validateFilterSelected(filter);

        const filterDataAfter = {
            ...filterData,
            selected: this.getFilterSelected()
        };

        return filterDataAfter;
    }

    /**
     * Валидация выбранных параметров фильтра
     * @private
     * @static
     * @param {Object} filterData - данные фильтра
     * @param {Array<Object>} filterData.city_id - список городов с бэка
     * @param {String|Number} filterData.city_id[].id - идентификатор города с бэка
     * @param {String} filterData.city_id[].value - название города с бэка
     * @param {Array<String>} filterData.date - список дат с бэка
     */
    static _validateFilterSelected(filterData = {}) {
        const filterDataSelected = this.getFilterSelected();
        const filterKeys = Object.keys(filterData);
        const isValidFilter = filterKeys.every(filterKey => {
            const filterDataSelectedList = filterDataSelected[filterKey] ?? [];
            const filterDataList = filterData[filterKey] ?? [];
            const filterDataIds = filterDataList.map(filterDataItem => filterKey === 'date' ? String(filterDataItem) : String(filterDataItem?.id));
            const isValid = filterDataSelectedList.every(selectedItem => filterDataIds.includes(String(selectedItem)));
            return isValid;
        });

        if (!isValidFilter)
            this._setFilterSelectedDefault(filterData);
    }

    /**
     * Есть ли выбранные параметры в локальном хранилище
     * @private
     * @static
     * @returns {Boolean}
     */
    static get _isFilterSelected() {
        return UtilLocalStorage.isData(this._localStorageDataKey);
    }

    /**
     * Установить выбранные данные фильтра по умолчанию
     * @private
     * @static
     * @param {Object} filterData - данные фильтра
     * @param {Array<Object>} filterData.city_id - список городов с бэка
     * @param {String|Number} filterData.city_id[].id - идентификатор города с бэка
     * @param {String} filterData.city_id[].value - название города с бэка
     * @param {Array<String>} filterData.date - список дат с бэка
     */
    static _setFilterSelectedDefault(filterData = {}) {
        const {
            city_id: cities = [], 
            date: dates = [], 
            kitShowed: {
                externalId = '',  
                city_id: citiesKitShowed = [], 
                date: datesKitShowed = []
            } = {}
        } = filterData;

        const selectedCity = cities[0] ?? {};
        const {id: cityId = '-1'} = selectedCity;
        
        let filterDataSelected = {
            city_id: [String(cityId)],
            date: [UtilDate.getNearDate(dates)]
        };

        if (String(externalId) !== '') {
            filterDataSelected = {
                kitShowed: filterData.kitShowed,
                city_id: citiesKitShowed,
                date: datesKitShowed
            }
        }

        this.setFilterSelected(filterDataSelected);
    }

    /**
     * Установить выбранные данные фильтра в локальное хранилище
     * @public
     * @static
     * @param {Object} selectedData - выбранные данные
     * @param {Array<Number|String>} selectedData.city_id - выбранные идентификаторы городов фильтра
     * @param {Array<String>} selectedData.date - выбранные даты фильтра
     */
    static setFilterSelected(selectedData = {}) {
        UtilLocalStorage.setData(this._localStorageDataKey, selectedData);
    }

    /**
     * Получить выбранные данные фильтра из локального хранилища
     * @public
     * @static
     * @returns {Object} selectedData - выбранные данные
     * @returns {Array<Number|String>} selectedData.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} selectedData.date - выбранные даты фильтра
     */
    static getFilterSelected() {
        return UtilLocalStorage.getData(this._localStorageDataKey);
    }

    /**
     * Получить данные расширенного фильтра
     * @static
     * @async
     * @property {Object} postData - тело запрос
     */
    static async postExtra(data = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.filterExtra,
                this._prepareExtraBody(data)
            );
            const {data: filterData = {}} = res;
            const filterDataAfter = this._postExtraAfter(filterData);
            actionAfter(filterDataAfter);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Тело запроса для обновления фильтра
     * @param filterDataSelected
     * @returns {{filter_main: {main_params: *}}}
     * @private
     */
    static _prepareExtraBody(filterDataSelected){
        return {
            filter_main : {
                main_params: filterDataSelected,
            }
        };
    }

    /**
     * @param {Object} extendFilter
     * @returns {Object}
     * @private
     */
    static _postExtraAfter(extendFilter = {}) {
        const {count = 0, filters = {}} = extendFilter;
        const extendFilterParams = this._filterExtraParamsKeys.map(key => filters[key] ?? {});

        return {filterDataParams:extendFilterParams, sideCount: Number(count)};
    }

    /**
     * Получить дату последнего обновления ап
     * @static
     * @async
     * @param {Function} actionAfter
     */
    static async getLastUpdate(actionAfter = () => {}) {

        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.lastDate
            );
            const {data: lastDate} = res;
            actionAfter(lastDate);
        }
        catch(error) {
            console.log(error);
        }
    }
}
