<template>
    <base-modal @close="onCloseModal" class="poi-modal">
        <template v-slot:modalBody>
            <div v-if="isLoading" class="poi-modal__preloader">
                <BasePreloaderGifV2 :size="60" />
            </div>
            <template v-if="!isLoading">
                <div class="poi-modal__body">
                    <div class="poi-modal__header">Файл загружен!</div>

                    <div class="poi-modal__status">
                        Мы загрузили файл все <b>{{ file.name }}</b
                        >. Теперь, сопоставьте данные из файла со значениями в
                        системе.
                    </div>

                    <div
                        :class="['poi-modal__data', { active: isTableVisible }]"
                    >
                        <div
                            class="poi-modal__label"
                            @click="changeTableVisibility"
                        >
                            Посмотреть пример данных из файла

                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.99488 6.66663C4.88438 6.66661 4.77842 6.6227 4.7003 6.54455L2.2003 4.04455C2.1244 3.96597 2.0824 3.86071 2.08335 3.75147C2.0843 3.64222 2.12812 3.53771 2.20537 3.46046C2.28263 3.38321 2.38713 3.33938 2.49638 3.33844C2.60563 3.33749 2.71088 3.37948 2.78946 3.45538L4.99488 5.6608L7.2003 3.45538C7.27888 3.37948 7.38413 3.33749 7.49338 3.33844C7.60263 3.33938 7.70713 3.38321 7.78439 3.46046C7.86164 3.53771 7.90546 3.64222 7.90641 3.75147C7.90736 3.86071 7.86536 3.96597 7.78946 4.04455L5.28946 6.54455C5.21134 6.6227 5.10538 6.66661 4.99488 6.66663Z"
                                    fill="#AAB0B8"
                                />
                            </svg>
                        </div>
                        <table class="poi-modal__table">
                            <thead>
                                <tr>
                                    <th
                                        v-for="item in columnsData"
                                        :key="item.value"
                                    >
                                        {{ item.value }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(row, index) in tableData"
                                    :key="index"
                                >
                                    <td
                                        v-for="(item, idx) in row"
                                        :key="columns[idx].value"
                                        :data-label="columns[idx].value"
                                    >
                                        {{ item }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <CheckFileDataSelects
                        :selects="selectsItems[fileComposingType]"
                        @onSelectAction="onSelectAction"
                        @onRemoveChecked="onRemoveChecked"
                    />
                </div>

                <div class="poi-modal__actions">
                    <base-button
                        class="poi-modal__cancel"
                        form="square"
                        view="border"
                        @click="onCloseModal"
                    >
                        {{ addGroupModal.textCancel }}
                    </base-button>
                    <base-button form="square" view="secondary" @click="onApply"
                        >{{ addGroupModal.textApply }}
                    </base-button>
                </div>
            </template>
        </template>
    </base-modal>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import BaseModal from "@/components/Base/BaseModal";
import CheckFileDataSelects from "../../components/MapV2/components/ContainerPoi/CheckFileDataSelects";
import containerPoiSchemes from "../../schemes/SchemeWidgetMap";
const { containerPoi } = containerPoiSchemes;

export default {
    components: {
        BaseModal,
        BaseButton,
        CheckFileDataSelects,
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        file: {
            type: File,
            default: () => {},
        },
        fileComposingType: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            isTableVisible: false,
            addGroupModal: containerPoi.addGroupModal,
            selectsItems: {
                address: [
                    {
                        id: "address",
                        label: "Адрес",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: true,
                        options: [],
                    },
                    {
                        id: "city",
                        label: "Город (не обязательно)",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: false,
                        options: [],
                    },
                    {
                        id: "tags",
                        label: "Теги (не обязательно)",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: false,
                        options: [],
                    },
                ],
                coords: [
                    {
                        id: "lat",
                        label: "Широта",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: true,
                        options: [],
                    },
                    {
                        id: "lng",
                        label: "Долгота",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: false,
                        options: [],
                    },
                    {
                        id: "tags",
                        label: "Теги (не обязательно)",
                        placeholder: "Выберите из списка",
                        value: "",
                        index: 0,
                        isRequired: false,
                        options: [],
                    },
                ],
            },
            selectOptions: [],
        };
    },

    mounted() {
        this.selectOptions = this.columnsData.map((item) => ({
            ...item,
            id: item.value,
            isSelected: false,
        }));

        this.selectsItems[this.fileComposingType] = this.selectsItems[
            this.fileComposingType
        ].map((select) => {
            return {
                ...select,
                options: this.selectOptions.map((option) => ({
                    ...option,
                    id: select.id,
                })),
            };
        });
    },

    computed: {
        columnsData() {
            return this.columns.map((value, index) => ({ value, index }));
        },
    },

    methods: {
        onCloseModal() {
            this.$emit("onCloseModal");
        },
        changeTableVisibility() {
            this.isTableVisible = !this.isTableVisible;
        },
        onApply() {
            let requestColumns = {}

            if (this.fileComposingType === 'coords') {
                requestColumns = {
                    lat_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'lat').index,
                    lng_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'lng').index,
                    tags_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'tags').index
                }
            } else if (this.fileComposingType === 'address') {
                requestColumns = {
                    address_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'address').index,
                    city_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'city').index,
                    tags_column: ++this.selectsItems[this.fileComposingType].find(item => item.id === 'tags').index
                }
            }
            this.$emit("onComposeFileData", {
                type: this.fileComposingType === "address" ? 10 : 20,
                ...requestColumns
            });
        },
        onSelectAction(select) {
            this.selectOptions = this.selectOptions.map((option) => {
                if (option.value === select.value) {
                    option.isSelected = true;
                }
                return option;
            });

            this.selectsItems[this.fileComposingType] = this.selectsItems[
                this.fileComposingType
            ].map((item) => {
                if (item.id === select.id) {
                    item.value = select.value;
                    item.index = select.index;
                }
                item.options = this.selectOptions.map((option) => ({
                    ...option,
                    id: item.id,
                }));
                return item;
            });
        },

        onRemoveChecked(select) {
            this.selectOptions = this.selectOptions.map((option) => {
                if (option.value === select.value) {
                    option.isSelected = false;
                }
                return option;
            });

            this.selectsItems[this.fileComposingType] = this.selectsItems[
                this.fileComposingType
            ].map((item) => {
                if (item.id === select.id) {
                    item.value = "";
                    item.index = 0;
                }
                item.options = this.selectOptions.map((option) => ({
                    ...option,
                    id: item.id,
                }));
                return item;
            });
        },
    },
};
</script>

<style lang="scss">
.poi-modal {
    &__data {
        border: 1px solid #eaeced;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;

        cursor: pointer;
    }

    &__data.active {
        .poi-modal__table {
            display: block;
        }
    }

    &__status {
        padding: 20px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        border-radius: 15px;
        color: #131314;
        background: #edfae7;
    }

    &__table {
        display: none;
        overflow: auto;
        border-collapse: collapse;
        border-spacing: 0;

        & tr {
            border-radius: 4px;
        }
        & th,
        & td {
            padding: 10px;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            color: #46494e;
            border: 1px solid #d9e1e9;
        }

        & th {
            padding: 10px 30px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #747e8c;
            background: #eff3f6;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        min-height: max-content;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
    }

    &__preloader {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    &__header {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #000;

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    &__row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 25px;
        }

        &._select {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
    }
    &__error {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
        color: #ff7c8e;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__cancel {
        color: #c8cad1;

        &:hover,
        &:active {
            color: #333;
        }
    }
}
</style>
