<template>
    <div class="map-wrapper">
        <div
            :id="renderDomElementId"
            :style="mapStyle"
            class="map-wrapper__map"
        >
        </div>
        <control-panel-v2
            :panelIdsActive="controlPanelIdsActive"
            :panelBlocksUnvisible="isLegendPanel ? [] : ['mode']"
            @onTogglePedestrianTraffic="onTogglePedestrianTraffic"
            @onToggleAutoTraffic="onToggleAutoTraffic"
            @onToggleJamsTraffic="onToggleJamsTraffic"
            @onToggleColorMap="onToggleColorMap"
            @onToggleDirection="onToggleDirection"
            @onToggleRuler="onToggleRuler"
            @onToggleSelectMode="onToggleSelectMode"
            @onToggleMassSelectMode="onToggleMassSelectMode"
            @onToggleClustering="onToggleClustering"
            class="map-wrapper__control-panel"
        />

        <layout-modules
            @onToggleGroupPoi="toggleGroupPoi"
            @onToggleOnePoi="toggleOnePoi"
            @onTogglePoiAll="togglePoiAll"
            @changeMarkerStylePoi="changeMarkerStylePoi"
        />

        <legend-panel
            v-if="isLegendPanel"
            :markers-statuses="markersStatuses"
            class="map-wrapper__legend-panel"
            @activeLegendPanel="activeLegendPanel"
        />
        <toolbar
            class="map-wrapper__toolbar"
            @onZoomIn="zoomMap('in')"
            @onZoomOut="zoomMap('out')"
        />
    </div>
</template>

<script>
import ControlPanelV2 from './ControlPanelV2/ControlPanelV2';
import LayoutModules from './LayoutModules/LayoutModules';
import LegendPanel from './LegendPanel/LegendPanel';
import Toolbar from './Toolbar/Toolbar.vue';
import ServiceGoogleMap from '../services/ServicesGoogle/ServiceGoogleMap';

export default {
    name: 'MapWrapper',
    components: {
        LayoutModules,
        ControlPanelV2,
        LegendPanel,
        Toolbar
    },
    /**
     * Входные данные компонента
     * @property {String} driver - движок карты
     * @property {String} renderDomElementId - идентификатор dom элемента в котором будет рендериться карта
     * @property {Object} mapParams - параметры для карты
     * @property {Array} markers - маркеры
     * @property {Array} circles - окружности
     * @property {Array} polygons - полигоны
     * @property {Array} modeOptions - опции для выбора режима
     * @property {String} modeIdSelected - выбранный режим
     * @property {Array} markersStatuses - список статусов маркеров для LegendPanel
     * @property {Boolean} isLegendPanel - условие отображения базовой легенды
     */
    props: {
        driver: {
            type: String,
            default: 'google',
            validator: (driver) => {
                return ['google', 'yandex'].indexOf(driver) !== -1;
            }
        },
        renderDomElementId: {
            type: String,
            default: 'map-wrapper',
            validator: (renderDomElementId) => {
                return renderDomElementId !== '';
            }
        },
        mapParams: {
            type: Object,
            default: () => ({})
        },
        markers: {
            type: Array,
            default: () => ([])
        },
        circles: {
            type: Array,
            default: () => ([])
        },
        polygons: {
            type: Array,
            default: () => ([])
        },
        modeOptions: {
            type: Array,
            default: () => ([])
        },
        modeIdSelected: {
            type: String,
            default: ''
        },
        markersStatuses: {
            type: Array,
            default: () => ([]),
        },
        isLegendPanel: {
            type: Boolean,
            default: true
        }
    },
    /**
     * Данные компонента
     * @property {Object} serviceMap - сервис для работы с картой
     * @property {Object} map - карта
     * @property {Array} controlPanelIdsActive - массив активных элементов панели контроллеров карты
     * @property {Boolean} isStreetView - включена ли панорама
     */
    data: () => ({
        serviceMap: {},
        map: {},
        controlPanelIdsActive: [],
        isStreetView: false,
    }),
    computed: {
        /**
         * Стили для контейнера с картой
         * @returns {Object}
         */
        mapStyle() {
            return this.isStreetView ? {'z-index': '41'} : {};
        }
    },
    watch: {
        /** Следим за изменениями маркеров */
        markers: function() {
            this.serviceMap.drawMarkers(this.markers);
        },
        /** Следим за изменениями окружностей */
        circles: function() {
            this.serviceMap.drawCircles(this.circles);
        },
        /** Следим за изменениями полигонов */
        polygons: function () {
            this.serviceMap.drawPolygons(this.polygons);
        }
    },
    mounted() {
        /** Инициализируем карту */
        this.initMap();
    },
    methods: {
        /**
         * Событие инициализации карты
         */
        initMap() {
            const params = {markerClickEvent: this.onClickMarker}
            switch (this.driver) {
                case 'google':
                    this.serviceMap = new ServiceGoogleMap(params);
                    break;
                case 'yandex':
                    break;
                default:
                    break;
            }
            this.map = this.serviceMap.initMap(this.renderDomElementId, this.mapParams);
            this.serviceMap.drawMarkers(this.markers);
            this.serviceMap.drawCircles(this.circles);
            this.serviceMap.drawPolygons(this.polygons);
        },
        /**
         * Событие масштабирования карты
         * @param {Array} coords - координаты в пределах которых масштабируем
         */
        scaleMap(coords = []) {
            this.map = this.serviceMap.scaleMap(coords);
        },
        /**
         * Событие зумирования карты
         * @param {String} zoomType - тип зумирования (in|out)
         */
        zoomMap(zoomType = '') {
            this.map = this.serviceMap.zoomMap(zoomType);
        },
        /**
         * Событие инициализации создания полигона
         */
        initPolygon() {
            this.destroyInitPolygon();
            this.destroyInitCircle();
            this.destroyInitRuler();
            this.serviceMap.initPolygon({}, (polygon) => {this.$emit('onAfterCreatePolygon', polygon)});
        },
        /**
         * Событие инициализации создания окружности
         * @param {Object} center - центер окружности
         */
        initCircle(center = {}) {
            this.destroyInitPolygon();
            this.destroyInitCircle();
            this.destroyInitRuler();
            const {lat: centerLat = '', lng: centerLng = ''} = center;
            this.serviceMap.initCircle({centerLat, centerLng}, (circle) => {this.$emit('onAfterCreateCircle', circle)});
        },
        /**
         * Событие инициализации окна выбора сторон
         * @param {Object} center - центр окружности
         * @param {Array} sides - стороны из файла
         */
        initSideSelectionBox(center = {}, sides = []) {
            this.destroySelectionSideInfo();
            const {lat: centerLat = '', lng: centerLng = ''} = center;
            this.serviceMap.initSideSelectionBox({centerLat, centerLng, sides}, (result) => {this.$emit('onAfterSelectSideInBox', result)});
        },
        /**
         * Событие инициализации линейки
         */
        initRuler() {
            this.destroyInitPolygon();
            this.destroyInitCircle();
            this.serviceMap.initRuler();
        },
        /**
         * Событие удаления инициализируемого полигона
         */
        destroyInitPolygon() {
            this.serviceMap.destroyInitPolygon();
        },
        /**
         * Событие удаления инициализируемой окна выбора сторон
         */
        destroySelectionSideInfo() {
            this.serviceMap.destroySelectionSideInfo();
        },
        /**
         * Событие удаления инициализируемой окружности
         */
        destroyInitCircle() {
            this.serviceMap.destroyInitCircle();
        },
        /**
         * Событие удаления инициализируемой линейки
         */
        destroyInitRuler() {
            this.controlPanelIdsActive.splice(this.controlPanelIdsActive.findIndex(panelId => panelId === 'ruler'), 1);
            this.serviceMap.destroyInitRuler();
        },
        /**
         * Событие переключения пешеходного траффика
         * @param {Boolean} active - активность плашки пешеходного траффика
         */
        onTogglePedestrianTraffic(active = false) {
            this.map = this.serviceMap.setBestPlaceTraffic('pedestrian', active);
        },
        /**
         * Событие переключения автомобильного траффика
         * @param {Boolean} active - активность плашки автомобильного траффика
         */
        onToggleAutoTraffic(active = false) {
            this.map = this.serviceMap.setBestPlaceTraffic('auto', active);
        },
        /**
         * Событие переключения пробок
         * @param {Boolean} active - активность плашки пробок
         */
        onToggleJamsTraffic(active = false) {
            this.serviceMap.setJamsTraffic(active);
        },
        /**
         * Событие переключения цвета карты
         * @param {Boolean} active - активность плашки цвета карты
         */
        onToggleColorMap(active = false) {
            this.map = this.serviceMap.setColorMap(active === true ? 'grey' : 'default');
        },
        /**
         * Событие переключения направлений
         * @param {Boolean} active - активность плашки направлений
         */
        onToggleDirection(active = false) {
            this.serviceMap.setDirection(active);
        },
        /**
         * Событие переключения активности линейки
         * @param {Boolean} active - активность плашки линейки карты
         */
        onToggleRuler(active = false) {
            if (active)
                this.initRuler();
            else
                this.destroyInitRuler();
        },
        /**
         * Переключить режим выбора
         */
        onToggleSelectMode() {
            if (this.modeIdSelected !== 'selection')
                this.onSelectMode('selection');
        },
        /**
         * Переключить массовый режим выбора
         */
        onToggleMassSelectMode() {
            if (this.modeIdSelected !== 'massSelection')
                this.onSelectMode('massSelection');
        },
        /**
         * Включить/отключить кластеризацию на карте
         * @param {Boolean} active - активность плашки кластеризации
         */
        onToggleClustering(active = false) {
            this.serviceMap.setIsClustering(active);
        },
        /**
         * @param {Object} marker - маркер
         * @param {String} typeEvent - тип события (левый клик|правый клик)
         */
        onClickMarker(marker = {}, typeEvent = '') {
            switch (typeEvent) {
                case 'left':
                    if (this.modeIdSelected === 'selection')
                        this.$emit('onSelectMarker', marker);
                    break;
                case 'right':
                    this.serviceMap.setMarkerClicked(marker);
                    this.$emit('onSelectMarkerRight', marker);
                    break;
                default:
                    break;
            }
        },
        /**
         * Событие выбора режима
         * @param {String} id - идентификатор режима
         */
        onSelectMode(id = '') {
            this.$emit('onSelectMode', String(id));
        },
        /**
         * Перерисовать маркеры
         * @param {Function} actionFilter - функция для фильтрации маркеров
         * @param {Array} markers - маркеры
         * @param {Boolean} isRender - условие перерендера
         * @param {Boolean} isUpdateClustering - Включить обновление кластера
         */
        redrawMarkers(actionFilter = () => {}, markers = [], isRender = true, isUpdateClustering = true) {
            this.serviceMap.redrawMarkers(actionFilter, markers, isRender, isUpdateClustering);
        },
        /**
         * Обновить маркеры
         * @param {Function} actionUpdate - функция обновления
         * @param {Boolean} isUpdateClustering - Включить обновление кластера
         */
        updateMarkers(actionUpdate = () => {}, isUpdateClustering = true) {
            this.serviceMap.updateMarkers(actionUpdate, isUpdateClustering);
        },
        /**
         * Отфильтровать маркеры по вхождению внутрь полигона
         * @param {Object} polygon - полигон
         * @returns {Array}
         */
        containsLocation(polygon = {}) {
            return this.serviceMap.containsLocation(polygon);
        },
        /**
         * Включить панораму
         * @param {String} lat - широта
         * @param {String} lng - долгота
         */
        setStreetView(lat = null, lng = null) {
            this.isStreetView = true;
            this.serviceMap.setStreetView(this.renderDomElementId, lat, lng, () => {
                this.isStreetView = false;
            });
        },
        /**
         * Масштабировать карту
         */
        fitBounds() {
            this.serviceMap.fitBounds();
        },
        /**
         * Передать родителю список активных статусов LegendPanel
         */
        activeLegendPanel() {
            this.$emit('activeLegendPanel');
        },
        /**
         * Показать/скрыть все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         * @param {string} groupId - key группы
         * @param {Boolean} isFirstToggle - Первый раз включили группу на карте
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleGroupPoi(dataPoi = [], groupId = '', isFirstToggle, isRender) {
            this.$emit('onToggleGroupPoi', dataPoi, groupId, isFirstToggle, isRender);
        },
        /**
         * Показать/скрыть все POI на карте
         * @param {Array} dataPoi - Список POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        togglePoiAll(dataPoi = [], isRender = true) {
            this.$emit('onTogglePoiAll', dataPoi, isRender);
        },
        /**
         * Показать/скрыть одну метку POI на карте
         * @param {Array} points - Список всех точек группы
         * @param {String} poiId - id POI
         * @param {String} groupId - id группы POI
         * @param {boolean} isRender - отрисовать/скрыть poi
         */
        toggleOnePoi(points = [], poiId = '', groupId = '', isRender = true) {
            this.$emit('onToggleOnePoi', points, poiId, groupId, isRender);
        },
        /**
         * Изменили цвет/иконку маркера
         * @param {Array} points - Список точек данной группы
         * @param {Object} markerStyle - Новый цвет или иконка маркера
         * @param {String} groupId - ID группы маркеров POI
         * @param {boolean} isRendered - отрисована ли группа на карте
         */
        changeMarkerStylePoi(points = [], markerStyle = {}, groupId = '', isRendered = true) {
            this.$emit('changeMarkerStylePoi', points, markerStyle, groupId, isRendered);
        },
    }
}
</script>

<style lang="scss" scoped>
    %map-wrapper {
        height: 100%;
        width: 100%;
    }
    .map-wrapper {
        @extend %map-wrapper;
        &__map {
            @extend %map-wrapper;
        }
        &__control-panel {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;
        }
        &__legend-panel {
            position: absolute;
            top: 10px;
            right: 108px;
        }
        &__toolbar {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
</style>
