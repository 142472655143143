<template>
    <div class="template">
        <template v-if="isName">
            <div class="template__text">Название шаблона</div>
            <div class="template__content">
                <BaseInput
                    class="template__buffer-name"
                    v-model="bufferName"
                />
            </div>
        </template>
        <div 
            v-if="isDescription" 
            class="template__text"
        >
            Какие столбцы показывать и их порядок
        </div>
        <div
            class="template__container"
        >
            <div
                v-for="(visibleItem,visibleIndex) of bufferTemplate"
                :key="'v' + visibleIndex"
            >
                <div
                    class="ghost-item"
                    v-if="ghostPositionUp === visibleIndex"
                >
                    Переместить сюда
                </div>
                <div
                    @mousedown="onMove($event, visibleIndex, visibleItem)"
                    class="js-draggable visible-item"
                    :class="{ 'visible-item_disabled': !getColumnIsCustomizable(visibleItem) }"
                >
                    <div class="cell-checkbox">
                        <base-checkbox-v3
                            :key="visibleItem.attribute"
                            :checked="visibleItem.checked"
                            :size="16"
                            @change="onChangeVisibility($event, visibleItem.attribute)"
                        />
                    </div>
                    <div
                        @dblclick="onEditLabel($event, visibleItem.attribute)"
                        v-if="!isEditing(visibleItem.attribute)"
                    >
                        {{visibleItem.label}}
                    </div>
                    <div
                        v-if="isEditing(visibleItem.attribute)"
                        style="margin-left: 5px"
                    >
                        <BaseInput
                            :value="visibleItem.label"
                            :ref="'input_item_' + visibleItem.attribute"
                            @onBlur="onClearEditing"
                        />
                    </div>
                </div>
                <div
                    class="ghost-item"
                    v-if="ghostPositionDown === visibleIndex"
                >
                    Переместить сюда
                </div>
            </div>
        </div>
        <div class="template__button-container">
            <BaseButton
                @click="onCancel"
            >Отменить</BaseButton>
            <BaseButton
                view="secondary"
                @click="onSaveTemplate"
            >{{ applyBtnText }}</BaseButton>
        </div>
    </div>
</template>

<script>

import BaseCheckboxV3 from "@/components/Base/BaseCheckboxV3";
import BaseButton from "@/components/Base/BaseButton";
import BaseInput from "@/components/Base/BaseTextInput";

export default {
    name: 'FormTemplateEdit',
    components: {
        BaseCheckboxV3,
        BaseButton,
        BaseInput,
    },
    /**
     * Входные данные
     * @property {Array} items - массив атрибутов для экспорта
     * @property {String} name - название шаблона
     * @property {Number} - id шаблона
     * @property {Number} - countTemplates количество шаблонов
     */
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: 0
        },
        countTemplates: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'create',
            validation: (val) => ['create', 'edit'].includes(val)
        },
        isName: {
            type: Boolean,
            default: true
        },
        isDescription: {
            type: Boolean,
            default: true
        }
    },
    /**
     * Локальные данные
     * @property {Number} pointX - координата нажатия по Х
     * @property {Number} pointY - координата нажатия по Y
     * @property {Number} ghostPositionUp - индекс верхней тени возможного перемещения (при перетаскивании верх)
     * @property {Number} ghostPositionDown - индекс нижней тени возможного перемещения (при перетаскивании вниз)
     * @property {Array} bufferTemplate - буффер с атрибутами
     * @property {String} editingAttribute - редактируемый атрибут
     * @property {String}  bufferName
     */
    data() {
        return {
            pointX: 0,
            pointY: 0,
            ghostPositionUp: null,
            ghostPositionDown: null,
            bufferTemplate: [],
            editingAttribute: '',
            bufferName: ''
        }
    },
    mounted() {
        this.bufferName = this.name;
        this.initBuffer()
    },

    computed: {
        applyBtnText() {
            if (this.type === 'edit') { return 'Сохранить' }
            return 'Создать';
        },
    },
    methods:{
        onEditLabel(event, attribute){
            this.editingAttribute = attribute;
            this.$nextTick(() => {
                this.$refs['input_item_' + attribute][0].$refs['input'].focus();
            });
        },
        isEditing(attribute){
            return this.editingAttribute === attribute;
        },
        initBuffer(){
            this.bufferTemplate = Object.assign([], this.items);
            if(this.id === 0){
                this.bufferName = 'Новый шаблон ' + this.countTemplates;

                this.$emit('changeType', 'create');
            }
        },
        getColumnIsCustomizable(visibleItem = {}) {
            return visibleItem?.extraData?.isCustomizable ?? true;
        },
        /**
         * Перетаскивание атрибутов
         * @param event
         * @param index
         */
        onMove(event, index, visibleItem = {}) {
            if (visibleItem?.extraData?.isCustomizable ?? true) {
                let boxTag = event.target.closest('.js-draggable');
                let itemHeight = boxTag.clientHeight + 2;
                document.body.style.userSelect = 'none'
                this.pointX = event.clientX
                this.pointY = event.clientY
    
    
                boxTag.style.borderStyle = 'dotted'
    
                const move = (moveEvent) => {
                    const differenceY = moveEvent.clientY - this.pointY
                    let countItems = Math.round(differenceY / itemHeight);
                    let newIndex = index + countItems;
                    if(newIndex < index){
                        newIndex = newIndex + 1;
                    }
                    if(newIndex < 0){
                        newIndex = 0;
                    }
                    if(newIndex >= this.bufferTemplate.length){
                        newIndex = this.bufferTemplate.length - 1;
                    }
                    if(newIndex >= index){
                        this.ghostPositionDown = newIndex;
                        this.ghostPositionUp = null;
                    }else {
                        this.ghostPositionUp = newIndex;
                        this.ghostPositionDown = null;
                    }
    
                }
    
                // Удаляю слушатели
                document.addEventListener('mousemove', move)
                document.addEventListener('mouseup', (moveEventUp) => {
                    document.removeEventListener('mousemove', move)
                    this.ghostPositionUp = null;
                    this.ghostPositionDown = null;
                    const differenceY = moveEventUp.clientY - this.pointY
                    let countItems = Math.round(differenceY / itemHeight);
                    let newIndex = index + countItems;
                    if(newIndex < index){
                        newIndex = newIndex + 1;
                    }
                    if(newIndex < 0){
                        newIndex = 0;
                    }
                    if(newIndex >= this.bufferTemplate.length){
                        newIndex = this.bufferTemplate.length - 1;
                    }
                    this.arrayMove(index, newIndex);
                    document.body.style.userSelect = 'initial'
                    boxTag.style.borderStyle = 'none';
                }, {once: true})
            }
        },
        /**
         * Перенос элементов в массиве
         * @param oldIndex
         * @param newIndex
         */
        arrayMove(oldIndex, newIndex) {
            let data = this.bufferTemplate;
            const movingItem = {...data[oldIndex]};
            data.splice(oldIndex, 1);
            data.splice(newIndex, 0, movingItem);
            this.bufferTemplate = Object.assign([], data);
        },
        /**
         * Нажатие на чекбокс
         * @param value
         * @param attribute
         */
        onChangeVisibility(value, attribute){
            let data = this.bufferTemplate;
            const itemIndex = data.findIndex((item) => {
                const {attribute: itemAttribute = ''} = item;
                return String(itemAttribute) === String(attribute);
            });
            const itemNew = {...data[itemIndex], checked: value};
            data.splice(itemIndex, 1, itemNew);
            this.bufferTemplate = Object.assign([], data);
        },
        /**
         * Сохранение шаблона
         */
        onSaveTemplate(){
            let data = {
                id: this.id,
                name: this.bufferName,
                data: this.bufferTemplate
            }
            this.$emit('onSaveTemplate', data);
        },
        onCancel(){
            this.$emit('onCancel');
        },
        /**
         * Завершение редактирования названия атрибута
         * @param event
         */
        onClearEditing(event){
            if(event.target.value && this.editingAttribute){
                let data = this.bufferTemplate;
                const itemIndex = data.findIndex((item) => {
                    const {attribute: itemAttribute = ''} = item;
                    return itemAttribute === this.editingAttribute;
                });
                const itemNew = {...data[itemIndex], label: event.target.value};
                data.splice(itemIndex, 1, itemNew);
                this.bufferTemplate = Object.assign([], data);
            }
            this.editingAttribute = '';
        }
    }
}
</script>

<style lang="scss">
    .template__buffer-name .input {
        border: none !important;
    }

    .cell-checkbox .base-checkbox input:checked + span[data-v-22ccad6e]:before {
        width: 4px;
        height: 6px;
        top: 1px;
        left: 3px;
    }
</style>

<style lang="scss" scoped>
.template{
    position: relative;
    margin-top: 20px;

    &__text {
        font-size: 12px;
        margin-bottom: 5px;
        cursor: default;
    }

    &__buffer-name {
        padding: 10px 15px;
        border-radius: 4px;
        background: #F5F5F5;
        margin-bottom: 15px;

        .input {
            border: none;
        }
    }

    &__subtitle {
        font-size: 14px;
    }

    &__input{
        padding: 5px;
        margin: 15px 0px;
        width: 100%;
    }

    &__button-container{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__container{
        overflow-y: auto;
        max-height: 295px;
        margin-bottom: 30px;

        .ghost-item{
            height: 40px;
            display: flex;
            align-items: center;
            background-color: #ececec;
            width: 100%;
            text-align: center;
            border-radius: 4px;
            padding-left: 38px;
            border: 3px dotted gray;
        }

        .visible-item{
            padding: 10px 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            background: #F5F5F5;
            border-radius: 4px;
            margin-bottom: 1px;

            &_disabled {
                color: #828992;
                pointer-events: none;
            }
        }

        .hidden-item{
            border: 1px solid gray;
            display: flex;
            align-items: center;
            height: 40px;
            cursor: pointer
        }

        .cell-checkbox {
            margin-right: 10px;
        }
    }

    &-content{
        width: 100%;
        display: flex;
        padding: 20px
    }
}
</style>