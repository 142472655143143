<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 5C13.4477 5 13 5.44772 13 6C13 6.55228 13.4477 7 14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5ZM11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H12C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7H4C3.44772 7 3 6.55228 3 6Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 6C15 5.44772 15.4477 5 16 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H16C15.4477 7 15 6.55228 15 6Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11ZM5 12C5 10.3431 6.34315 9 8 9C9.65685 9 11 10.3431 11 12C11 13.6569 9.65685 15 8 15C6.34315 15 5 13.6569 5 12Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 12C9 11.4477 9.44772 11 10 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H10C9.44772 13 9 12.5523 9 12Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 17C16.4477 17 16 17.4477 16 18C16 18.5523 16.4477 19 17 19C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17ZM14 18C14 16.3431 15.3431 15 17 15C18.6569 15 20 16.3431 20 18C20 19.6569 18.6569 21 17 21C15.3431 21 14 19.6569 14 18Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 18C3 17.4477 3.44772 17 4 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H4C3.44772 19 3 18.5523 3 18Z" :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18C18 17.4477 18.4477 17 19 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H19C18.4477 19 18 18.5523 18 18Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'IconSettings',
    props: {
        color: {
            type: String,
            default: 'black'
        }
    }
}
</script>
