<template>
    <div class="params">
        <h3 class="params__title" @mousedown="$emit('mouseDown', $event)">Характеристики</h3>

        <div class="params__table">
            <div 
                v-if="params.oohdeskId"
                class="params__table-row"
            >
                <div class="params__table-col-name">OOHDESK ID</div>
                <div class="params__table-col-value">{{params.oohdeskId}}</div>
            </div>

            <div 
                v-if="params.city"
                class="params__table-row"
            >
                <div class="params__table-col-name">Регион</div>
                <div class="params__table-col-value">{{params.city}}</div>
            </div>

            <div 
                v-if="params.suppId"
                class="params__table-row"
            >
                <div class="params__table-col-name">Оператор</div>
                <div class="params__table-col-value">{{params.suppId}}</div>
            </div>

            <div 
                v-if="params.gid"
                class="params__table-row"
            >
                <div class="params__table-col-name">GID</div>
                <div class="params__table-col-value">{{params.gid}}</div>
            </div>

            <div class="params__table-row">
                <div class="params__table-col-name">Тип</div>
                <div class="params__table-col-value">{{params.type}}</div>
            </div>

            <div
                v-if="params.kind"
                class="params__table-row"
            >
                <div class="params__table-col-name">Вид</div>
                <div class="params__table-col-value">{{params.kind}}</div>
            </div>

            <div
                v-if="params.coords"
                class="params__table-row"
            >
                <div class="params__table-col-name">Координаты</div>
                <div class="params__table-col-value">{{params.coords}}</div>
            </div>

            <div class="params__table-row">
                <div class="params__table-col-name">Формат</div>
                <div class="params__table-col-value">{{params.format}}</div>
            </div>

            <div
                v-if="params.grp"
                class="params__table-row"
            >
                <div class="params__table-col-name">GRP</div>
                <div class="params__table-col-value">{{params.grp}}</div>
            </div>

            <div
                v-if="params.ots"
                class="params__table-row"
            >
                <div class="params__table-col-name">OTS</div>
                <div class="params__table-col-value">{{params.ots}}</div>
            </div>

            <div
                v-if="params.sideName"
                class="params__table-row"
            >
                <div class="params__table-col-name">Сторона</div>
                <div class="params__table-col-value">{{params.sideName}}</div>
            </div>

            <div
                v-if="params.moveDirection"
                class="params__table-row"
            >
                <div class="params__table-col-name">Направление движения</div>
                <div class="params__table-col-value">{{params.moveDirection}}</div>
            </div>

            <div class="params__table-row">
                <div class="params__table-col-name">Материал</div>
                <div class="params__table-col-value">{{params.material}}</div>
            </div>

            <div
                v-if="params.lighted"
                class="params__table-row"
            >
                <div class="params__table-col-name">Освещение</div>
                <div class="params__table-col-value">{{params.lighted}}</div>
            </div>

            <div
                v-if="params.opn"
                class="params__table-row"
            >
                <div class="params__table-col-name">№</div>
                <div class="params__table-col-value">{{params.opn}}</div>
            </div>

            <div
                v-if="params.printPrice"
                class="params__table-row"
            >
                <div class="params__table-col-name">Печать за единицу</div>
                <div class="params__table-col-value">{{params.printPrice}}</div>
            </div>

            <div
                v-if="params.installPrice"
                class="params__table-row"
            >
                <div class="params__table-col-name">Монтаж за единицу</div>
                <div class="params__table-col-value">{{params.installPrice}}</div>
            </div>

            <div v-if="params.brands" class="params__table-row">
                <div class="params__table-col-name">Бренд</div>
                <div class="params__table-col-value">{{params.brands}}</div>
            </div>
            <div v-if="params.projects" class="params__table-row">
                <div class="params__table-col-name">Проект</div>
                <div class="params__table-col-value">{{params.projects}}</div>
            </div>
            <div v-if="params.advertisers" class="params__table-row">
                <div class="params__table-col-name">Рекламодатель</div>
                <div class="params__table-col-value">{{params.advertisers}}</div>
            </div>
            <div v-if="params.industries" class="params__table-row">
                <div class="params__table-col-name">Отрасль</div>
                <div class="params__table-col-value">{{params.industries}}</div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "Params",
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-border-table: #F2F2F2;
$color-gray-300: #AFB0B4;

.params {
    background: #fff;
    border-radius: 10px;
    border: 1px solid $color-border;

    padding: 15px 20px 20px 20px;

    &__title {
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        cursor: move;
        padding: 5px 0;

        margin-bottom: 10px;
    }

    &__table {
        display: flex;
        flex-direction: column;
    }

    &__table-row {
        display: flex;
        justify-content: space-between;

        font-size: 11px;
        border-top: 1px solid $color-border-table;
        padding: 7px 0;
    }

    &__table-col-value {
        color: $color-gray-300;
        text-align: right;
    }
}
</style>
