<template>
    <div class="container-competitor">
        <WidgetMapBaseLegend
            title="Конкуренты"
            :is-empty="false"
            :isLoading="true"
            :head-menu-items="headMenuCompetitor"
            :dropdown-options="competitorDropdownOptions"
            :items-list="competitorItems"
            :children-list="competitorChildren"
            :active-items-list="competitorItemsActive"
            :active-children-list="competitorChildrenActive"
            :checked-dates="checkedDates"
            :access-dates="accessDates"
            :config="competitorConfig"
            :isMultipleAccordion="true"
            :accordionActivityMultiple="competitorsAccordionActivity"
            class="widget-map-competitor"
            @onDropdownOption="onDropdownOption"
            @onShowColorPanel="onShowColorPanel"
            @onCheckedChild="onCheckedChild"
            @onChangeAccordionActivityMultiple="onChangeAccordionActivityMultiple"
            @onActionButton="onActionButton"
            @onApproveDate="onApproveDate"
        />

        <CompetitorSelectForm
            v-if="isCompetitorAdd"
            :title="'Добавление конкурента'"
            :newCompetitorsList="newCompetitorsList"
            :competitorTypes="competitorTypesDropdownOptions"
            :isLoadingNewCompetitors="isLoadingNewCompetitors"
            @onCloseModal="onExitAdding"
            @onAddCompetitor="onAddCompetitor"
            @onCheckCompetitorType="competitorAddTypeValues"
        />

        <ColorPanel
            v-if="editCompetitorColorKey"
            :title="activeColorTitle"
            :active-color="activeColor"
            :icons="[]"
            :config="{
                isShowColorBlock: true,
                isShowIconBlock: false,
                isShowFigureBlock: false,
            }"
            :is-loading="false"
            class="color-panel"
            @onClose="onCloseColorPanel"
            @onChangeColor="changeColorMarker"
        />
    </div>
</template>

<script>
    import {mapState, mapMutations} from "vuex";
    import CompetitorSelectForm from "./CompetitorSelectForm";
    import ServiceCompetitor from "../../../../services/ServicesCompetitor/ServiceCompetitor";
    import ServiceCompetitorSelector from '../../../../services/ServicesCompetitor/ServiceCompetitorSelector';
    import SchemeCompetitor from "../../../../schemes/SchemeCompetitor";
    const {competitorTypes, headMenuCompetitor, competitorDropdownOptions} = SchemeCompetitor;
    export default {
        name: "ContainerCompetitor",
        components: {
            CompetitorSelectForm
        },
        /**
         * Входные данные компонента
         */
        props: {

        },
        /**
         *
         * @property {Object} competitorConfig - конфиг глоб компонента
         * @property {Boolean} isLoadingNewCompetitors - прелодер при загрузки конкурентов в модалке
         * @property {Boolean} isPreloader прелодер главного компонента
         * @property {Array<Object>} checkedDates - выбранные даты
         * @property {Array<Object>} accessDates - доступные даты
         * @property {Array<Object>} newCompetitorsList - список новых конкурентов в модалку
         * @property {Boolean} isCompetitorAdd флаг добавления конкурента
         * @property {Array<String>} competitorChildrenActive - активированные на карте конкуренты
         * @property {Array<String>} competitorItemsActive - активированные главные группы, салазки
         * @property {Object<Array>} competitorChildren - массив конкурентов
         * @property {Array<Object>} competitorItems
         * @property {Array<Object>} headMenuCompetitor - меню заголовков
         * @property {Array<Object>} competitorDropdownOptions - меню для чекбоксов
         * @property {String} editCompetitorColorKey - ключ для редактирования цвета
         * @property {Array} competitorsAccordionActivity Активность множественного аккордеона
         * @property {Array} selectedCities Текущие выбранные города
         */
        data: () => ({
            competitorConfig: {
                isShowSelectDate: true,
            },
            isLoadingNewCompetitors: false,
            isPreloader: false,
            checkedDates: [],
            accessDates: [],
            newCompetitorsList: [],
            isCompetitorAdd: false,
            competitorChildrenActive: [
                // {id: '1-industry', key: 'industry'},
                // {id: '2-industry', key: 'industry'}
            ],
            competitorItemsActive: [/*'industry'*/],
            competitorChildren: [],
            competitorItems: competitorTypes,
            // headMenuCompetitor: headMenuCompetitor,
            competitorDropdownOptions: competitorDropdownOptions,
            editCompetitorColorKey: '',
            competitorsAccordionActivity: [],
            selectedCities: []
        }),

        /**
         * После рендере компонента получить данные от сервера
         */
        mounted() {
            this.getAvailableDates();

            this.$emit('changeActiveList', 'competitor', this.competitorChildrenActive.length > 0);
        },

        watch: {
            /**
             * Обновляю маркеры и список конкурентов при изменении даты
             */
            checkedDates(){
                this.getCompetitorsUserList((type) => {
                    this.updateAllActiveMarkers(type);

                    this.updateCompetitorGroupsChecked();

                    if (this.isShare) {
                        const competitors = this.competitorChildren.filter(competitor => String(competitor?.key) === String(type));
                        competitors.forEach(competitor => this.onCheckedChild(true, String(competitor?.id)));
                    }
                });

                this.setCompetitorDatesSelected(this.checkedDates);
            },
            /**
             * Обновляю маркеры и список конкурентов при изменении города
             */
            filterSelectedData(){
                if(this.isChangedCity){
                    this.checkedDates = [];
                    this.getAvailableDates();
                    // this.getCompetitorsUserList((type) => {
                    //     this.updateAllActiveMarkers(type);

                    //     this.updateCompetitorGroupsChecked();
                    // });
                }
            },
            competitorChildrenActive() {
                this.$emit('changeActiveList', 'competitor', this.competitorChildrenActive.length > 0);

                const competitorIdsChildrenActive = this.competitorChildrenActive.map(competitor => String(competitor?.id));
                const competitorChildrenActive = this.competitorChildren.filter(competitor => competitorIdsChildrenActive.includes(competitor?.id));
                this.setCompetitorSelected(competitorChildrenActive);
            },
        },
        computed: {
            /**
             * Проксируем состояния из стора
             * @returns {Object} state - состояния
             * @returns {Object} state.filterSelectedData - выбранные данные фильтра
             * @returns {Array<Number|String>} state.filterSelectedData.city_id - выбранные идентификаторы городов фильтра
             * @returns {Array<String>} state.filterSelectedData.date - выбранные даты фильтра
             */
            ...mapState({
                filterSelectedData: state => state.filter.filterSelectedData
            }),

            isShare() {
                const {query = {}} = this.$route;
                const {shareToken = null} = query;
                return shareToken !== null;
            },

            headMenuCompetitor() {
                return this.isShare ? [] : [...headMenuCompetitor];
            },

            /**
             * Дропдаун для добавления нового конкурента
             */
            competitorTypesDropdownOptions(){
                let options = [];
                this.competitorItems.forEach((item) => {
                    options.push({
                        id: item.key,
                        value: item.name
                    });
                })
                return options;
            },

            /**
             *  Выбранные города в глобальном фильтре
             */
            filterCityId() {
                const { city_id = []} = this.filterSelectedData;
                return city_id;
            },

            /**
             * Если задан id редактируемого конкурента открывается окно выбора цвета
             */
            activeColor(){
                if(!this.editCompetitorColorKey){
                    return "";
                }
                let activeItemIndex = this.competitorChildren.findIndex(item => item.id === this.editCompetitorColorKey);
                return this.competitorChildren[activeItemIndex].color;
            },
            /**
             * Заголовок для окна выбора цвета
             */
            activeColorTitle(){
                if(!this.editCompetitorColorKey){
                    return "";
                }
                let activeItemIndex = this.competitorChildren.findIndex(item => item.id === this.editCompetitorColorKey);
                return this.competitorChildren[activeItemIndex].description;
            }
        },
        methods: {
            ...mapMutations('mapWidget', ['setCompetitorSelected', 'setCompetitorDatesSelected']),
            setCompetitorGroupsChecked(itemId) {
                const [type, ] = String(itemId).split("-");
                this.competitorChildrenActive.push({id: String(itemId), key: type});
                this.getCompetitorMarkers(itemId);
            },

            updateCompetitorGroupsChecked() {
                if (!this.isShare) {
                    const competitorGroupsChecked = ServiceCompetitorSelector.getCompetitorGroupsChecked();
                    this.competitorChildren.forEach(competitor => {
                        const {id = ''} = competitor;
                        if (competitorGroupsChecked.includes(String(id)))
                            this.setCompetitorGroupsChecked(id);
                    });
                }
            },

            /**
             *  Проверка на изменение города в глоб фильтре
             */
            isChangedCity(){
                let isUpdatedCity = false;
                isUpdatedCity = this.selectedCities.filter(selectedCity => this.filterCityId.includes(selectedCity)).length;
                isUpdatedCity = isUpdatedCity || this.filterCityId.filter(filterCityIdItem => this.selectedCities.includes(filterCityIdItem)).length;
                this.selectedCities = [...this.filterCityId];
                return isUpdatedCity;
            },
            /**
             * Получение доступных дат конкурентов
             *
             * @param {Function} actionAfter
             */
            async getAvailableDates(actionAfter = () => {}){
                ServiceCompetitor.postCompetitorDates((result) => {
                    const {preparedDates, nearest} = result;
                    this.accessDates = preparedDates;
                    if(nearest !== false && !this.checkedDates.length){
                        this.checkedDates.push(nearest);
                    }
                    actionAfter();
                });
            },

            /**
             * Получение конкуретов из списка пользователей
             *
             * @param {Function} actionAfter
             */
            async getCompetitorsUserList(actionAfter = () => {}){
                this.competitorChildren = [];
                this.competitorItems.forEach((competitorItem) => {
                    const {key} = competitorItem;
                    this.updateCompetitorType(key, actionAfter);
                })
            },
            /**
             * Применить выбор даты
             * @param {Array<Object>} dates - даты
             */
            onApproveDate(dates) {
                this.checkedDates = dates;
            },

            /**
             * Обноление маркеров после смены фильтра
             *
             * @param {String} type - тип конкурента
             */
            updateAllActiveMarkers(type){
                let groupCompetitorChildrenActive = this.competitorChildrenActive.filter(competitorChildrenActiveItem => competitorChildrenActiveItem.key === type);
                groupCompetitorChildrenActive.forEach((competitorChildrenActiveItem) => {
                    const {id: itemId = ""} = competitorChildrenActiveItem;
                    let isCompetitorExists = this.competitorChildren.filter(competitorChildrenItem => competitorChildrenItem.id === itemId).length;
                    if(isCompetitorExists){
                        this.getCompetitorMarkers(itemId)
                    }else{
                        this.$emit("onClearCompetitorMarkers", itemId);
                    }
                })
            },

            /**
             * Клик по чекбоксу
             * @param {Boolean} isChecked - включен/выключен чекбокс
             * @param {String} itemId - id элемента
             */
            onCheckedChild(isChecked, itemId) {
                if (isChecked){
                    this.setCompetitorGroupsChecked(itemId);
                    
                    if (!this.isShare)
                        ServiceCompetitorSelector.setCompetitorGroupsChecked(itemId);
                }else {
                    this.competitorChildrenActive = this.competitorChildrenActive.filter(competitorChildrenActiveItem => competitorChildrenActiveItem.id !== itemId);
                    this.$emit("onClearCompetitorMarkers", itemId);

                    if (!this.isShare)
                        ServiceCompetitorSelector.unsetCompetitorGroupsChecked(itemId);
                }
            },

            /**
             * Получение новых маркеров для конкурента
             *
             * @param {String} itemId
             */
            async getCompetitorMarkers(itemId){
                let activeItemIndex = this.competitorChildren.findIndex(item => item.id === itemId);
                let competitorObj = this.competitorChildren[activeItemIndex]
                ServiceCompetitor.postCompetitorMarkers(competitorObj, this.checkedDates, (result) => {
                    this.$emit("onDrawCompetitorMarkers", itemId, result);
                });
            },
            /**
             * Клик по пункту в dropdown
             * @param {string} methodName - название функции обработчика события
             * @param {String} key
             */
            onDropdownOption(methodName, key = "") {
                console.log(methodName)
                switch (methodName) {
                    case "addCompetitor":{
                        this.isCompetitorAdd = true;
                        break;
                    }
                    case "removeCompetitor":{
                        this.onRemoveCompetitor(key);
                        break;
                    }

                }
            },

            /**
             * Изменение цвета конкурента
             */
            onShowColorPanel(key){
                this.editCompetitorColorKey = key;
            },

            /**
             * Закрытие окна добавления нового конкурента
             */
            onExitAdding(){
                this.isCompetitorAdd = false;
            },

            /**
             * Выход из окна изменения цвета
             */
            onCloseColorPanel(){
                this.editCompetitorColorKey = '';
            },

            /**
             * Изменить цвет маркера
             * @param {String} color - Цвет маркера
             */
            changeColorMarker(color = '000000') {
                let tmpKey = this.editCompetitorColorKey;
                this.editCompetitorColorKey = '';
                let [type, competitorId] = tmpKey.split("-");
                ServiceCompetitor.postCompetitorChangeColor(type, competitorId, color, () => {
                    this.competitorChildren.filter(item => item.id === tmpKey).forEach(item => {
                        item.color = color;
                    })
                    let isCompetitorExists = this.competitorChildrenActive.filter(competitorChildrenItem => competitorChildrenItem.id === tmpKey).length;
                    if(isCompetitorExists){
                        this.$emit("onChangeCompetitorColor", tmpKey, color)
                    }
                })
            },

            /**
             *
             * @param {String} type
             * @param {Function} actionAfter
             **/
            updateCompetitorType(type, actionAfter = () => {}){
                ServiceCompetitor.postCompetitorUserList(type, this.checkedDates,(result) => {
                    let tmpList = [...this.competitorChildren];
                    tmpList = tmpList.filter(item => item.key !== type);
                    this.competitorChildren = [...tmpList, ...result];
                    actionAfter(type);
                });
            },

            /**
             * Удаление конкурента из списка пользователя
             *
             * @param {String} key
             */
            onRemoveCompetitor(key){
                this.isPreloader = true;
                let [type, competitorId] = key.split("-");
                ServiceCompetitor.postCompetitorUserListRemove(type, competitorId, () => {
                    this.updateCompetitorType(type);
                    this.isPreloader = false;
                })
                this.$emit("onClearCompetitorMarkers", key);

                if (!this.isShare)
                    ServiceCompetitorSelector.unsetCompetitorGroupsChecked(key);
            },

            /**
             * Добавление конкурента в список пользователя
             * @param {String} competitorId
             * @param {String} competitorAddType
             */
            onAddCompetitor(competitorId, competitorAddType){
                this.isPreloader = true;
                this.isCompetitorAdd = false;
                ServiceCompetitor.postCompetitorUserListAdd(
                    competitorAddType,
                    competitorId,
                    () => {
                        this.updateCompetitorType(competitorAddType);
                        this.isPreloader = false;
                    }
                )
            },

            /**
             *
             * @param {String} competitorAddType
             */
            competitorAddTypeValues(competitorAddType){
                this.isLoadingNewCompetitors = true;
                ServiceCompetitor.postCompetitorList(competitorAddType, this.checkedDates, (result) => {
                    this.newCompetitorsList = result;
                    this.isLoadingNewCompetitors = false;
                });
            },

            /**
             * Смена активности аккардиона
             *
             * @param {Array} activityMultiple
             */
            onChangeAccordionActivityMultiple(activityMultiple){
                this.competitorsAccordionActivity = activityMultiple;
            },

            /**
             * Действия для кнопок убрать все и сбросить
             * @param action
             * @param value
             */
            onActionButton(action, value = null){
                switch (action) {
                    case "unselectedAll":{
                        this.competitorChildrenActive.forEach(item => {
                            const {id: groupId} = item;
                            this.$emit("onClearCompetitorMarkers", groupId);

                            if (!this.isShare)
                                ServiceCompetitorSelector.unsetCompetitorGroupsChecked(groupId);
                        })
                        this.competitorChildrenActive = [];
                        break;
                    }
                    case "reset":{
                        if(value === null){
                            return;
                        }
                        let updatedActivities = [];
                        this.competitorChildrenActive.forEach(item => {
                            const {id: groupId, key: groupKey = ""} = item;
                            if(groupKey === value){
                                this.$emit("onClearCompetitorMarkers", groupId);

                                if (!this.isShare)
                                    ServiceCompetitorSelector.unsetCompetitorGroupsChecked(groupId);
                            }else{
                                updatedActivities.push(item);
                            }
                        });
                        this.competitorChildrenActive = updatedActivities;
                        break;
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.container-competitor {
    display: flex;
    align-items: flex-start;

    .widget-map-competitor {
        margin-left: 10px;
    }

    .color-panel {
        margin-left: 10px;
    }
}
</style>

