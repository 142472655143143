/* eslint-disable no-extra-semi */
/** Базовый сервис для работы с маркерами */
export default class ServiceBaseMarker {
    /**
     * Конструктор
     * @param {Object} params - параметры для маркера
     */
    constructor(params = {}) {
        const {map = null, id = '', lat = '', lng = '', color = '', src = '', state = -1, direction = 0, sides = {}, mode = '', iconType = 'default', extraData = {}} = params;
        this.map = map;
        this.id = String(id);
        this.lat = parseFloat(lat);
        this.lng = parseFloat(lng);
        this.iconType = iconType;
        this._color = this.correctionColor(color);
        this.state = parseInt(state);
        this.direction = parseInt(direction);
        this.sides = sides;
        this.sidesCorrect = this.correctionSides(sides);
        this.mode = String(mode);
        this.extraData = extraData;
        this.src = src;
    };
    /**
     * Привести цвет маркера к нужному формату
     * @param {String} color - цвет маркера
     * @returns {String}
     */
    correctionColor(color = '') {
        return String(color).replace('#', '').toLowerCase();
    };

    /**
     * Геттер для переопределения цвета маркера
     * @return {String}
     */
    get color() {
        return this._color;
    };

    /**
     * Сеттер для переопределения цвета маркера
     * @param {String} newColor - цвет маркера
     */
    set color(newColor) {
        this._color = this.correctionColor(newColor);
    };


    /**
     * Привести объект сторон маркера к нужному формату
     * @param {Object} sides - стороны
     * @returns {Object}
     */
    correctionSides(sides = {}) {
        let sidesCorrect = {};
        const enB = String.fromCharCode(66);
        const ruB = String.fromCharCode(1042);
        Object.keys(sides).forEach(sideKey => {
            const sideNameCorrect = String(sides[sideKey]).toUpperCase().substring(0, 1);
            sidesCorrect[sideKey] = sideNameCorrect === enB || sideNameCorrect === ruB ? 'B' : 'A';
        });
        return sidesCorrect;
    };
    /**
     * Получить url иконки маркера
     * @returns {String}
     */
    getIconUrl() {
        let url = ``;
        switch (this.iconType) {
            case 'default':
                url = `${process.env.VUE_APP_FREE_BACKEND_HOST}/map/draw/marker?hexCode=${this.color}&showMode=0&stroke=${this.getState()}`;
                break;
            case 'direction': {
                // const isSideA = Object.values(this.sidesCorrect).includes('A');
                // const isSideB = Object.values(this.sidesCorrect).includes('B');
                // const sides = `${isSideA ? 'a' : ''}${isSideB ? 'b' : ''}`;
                // const colorA = '0000ff';
                // const colorB = '0000ff';
                // if (isNaN(this.direction))
                //     url = `/map/draw/marker-question?hexCode=${this.color}&showMode=${this.state}`;
                // else
                //     url = `/map/draw/marker-direction?sides=${sides}&colorA=${colorA}&colorB=${colorB}&direction=${this.direction}`;
                break;
            }
            case 'poi': {
                url = `${process.env.VUE_APP_FREE_BACKEND_HOST}/map/draw/marker-rectangle?hexCode=${this.color}&showMode=${this.getState()}`;
                break;
            }
            default:
                break;
        }
        return url;
    };
    getState() {
        const {kitId = ''} = this.extraData;
        return String(kitId) === '0' || this.state === 2 ? this.state : 0;
    };
};
