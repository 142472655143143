<template>
	<div
		class="default-cell"
		:class="{'default-cell_empty': !content}"
		v-tooltip="content"
		v-clipboard="() => content"
	>
		{{content ? content : "Нет данных"}}
	</div>
</template>

<script>

export default {
	name: "BaseCell",
	props: {
		content: {
			default: '',
		}
	},
}
</script>

<style lang="scss" scoped>
.default-cell {
	width: 100%;
	max-width: 100%;

	&_empty {
		color: #BEC0C7;
	}
}
</style>