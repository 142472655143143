<template>
    <button
        :class="classIsActive"
        class="tab-list__item"
        @click="onCheck"
    >
        {{ tabName }}
    </button>
</template>

<script>
/** Компонент для элемента списка табов */
export default {
    name: 'TabItem',
    /**
     * Входные данные компонента
     * @property {Object} tab - таб
     * @property {Boolean} isActive - условие активности таба
     */
    props: {
        tab: {
            type: Object,
            default: () => ({})
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Идентификатор таба
         * @returns {String}
         */
        id() {
            const {id = '-1'} = this.tab;
            return String(id);
        },
        /**
         * Название таба
         * @returns {String}
         */
        tabName() {
            const {tabName = ''} = this.tab;
            return String(tabName);
        },
        /**
         * Класс для активного таба
         * @returns {String}
         */
        classIsActive() {
            return this.isActive ? 'tab-list__item--active' : '';
        }
    },
    methods: {
        /**
         * Событие выбора таба
         */
        onCheck() {
            this.$emit('onCheck', this.id);
        }
    }
}
</script>

<style lang="scss" scoped>
$color-green: #6EC87A;
$color-gray: #AFB0B4;
$color-gray-light: #f2f2f2;

    .tab-list__item {
        font-size: 12px;
        letter-spacing: 0;
        color: $color-gray;

        margin: 0;
        padding: 0;
        padding-bottom: 5px;
        margin-bottom: -6px;

        border-bottom: 1px solid $color-gray-light;
        transition: all .3s ease-out;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &:hover {
            color: #333;
            border-bottom: 1px solid #333;
        }

        &--active {
            background-color: #fff;
            color: $color-green;
            border-bottom: 1px solid $color-green;

            &:hover {
                background-color: inherit;
            }
        }
    }
</style>
