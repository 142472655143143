<template>
    <div class="base-preloader-skeleton">
    </div>
</template>

<script>
export default {
    name: 'BasePreloaderSkeleton'
}
</script>

<style lang="scss" scoped>
    .base-preloader-skeleton {
        background-color: #e2e5e7;
        background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
        background-size: 40px 100%; 
        background-repeat: no-repeat;
        background-position: left -40px top 0;
        animation: shine 1s ease infinite;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 4px;
        z-index: 2;
    }

    @keyframes shine {
        to {
            background-position: right -40px top 0;
        }
    }
</style>