<template>
    <div class="tab-list">
        <tab-item
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            :tab="tab"
            :isActive="isActive(tab, tabIndex)"
            @onCheck="onCheck"
        />
    </div>
</template>

<script>
/** Компонент для списка табов */
import TabItem from './TabItem';
export default {
    name: 'TabList',
    components: {TabItem},
    /**
     * Входные данные компонента
     * @property {Array} tabs - список табов
     * @property {Boolean} tabIdActive - идентификатор активного таба
     */
    props: {
        tabs: {
            type: Array,
            default: () => ([])
        },

    },

    data: () => ({
        activeTabId: '',
    }),

    methods: {
        /**
         * Условие активности таба
         * @param {Object} tab - таб
         * @param {number} index - Индекс таба
         * @returns {Boolean}
         */
        isActive(tab = {}, index = 0) {
            if (!this.activeTabId) {
                this.activeTabId = String(index);
            }
            return String(tab.id) === this.activeTabId;
        },
        /**
         * Выбор таба
         * @param {String} id - идентификатор таба
         */
        onCheck(id = '') {
            if (this.activeTabId === id) {
                this.activeTabId = '';
            } else {
                this.activeTabId = id;
            }

            this.$emit('onCheck', id);
        }
    }
}
</script>

<style lang="scss" scoped>
$color-gray: #F2F2F2;

    .tab-list {
        display: flex;

        width: max-content;
        padding-bottom: 5px;
        border-bottom: 1px solid $color-gray;
    }
</style>
