const SchemeConstructionInfo = {
    draggableCards: [
        [
            {id: 0, classes: '', draggable: true, componentName: 'Budget', isPreview: false},
            {id: 1, classes: '', draggable: true, componentName: 'Site', isPreview: false},
            {id: 2, classes: '', draggable: true, componentName: 'Projects', isPreview: false},
            {id: 5, classes: '', draggable: true, componentName: 'Maket', isPreview: false},
        ],
        [
            {id: 6, classes: '', draggable: true, componentName: 'Photo', isPreview: false},
            {id: 3, classes: '', draggable: true, componentName: 'Params', isPreview: false},
            {id: 4, classes: '', draggable: true, componentName: 'Comment', isPreview: false},
        ]
    ],

};
export default SchemeConstructionInfo;
