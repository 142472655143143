import ServiceApi from '../ServiceApi';
import UtilDate from "../../utils/UtilDate";
// import UtilLocalStorage from '../../utils/UtilLocalStorage';
/** Сервис для карточки конструкции */
export default class ServiceConstructionInfo {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    static _entitySideName = 'side';
    static _entityMarkerName = 'marker';
    static _typeAp = '1';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     */
    static _requestRouts = {
        constructionInfo: '/main/card-get',
        unlinkGroup: '/price_group/update',
        constructionComment: '/main/card-comment'
    };

    /**
     * Получить данные конструкции
     * @public
     * @static
     * @async
     * @param {String} entity - сущность side, price или marker
     * @param {Number} id - идентификатор сущности
     * @param {Number} kitItemId - id набора
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postInfo(entity, id, kitItemId, actionAfter = () => {}) {
        try {
            let postData = {price_group_id: kitItemId};
            postData[entity + "_id"] = id;
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.constructionInfo, postData);
            const {data: constructionData = {}} = res;
            const {activeSide, activePrice, availablePrices} = this._prepareCardData(entity, id, constructionData);
            actionAfter(constructionData, activeSide, activePrice, availablePrices);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param {String} entity
     * @param {Number} id
     * @param {Object} cardData
     * @returns {Object}
     * @private
     */
    static _prepareCardData(entity, id, cardData) {
        let activeSide = this._getActiveSide(entity, id, cardData);
        let {prices = []} = activeSide;
        let availablePrices = this._getAvailablePrices(activeSide);
        let activePrice = this._getActivePrice(prices, availablePrices);
        return {activeSide, activePrice, availablePrices};
    }

    /**
     * Получаю активную сторону
     * @param {String} entity
     * @param {Number} id
     * @param {Object} cardData
     * @returns {Object}
     * @private
     */
    static _getActiveSide(entity, id, cardData){
        let {sides = []} = cardData;
        let defaultSide = null;
        if(sides.length){
            defaultSide = sides[0].id;
        }
        let activeSideId = entity === this._entitySideName ? id : defaultSide;
        let activeSideIndex = sides.findIndex((side) => {
            return side.id === activeSideId;
        })
        return sides[activeSideIndex];
    }

    /**
     * Получение массива доступных цен (пока только для АП)
     * @param {Object} activeSide
     * @returns {Array}
     * @private
     */
    static _getAvailablePrices(activeSide){
        let {prices = []} = activeSide;
        return prices.map((price) => {
            const {date = "", id:priceId = "0"} = price;
            return {
                priceId,
                date: UtilDate.toDatepickerDate(date),
                stringDate: date
            };
        });

    }

    /**
     * Получение активной даты
     * @param {Array} prices
     * @param {Array} availablePrices
     * @returns {Object}
     * @private
     */
    static _getActivePrice(prices, availablePrices){
        let dates = availablePrices.map(item => item.stringDate);
        let nearestDate = UtilDate.getNearDate(dates);
        let activePrice = {};
        if(nearestDate !== false){
            let findPriceIndex = prices.findIndex((price) => {
                return price.date === nearestDate;
            });
            activePrice = findPriceIndex !== -1
                ? prices[findPriceIndex] : null
        }
        return activePrice;
    }

    /**
     * Отвязать от группы
     * @public
     * @static
     * @async
     * @param {Number} groupId
     * @param {Number} priceId - идентификатор сущности
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async unlinkPriceFromGroup(groupId, priceId, actionAfter = () => {}) {
        let priceGroup = {pg_id:groupId, action:"unlink", price_ids:[priceId]}
        try {
            const res = await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.unlinkGroup,
                {price_group: priceGroup}
            );
            const {data: constructionData = {}} = res;
            actionAfter(constructionData);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Сохранить комментарий
     * @public
     * @static
     * @async
     * @param {String} comment
     * @param {Number} priceId - идентификатор сущности
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async setComment(comment, priceId, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.constructionComment,
                {price_id: priceId, comment}
            );
            const {data: priceData = {}} = res;
            actionAfter(priceData);
        }
        catch(error) {
            console.log(error);
        }
    }
}
