export default {
	data() {
        return {
            sortedKey: '',
            isReverse: false,
        }
    },
	methods: {
        /**
         * Событие срабатывает при клике на заголовок таблицы
         * @param {Object} headerColumnData - данные о колонке
         */
		onSort(headerColumnData) {
            if (!this.isOnResizeColumn) {
                this.$el.scrollTop = 0
                if (!headerColumnData.canSorted) { return }
    
                const {prop} = headerColumnData
                this.ProgressBar = true
                
    
                if (this.sortedKey === prop) {
                    this.isReverse = !this.isReverse
                } else {
                    this.sortedKey = prop
                    this.isReverse = false
                }
    
                const type = this.isReverse ? 'desc' : 'asc'
    
                this.$emit('sort', {key: prop, type})
            }
        },
	}
}