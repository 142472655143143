<template>
    <BaseModal
        title="Редактирование направления"
        description="Укажите направление стрелки от 0 до 15, где 0 - это север, 4 - запад, 8 - юг, 12 - восток"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="modal-direction-edit__section">
                <BaseCounterV2 
                    :counterMax="15"
                    :counterMin="0"
                    v-model="direction"
                />
            </div>
            <div class="modal-direction-edit__section">
                <img :src="markerIconUrl"/>
            </div>
        </template>
        <template v-slot:actionButtons>
            <div class="modal-direction-edit__actions">
                <BaseButton
                    form="square"
                    view="border" 
                    class="modal-direction-edit__action-btn"
                    @click="onCloseModal"
                >
                    Отменить
                </BaseButton>
                <BaseButton
                    form="square"
                    view="secondary" 
                    class="modal-direction-edit__action-btn"
                    @click="saveDirectionEdit"
                >
                    Сохранить
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../Base/BaseModal.vue';
import BaseCounterV2 from '../Base/BaseCounterV2.vue';
import BaseButton from '../Base/BaseButton.vue';
export default {
    name: 'ModalDirectionEdit',
    components: {
        BaseModal,
        BaseCounterV2,
        BaseButton
    },
    props: {
        markerIconUrlInit: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        direction: 0,
    }),
    computed: {
        markerIconUrl() {
            let markerIconUrlInit = new URL(this.markerIconUrlInit);
            if (!markerIconUrlInit.searchParams.has('direction'))
                markerIconUrlInit.searchParams.set('showMode', 1);
            markerIconUrlInit.searchParams.set('direction', this.direction);

            return String(markerIconUrlInit.href);
        }
    },
    mounted() {
        const markerIconUrlInit = new URL(this.markerIconUrlInit);
        const direction = parseInt(markerIconUrlInit.searchParams.get('direction') ?? 0);

        this.direction = direction;
    },
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal');
        },
        saveDirectionEdit() {
            this.$emit('saveDirectionEdit', this.direction);
        }
    },
}
</script>

<style lang="scss" scoped>
    .modal-direction-edit {
        &__section {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            user-select: none;
        }

        &__actions {
            display: flex;
            justify-content: end;
            margin-top: 30px;
            user-select: none;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
</style>