<template>
    <div class="toolbar-block">
        <BaseButtonIcon
            v-for="(toolbarItem, index) in toolbarBlockItems"
            :key="index"
            :icon-name="toolbarItem.icon"
            :tooltip-text="toolbarItem.description"
            :is-gray="true"
            class="toolbar-block__item"
            @onBaseButtonIcon="onClickToolbarItem"
        />

    </div>
</template>

<script>
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";

export default {
    name: 'ToolbarBlock',
    components: {
        BaseButtonIcon,
    },
    /**
     * Входные данный компонента
     * @property {Object} toolbarBlock - блок тулбара
     */
    props: {
        toolbarBlock: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * Элементы блока тулбара
         * @returns {Array}
         */
        toolbarBlockItems() {
            const {items = []} = this.toolbarBlock;
            return items;
        }
    },
    methods: {
        /**
         * Событие клика по элементу тулбара
         * @param {string} toolbarItemId - идентификатор элемента тулбара
         */
        onClickToolbarItem(toolbarItemId = '') {
            this.$emit('onClickToolbarItem', toolbarItemId);
        },
    }
}
</script>

<style lang="scss" scoped>
    .toolbar-block {
        display: flex;

        &__item {
            margin-right: 1px !important;
        }
    }
</style>
