<template>
    <div class="base-counter">
        <div
            class="base-counter__button base-counter__button-minus"
            @click="onClickSub"
        >
            <IconMinus class="base-counter__button-icon"/>
        </div>
        <input
            type="number"
            :value="value"
            @input="onInput($event.target.value)"
            :min="counterMin"
            :max="counterMax"
            style="appearance: textfield;"
        >
        <div
            class="base-counter__button base-counter__button-plus"
            @click="onClickAdd"
        >
            <IconPlus class="base-counter__button-icon"/>
        </div>
    </div>
</template>

<script>
import IconMinus from '../Icons/IconMinus.vue';
import IconPlus from '../Icons/IconPlus.vue';
export default {
    name: 'BaseCounterV2',
    components: {
        IconMinus,
        IconPlus
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        counterMax: {
            type: Number,
            default: 0
        },
        counterMin: {
            type: Number,
            default: 0
        },
    },
    data: () => ({
        counter: 0
    }),
    methods: {
        onClickSub() {
            let counter = this.value;
            counter--;
            this.onInput(counter);
        },
        onClickAdd() {
            let counter = this.value;
            counter++;
            this.onInput(counter);
        },
        onInput(counter = '') {
            let correctCounter = this.counterMin;
            if (counter === '' || parseInt(counter) <= this.counterMin)
                correctCounter = this.counterMin;
            else if (parseInt(counter) >= this.counterMax)
                correctCounter = this.counterMax;
            else
                correctCounter = parseInt(counter);
            
            this.$emit('input', correctCounter);
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-counter {
        display: flex;
        margin-top: 8px;

        &__button-minus {
            border-right: 0;
            border-radius: 4px 0 0 4px;
        }

        &__button-plus {
            border-left: 0;
            border-radius: 0 4px 4px 0;
        }

        input[type="number"] {
            width: 36px;
            height: 36px;
            border: 1px solid #dcdfe5;
            border-radius: 10px;
            display: inline-block;
            text-align: center;
            margin-right: 4px;

            &:active, &:hover, &:focus {
                outline: 0;
                outline-offset: 0;
            }

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &__button {
            background: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 34px;
            border: 1px solid #dcdfe5;
            border-left-color: rgb(220, 223, 229);
            border-left-style: solid;
            border-left-width: 1px;
            cursor: pointer;
            outline: 0;
            -webkit-transition: all 250ms;
            -moz-transition: all 250ms;
            transition: all 250ms;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            padding: 0;
            margin-right: 4px;

            &__icon {
                width: 18px;
                height: 18px;
            }
        }
    }
</style>