<template>
    <div
        :id="id"
        class="draggable-board"
        @drop.prevent="drop"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DropColumn",
    props: {
        id: {
            type: String,
            required: true,
        },
    },

    methods: {
        drop(evt) {
          const cardId = evt.dataTransfer.getData('card_id').slice(15);
          const columnId = evt.dataTransfer.getData('column_id');

          this.$emit('dropCard', Number(cardId), Number(columnId));
        },
    },
}
</script>

<style lang="scss" scoped>
.draggable-board {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 300px;

    padding: 0;

    &:not(:last-child) {
        margin-right: 10px;
    }
}
</style>
