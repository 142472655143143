<template>
    <div class="comment">
        <h3 class="comment__title" @mousedown="$emit('mouseDown', $event)">Комментарий</h3>
        <textarea
            class="comment__textarea"
            v-model="params.comment"
            rows="3"
            @input="onInput"
        ></textarea>
    </div>
</template>

<script>

export default {
    name: "Comment",
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        inputLazyTimerId: null,
    }),
    methods: {
        onInput(event) {
            const value = event === null ? '' : event.target.value
            this.onInputLazy(value);
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputCommentLazy', value);
            }, 400);
        }
    }
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-gray-900: #F2F2F2;
$color-gray-300: #AFB0B4;

.comment {
    background: #fff;
    border-radius: 10px;
    border: 1px solid $color-border;

    padding: 15px 20px 20px 20px;

    &__title {
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        cursor: move;
        padding: 5px 0;

        margin-bottom: 10px;
    }

    &__textarea {
        background: $color-gray-900;
        width: 100%;
        height: 80px;
        border-radius: 10px;
        border: 0;
        font-size: 12px;
        padding: 10px;

        resize: vertical;
        min-height: 50px;
        max-height: 150px;

        &:hover, &:focus {
            outline: none;
        }
    }
}
</style>
