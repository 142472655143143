import ServiceApi from "../ServiceApi";
/** Сервис по работе с данными пользователя */
export default class ServiceUser {
    static profileKey = "USER_PROFILE";

    static userMenuItemsScheme = {
        myProfile: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/profile`,
        access: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/profile/access`,
        managerLink: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/links`,
        import: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/import`,
        settings: `${process.env.VUE_APP_EXTRA_PRODUCT_HOST}/settings`,
    };

    static _microserviceName = "apiMap";

    static _requestRouts = {
        getUserProfile: "/get-profile",
        getUserEntities: "/entity/item",
        updateUserEntities: "/entity/update",
        updateUserProfile: "/profile/update",
        getUsersShared: "/user/access/list",
        postSwitchUserShared: "/user/access/switch",
        getAccessList: "/user/access/list",
        getAccessGivenList: "/user/access/list-given",
        getSuppliersList: "/user/access/suppliers-list",
        getCitiesList: "/user/access/cities-list",
        addUserAccess: "/user/access/add",
        removeUserAccess: "user/access/remove",
    };

    /**
     * Получить данные пользователя из локального хранилища
     * @static
     */
    static get profile() {
        return JSON.parse(localStorage.getItem(this.profileKey));
    }

    /**
     * Установить данные пользователя в локальное хранилище
     * @static
     * @param {Object} profile - данные пользователя
     */
    static setProfile(profile = {}) {
        localStorage.setItem(this.profileKey, JSON.stringify(profile));
    }

    /**
     * Удалить данные пользователя из локального хранилища
     * @static
     */
    static removeProfile() {
        localStorage.removeItem(this.profileKey);
    }

    static goToUserMenuItem(key = "") {
        window.open(this.userMenuItemsScheme[key] ?? "", "_blank");
    }

    static async removeUserAccess(reqBody, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.removeUserAccess,
                reqBody
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async addUserAccess(reqBody, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.addUserAccess,
                reqBody
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getAccessList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getAccessList
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getAccessGivenList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getAccessGivenList
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getSuppliersList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getSuppliersList
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getCitiesList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getCitiesList
            );

            actionAfter(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    static async getUserEntities(actionAfter = () => {}) {
        await this._getUserEntities(actionAfter);
    }

    static async _getUserEntities(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getUserEntities
            );
            const entities = await this._getUserEntitiesAfter(res);

            actionAfter(entities);
        } catch (error) {
            console.log(error);
        }
    }

    static async _getUserEntitiesAfter(response = {}) {
        const { data = {} } = response;

        return {
            user_id: String(data?.user_id ?? ""),
            name: String(data?.name ?? ""),
            brand: String(data?.brand ?? ""),
            inn: String(data?.inn ?? ""),
            kpp: String(data?.kpp ?? ""),
            ogrn: String(data?.ogrn ?? ""),
            address: String(data?.address ?? ""),
            director: String(data?.director ?? ""),
            contract_number: String(data?.contract_number ?? ""),
            contract_date: String(data?.contract_date ?? ""),
        };
    }

    static async updateUserEntities(
        data = {},
        actionAfter = () => {},
        actionError = () => {}
    ) {
        await this._updateUserEntities(data, actionAfter, actionError);
    }

    static async _updateUserEntities(
        data = {},
        actionAfter = () => {},
        actionError = () => {}
    ) {
        const reqBody = this._updateUserEntitiesBefore(data);

        try {
            const res = await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.updateUserEntities,
                reqBody
            );
            const entities = await this._getUserEntitiesAfter(res);

            actionAfter(entities);
        } catch (error) {
            error?.message?.includes("400") &&
                actionError("Заполните обязятельные поля");
            console.log(error);
        }
    }

    static _updateUserEntitiesBefore(data = {}) {
        return {
            name: String(data?.name ?? ""),
            inn: String(data?.inn ?? ""),
            kpp: String(data?.kpp ?? ""),
            ogrn: String(data?.ogrn ?? ""),
            address: String(data?.address ?? ""),
            contract_number: String(data?.contract_number ?? ""),
            brand: String(data?.brand ?? ""),
        };
    }

    static async getUserProfile(actionAfter = () => {}) {
        await this._getUserProfile(actionAfter);
    }

    static async _getUserProfile(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getUserProfile
            );
            const {
                data: { profile = {}, email = "", realUser = false } = {},
            } = res;
            const userProfile = this._getUserProfileAfter({
                ...profile,
                login: String(email),
                realUser,
            });
            // if (!this.profile) {
                this.setProfile({
                    email: userProfile.email,
                    name: userProfile.name,
                    surname: userProfile.surname,
                    company: userProfile.company,
                    extraData: {...res?.data}
                });
            // }

            actionAfter(userProfile);
        } catch (error) {
            console.log(error);
        }
    }

    static _getUserProfileAfter(userProfile = {}) {
        const { user_id: userId = "" } = userProfile;
        const userProfileAfter = this._prepareUserProfile(userProfile, userId);

        return userProfileAfter;
    }

    static async updateUserProfile(data, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.updateUserProfile,
                data
            );
            const { data: { profile = {}, email = "" } = {} } = res;
            const userProfile = this._getUserProfileAfter({
                ...profile,
                login: String(email),
            });

            actionAfter(userProfile);
        } catch (error) {
            console.log(error);
        }
    }

    static async getUsersShared(actionAfter = () => {}) {
        await this._getUsersShared(actionAfter);
    }

    static async _getUsersShared(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getUsersShared
            );
            const { data: { users = {} } = {} } = res;
            const usersShared = this._getUsersSharedAfter(users);

            actionAfter(usersShared);
        } catch (error) {
            console.log(error);
        }
    }

    static _getUsersSharedAfter(usersShared = {}) {
        const usersSharedAfter = Object.keys(usersShared)
            .map((usersSharedId) => {
                const userShared = usersShared[usersSharedId];
                const userSharedAfter = this._prepareUserProfile(
                    userShared,
                    usersSharedId
                );

                return userSharedAfter;
            })
            .sort((userPrev) => {
                const { isRealUser = false } = userPrev;

                return isRealUser ? -1 : 0;
            });

        return usersSharedAfter;
    }

    static _prepareUserProfile(userProfile = {}, userId = "") {
        const {
            login: email = "",
            name = "",
            surname = "",
            realUser: isRealUser = false,
        } = userProfile;
        const userProfilePrepared = {
            ...userProfile,
            id: String(userId),
            email: String(email),
            name: String(name),
            surname: String(surname),
            isRealUser: Boolean(isRealUser),
        };

        return userProfilePrepared;
    }

    static async postSwitchUserShared(userShared = {}, actionAfter = () => {}) {
        await this._postSwitchUserShared(userShared, actionAfter);
    }

    static async _postSwitchUserShared(
        userShared = {},
        actionAfter = () => {}
    ) {
        const reqBody = this._postSwitchUserSharedBefore(userShared);
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.postSwitchUserShared,
                reqBody
            );
            this._postSwitchUserSharedAfter(userShared);

            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static _postSwitchUserSharedBefore(userShared = {}) {
        const { login = "" } = userShared;
        const reqBody = { login: String(login) };

        return reqBody;
    }

    static _postSwitchUserSharedAfter(userShared = {}) {
        const {
            email = "",
            company = "",
            name = "",
            surname = "",
        } = userShared;
        const profile = {
            email: String(email),
            company: String(company),
            name: String(name),
            surname: String(surname),
            extraData: {...userShared}
        };

        this.setProfile(profile);
    }
}
