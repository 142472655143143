import ServiceApi from '../ServiceApi';

export default class ServiceWidgetPoi {
    /**
     * Сохранение состояния виджетов и режимов карты
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postStateWidgets(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/main/state-get', postData);
            const {data = {}} = res;
            actionAfter(data);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на очищение store
     * @async
     * @param {Array} stateKeys - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postClearState(stateKeys = [], actionAfter = () => {}) {
        const params = {
            stateNames: stateKeys,
        };

        try {
            const res = await ServiceApi.post('apiMap', 'main/state-clear', params);
            const { data: dataBody } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
            throw new Error(error);
        }
    }

    /**
     * Запрос на получение списка групп POI в текущем городе
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postGroups(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/group-list', postData);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на получение списка групп POI для всех городов
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postGroupsTotal(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/group-list-total', postData);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на получение списка POI
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postPoints(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/get-points', postData);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
            throw new Error(error);
        }
    }

    /**
     * Запрос на удаление группы POI
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postRemoveGroup(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/remove-group', postData);
            const { data: dataBody } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на добавление/изменение группы POI
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postCreateGroup(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/create-group', postData);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
            throw new Error(error);
        }
    }

    /**
     * Запрос на изменение группы POI
     * @async
     * @param {Object} putData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async putUpdateGroup(putData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.put('apiMap', '/poi/update-group', putData);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /* ======================================== Иконки POI ================================================== */

    /**
     * Запрос на загрузку новой иконки POI
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postUploadIcon(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/upload-icon', postData);
            const { data: dataBody = {} } = res;

            // dataBody
            // {
            //     created_at: 1653865449
            //     id: 19
            //     link: "/poi/icon/19"
            //     name: "6293fbe961d69_big"
            //     updated_at: 1653865449
            //     user_id: 2
            // }
            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос получения иконоки
     * @async
     * @param {String} iconId - id иконки
     * @param {Object} HeaderParams - параметры запроса
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async getIconUrl(iconId = '', HeaderParams = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get('apiMap', `/poi/icon/${iconId}`, {}, HeaderParams);
            const { data: dataBody = {} } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос списка иконок POI
     * @async
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async getIconList(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get('apiMap', '/poi/icon-list');
            const { data: dataBody = [] } = res;

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на привязку иконки к группе
     * @async
     * @param {Object} postData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async postLinkIcon(postData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('apiMap', '/poi/link-icon', postData);
            const { data: dataBody = {} } = res;

            // dataBody
            /*{
                "id": 120,
                "name": "Макдоналдс",
                "color": "00ff55",
                "icon_id": "8"
            }*/

            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на отвязку иконки группы
     * @async
     * @param {Object} deleteData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async deleteUnlinkIcon(deleteData = {}, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.delete('apiMap', '/poi/unlink-icon', deleteData);
            const { data: dataBody = {} } = res;

            // dataBody
            // {
            //     "id": 120,
            //     "name": "Макдоналдс",
            //     "color": "00ff55",
            //     "icon_id": null
            // }
            actionAfter(dataBody);
        }
        catch(error) {
            console.log(error);
        }
    }

    /**
     * Запрос на удаление иконки из списка
     * @async
     * @param {Object} deleteData - тело запрос
     * @param {Function} actionAfter - событие, сработающее после отработки запроса
     */
    static async deleteRemoveIcon(deleteData = {}, actionAfter = () => {}) {
        try {
            await ServiceApi.delete('apiMap', '/poi/remove-icon', deleteData);      actionAfter();
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }
}
